import { crudConstNames } from '../utils/enums/crudConst'
import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import useBackendApi from './useBackendApi'
import { saveAs } from 'file-saver'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const headers = { ContentType: 'application/pdf', Accept: 'application/pdf' }

export default function useDownloadPdf(url, filename) {
  const { data, loading, sendRequest } = useBackendApi()
  const { t } = useTranslation(['translation'])
  const withTotalsRef = useRef(true)

  const getPdf = () => {
    const params = { with_totals: withTotalsRef.current }
    sendRequest(url, crudConstNames.get, headers, null, params, 'blob')
  }

  useEffect(() => {
    if (!loading && data) {
      notify(t('file_downloaded', { ns: 'translation' }), severities.success)
      const blob = new Blob([data], { type: 'application/pdf' })
      saveAs(blob, `${filename}.pdf`)
    }
  }, [loading])

  return { getPdf, withTotalsRef }
}
