export const detailsVariants = {
  default: 'default',
  withAddToCartButton: 'withAddToCartButton',
  public: 'public'
}

export const attributesList = {
  default: [
    'minimum_price',
    'leasing_price',
    'production_price',
    'installation_price',
    'provider',
    'locality_name',
    'format_id',
    'full_size',
    'partial_size',
    'vacancy_status',
    'illumination',
    'orientation', 
    'km',
    'daily_flips',
    'pixel_pitch',
    'resolution',
    'store_brand',
    'working_hours',
    'material_specs',
    'shipping_format',
    'shipping_deadline'
  ],
  public: [
    'locality_name',
    'format_id',
    'full_size',
    'partial_size',
    'vacancy_status',
    'illumination',
    'orientation', 
    'km',
    'daily_flips',
    'pixel_pitch',
    'resolution',
    'store_brand',
    'working_hours',
    'material_specs',
    'shipping_format',
    'shipping_deadline'
  ]
}
