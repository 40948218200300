import { bidPricesTypes } from "../enums/bidPrices"
import { getBaseTotalPriceByAttribute } from "./getBaseTotalPriceByAttribute"
import { getPriceByAttribute } from "./getPriceByAttribute"

export const getPriceByTotals = (
  bidsCart,
  ) => {
  const { totalLeasingPrice, totalProductionPrice, totalInstallationPrice } = bidsCart
  // { leasingKey, baseTotalLeasingPrice, isLeasingTotalZero }
  const bidsInCart = bidsCart.bids.filter((bid) => bid.quantity > 0)
  const leasingData = getBaseTotalPriceByAttribute(bidPricesTypes.total_leasing, bidsInCart, totalLeasingPrice)
  const productionData = getBaseTotalPriceByAttribute(bidPricesTypes.total_production, bidsInCart, totalProductionPrice)
  const installationData = getBaseTotalPriceByAttribute(bidPricesTypes.total_installation, bidsInCart, totalInstallationPrice)

  let bids = []
  bidsCart.bids.forEach((bid) => {
    let newBid = {}
    if (bid.quantity > 0) {
      newBid = {...newBid, ...getPriceByAttribute(bid, leasingData.attributeName, totalLeasingPrice, leasingData.baseTotalPrice, leasingData.isBaseTotalZero)}
      newBid.production_price = getPriceByAttribute(bid, productionData.attributeName, totalProductionPrice, productionData.baseTotalPrice, productionData.isBaseTotalZero).production_price
      newBid.installation_price = getPriceByAttribute(bid, installationData.attributeName, totalInstallationPrice, installationData.baseTotalPrice, installationData.isBaseTotalZero).installation_price
      newBid = { ...bid, ...newBid }
      bids = [...bids, newBid]
    } else {
      bids = [...bids, bid]
    }
  })
  return { bids }
}
