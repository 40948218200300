import { durationUnitsEnum } from '../durationUnits'
import { mappedFormats } from '../formats'

export const bidFromAdSpace = (adSpace) => {
  return {
    ad_space_uuid: adSpace.uuid,
    ad_space_name: adSpace.name,
    ad_space_code: adSpace.code,
    ad_space_sku: adSpace.sku,
    ad_space_format_name: mappedFormats[adSpace?.format_id],
    ad_space_lat: adSpace.lat,
    ad_space_lng: adSpace.lng,
    ad_space_icon: adSpace.icon,
    ad_space_leasing_price: adSpace.leasing_price,
    ad_space_production_price: adSpace.production_price,
    ad_space_installation_price: adSpace.installation_price,
    ad_space_provider_id: adSpace.provider_id,
    ad_space_provider_name: adSpace.provider_name,
    ad_space_provider_fee: adSpace.provider_fee,
    ad_space_minimum_price: adSpace.minimum_price || 0,
    quantity: 1,
    duration: 1,
    duration_unit: durationUnitsEnum.months,
    leasing_price: adSpace.leasing_price || 0,
    total_leasing_price: adSpace.leasing_price || 0,
    production_price: adSpace.production_price || 0,
    installation_price: adSpace.installation_price || 0,
    start_date: adSpace.start_date || '',
    end_date: adSpace.end_date || '',
    _destroy: false
  }
}
