import bidsByProviderReducer from './bidsByProviderSlice'
import bidsCartReducer from './bidsCartSlice'
import mapFilterReducer from './mapFilterSlice'
import quotationTabReducer from './quotationTabSlice'
import campaignTabReducer from './campaignTabSlice'
import visitModeReducer from './visitModeSlice'
import bidBounceReducer from './bidBounceSlice'
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    bidsCart: bidsCartReducer,
    bidsByProvider: bidsByProviderReducer,
    mapFilter: mapFilterReducer,
    quotationTab: quotationTabReducer,
    campaignTab: campaignTabReducer,
    visitMode: visitModeReducer,
    bidBounce: bidBounceReducer
  }
})
