import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, TextField, IconButton, Tooltip, FormControlLabel, Checkbox } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

export const QueryFilterInput = () => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])
  // METHODS
  const { setFieldValue, values } = useFormikContext()
  const handleClear = () => {
    setFieldValue('query_filter', '')
  }

  // RETURN
  return (
    <FormControl className='form-control-filters form-input' variant='outlined' size='small' fullWidth>
      <Field
        as={TextField}
        name='query_filter[text]'
        id='query_filter'
        label={t('queryFilterLabel', { ns: 'adSpace' })}
        fullWidth
        size='small'
        InputProps={{
          endAdornment: values.query_filter && (
            <IconButton onClick={handleClear} size='small'>
              <ClearIcon fontSize='small' />
            </IconButton>
          )
        }}
      />
      <Tooltip title={t('exact_match_tooltip', { ns: 'adSpace' })}>
        <FormControlLabel
          control={
            <Checkbox
              name='query_filter[exact_match]'
              id='exact_match'
              defaultChecked={false}
              onChange={(value) => setFieldValue('query_filter[exact_match]', value.target.checked)}
            />
          }
          label={t('exact_match', { ns: 'adSpace' })}
        />
      </Tooltip>
    </FormControl>
  )
}
