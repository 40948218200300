import { Box } from '@mui/system'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../hooks/store'
import { quotationTabTypes } from '../../utils/enums/quotationTabTypes'
import { generateQuotationPublicUrl } from '../../utils/quotations/generatePublicUrl'
import MapFilters from '../map/MapFilters'
import ExportQuotationOptions from './ExportQuotationOptions'
import { Button } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import VisibilityIcon from '@mui/icons-material/Visibility'

export function QuotationContextualActions({ quotation, providers }) {
  const currentQuotationTab = useAppSelector((state) => state.quotationTab.currentTab)
  const { t } = useTranslation(['translation', 'quotation'])
  const isCartTabOpen = currentQuotationTab === quotationTabTypes.cart_tab_open

  if (isCartTabOpen && quotation) {
    const fullUrl = generateQuotationPublicUrl(quotation.uuid)
    const fileNameForExport = [quotation.advertiser?.name, quotation.title].join(' - ')
    return (
      <Box sx={{ float: 'right', ml: 'auto' }}>
        <Button
          onClick={() => window.open(fullUrl, 'rel=noopener noreferrer')}
          variant='outlined'
          startIcon={<VisibilityIcon />}
          sx={{ mr: '8px' }}>
          {t('show', { ns: 'quotation' })}
        </Button>
        <ExportQuotationOptions quotationId={quotation.uuid} filename={fileNameForExport} packagePricing={quotation.package_pricing} />
      </Box>
    )
  } else {
    return <MapFilters providers={providers} />
  }
}

QuotationContextualActions.propTypes = {
  quotation: propTypes.object,
  providers: propTypes.array
}
