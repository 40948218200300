import PropTypes from 'prop-types'
import { useRef } from 'react'

import { useAppSelector } from '../../hooks/store'
import { extractMarkersFromBids } from '../../utils/bid/extractMarkersFromBids'
import { detailsVariants } from '../../utils/detailsVariants'
import { Map } from '../map/Map'

export function QuotationFormMap({ bidsBounce }) {
  const bidList = useAppSelector((state) => state.bidsCart.bids)
  const markers = extractMarkersFromBids(bidList)
  const activeBounce = useRef({uuid: null, active: false})
  const bounceActive = Object.entries(bidsBounce).find(([key, value]) => value.active)

  activeBounce.current = bounceActive !== undefined
    ? { uuid: bounceActive[0], active: bounceActive[1].active }
    : {uuid: null, active: false}

  return (
      <Map
        markers={markers}
        showAllMarkers={true}
        variant={detailsVariants.default}
        activeMarkerUuid={activeBounce.current}
      />
  )
}

QuotationFormMap.propTypes = {
  bidsBounce: PropTypes.object.isRequired
}