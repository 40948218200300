import propTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Checkbox, Paper, Button, Chip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useState, useEffect, useRef } from 'react'

import { useAdSpacesList } from '../../hooks/adSpaces/useAdSpacesList'
import { isEmptyArray } from '../../utils/object/isEmptyArray'
import { index } from '../../routes'
import { adSpaceCardDataCleaner } from '../../utils/adSpaceCardDataCleaner'
import AdSpaceCard from './AdSpaceCard'
import { AdSpaceListLoading } from './AdSpaceListLoading'
import EmptyAdSpacesListCard from './EmptyAdSpacesListCard'
import { useTranslation } from 'react-i18next'
import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'

import '../../styles/adSpaces/List.css'

const createCheckedAdSpaces = (adSpaces, isChecked) => {
  return adSpaces.reduce((acc, adSpace) => {
    acc[adSpace.uuid] = isChecked
    return acc
  }, {})
}

export function AdSpacesList({ adSpacesIds, height, onMouseEnter, onMouseLeave }) {
  const { adSpaces, addPage, hasMore } = useAdSpacesList(adSpacesIds)
  const { t } = useTranslation(['translation'])

  const [checkedAdSpaces, setCheckedAdSpaces] = useState({})
  const checkIndeterminated = useRef(false)
  const quantityChecboxSelected = useRef(0)
  const { addBidsToCart } = useBidsCartActions()

  useEffect(() => {
    setCheckedAdSpaces((prevChecked) => {
      const newChecked = adSpaces.reduce((acc, adSpace) => {
        acc[adSpace.uuid] = prevChecked[adSpace.uuid] || false
        return acc
      }, {})
      checkIndeterminated.current = Object.values(newChecked).includes(true) && !Object.values(newChecked).every(Boolean);
      quantityChecboxSelected.current = Object.values(newChecked).filter(value => value === true).length
      return newChecked;
    })
  }, [adSpaces])

  const handleAddToBulkCart = () => {
    const adSpacesFilter = adSpaces.filter(adSpace => checkedAdSpaces[adSpace.uuid] )
    addBidsToCart(adSpacesFilter)
  }

  const handleParentChange = (event) => {
    const isChecked = event.target.checked
    checkIndeterminated.current = false
    const newChecked =createCheckedAdSpaces(adSpaces, isChecked)
    quantityChecboxSelected.current = Object.values(newChecked).filter(value => value === true).length
    setCheckedAdSpaces(newChecked)
  }

  const handleChildChange = (uuid) => (event) => {
    const newChecked = {
      ...checkedAdSpaces,
      [uuid]: event.target.checked,
    }
    checkIndeterminated.current = Object.values(newChecked).includes(true) && !Object.values(newChecked).every(Boolean)
    quantityChecboxSelected.current = Object.values(newChecked).filter(value => value === true).length
    setCheckedAdSpaces(newChecked)
  }

  if (isEmptyArray(adSpacesIds)) {
    return <EmptyAdSpacesListCard />
  }

  const chipChecboxSelected = () => {
    return quantityChecboxSelected.current > 0 &&
      <Chip label={quantityChecboxSelected.current} sx={{ ml: 1, backgroundColor: 'white' }} size='small' color='secondary' variant='outlined' />
  }

  return (
    <div id='scrollableDiv' className='infinite-scroll-container' style={{ height }}>
      <InfiniteScroll
        dataLength={`${adSpaces.length}-${index}`}
        next={addPage}
        hasMore={hasMore}
        height={height}
        scrollThreshold={0.9}
        loader={<AdSpaceListLoading />}
        className='infinite-scroll'>

        <Paper className='infinite-scroll-checkbox'>
          <Checkbox
            color='primary'
            checked={Object.values(checkedAdSpaces).every(Boolean) && quantityChecboxSelected.current > 0}
            indeterminate={checkIndeterminated.current}
            onChange={handleParentChange}
            inputProps={{ 'aria-label': 'select ad space bulk' }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddToBulkCart}
            startIcon={<AddIcon />}
            disabled= { quantityChecboxSelected.current <= 0 }
          >
            { t('add_in_bulk', { ns: 'translation' }) }

            {chipChecboxSelected()}
          </Button>
        </Paper>

        {adSpaces.map((adSpace, index) => {
          return (
            adSpace && (
              <AdSpaceCard
                key={adSpace.uuid}
                adSpace={adSpaceCardDataCleaner(adSpace, 'adSpace')}
                variant='adSpace'
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                isChecked={!!checkedAdSpaces[adSpace.uuid]}
                onCheckboxChange={handleChildChange(adSpace.uuid)}
              />
            )
          )
        })}
      </InfiniteScroll>
    </div>
  )
}

AdSpacesList.propTypes = {
  adSpacesIds: propTypes.array.isRequired,
  height: propTypes.number.isRequired,
  onMouseEnter: propTypes.func,
  onMouseLeave: propTypes.func
}
