import { toast } from 'react-toastify'

import { severities } from '../enums/notifications/notificationsSeverities'

export default function notify(message, variant) {
  toast(message, {
    type: variant
  })
}

export const notifySuccess = (message) => {
  notify(message, severities.success)
}

export const notifyInfo = (message) => {
  notify(message, severities.info)
}

export const notifyWarning = (message) => {
  notify(message, severities.warning)
}

export const notifyError = (errors) => {
  if (Array.isArray(errors?.error)) {
    errors.error?.forEach((error) => {
      notify(error.message, severities.error)
    })
  } else {
    const notifications = errors?.messages ? errors?.messages.join(', ') : errors?.message
    if (notifications) {
      notify(notifications, severities.error)
    } else {
      notify('Hubo un error interno, intenta más tarde', severities.error)
    }
  }
}
