import '../../styles/adSpaces/Card.css'
import { ListItem, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

export default function EmptyAdSpacesListCard() {
  //
  /* HOOKS */
  const { t } = useTranslation(['adSpace'])

  //
  /* RETURN */
  return (
    <ListItem
      alignItems='flex-start'
      sx={{
        p: 0,
        width: '35%',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          height: '100px',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: 'transparent'
        }}
      >
        <Typography
          variant='h6'
          style={{
            fontWeight: 'bold',
            padding: '0 10px'
          }}
        >
          {t('empty_ad_spaces_result', { ns: 'adSpace' })}
        </Typography>
      </Paper>
    </ListItem>
  )
}
