import { Button, ButtonGroup, Paper, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { crudConst } from '../../utils/enums/crudConst'
import { AgencyFormFields } from './AgencyFormFields'

const { create, update } = crudConst

export const AgencyForm = ({ onSubmit, agency }) => {
  //
  /* CONSTANTS */
  const action = agency ? update : create
  //
  /* HOOKS */
  const navigate = useNavigate()
  const { t } = useTranslation(['translation', 'agency'])

  //
  /* METHODS */
  const handleSubmit = (values) => {
    onSubmit(values)
  }

  const handleGoBack = () => {
    navigate('/agencies')
  }

  //
  /* RETURN */
  return (
    <>
      <Formik
        initialValues={{
          name: agency?.name || '',
          corporate_name: agency?.corporate_name || '',
          national_id: agency?.national_id || '',
          trade_name: agency?.trade_name || '',
          address: agency?.address || '',
          email: agency?.email || '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          name: Yup.string().required(t('name_required', { ns: 'agency' })),
          corporate_name: Yup.string().nullable(),
          national_id: Yup.string()
            .required(t('national_id_required', { ns: 'agency' }))
            .matches(/\b[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[0-9kK]\b/, t('national_id_match', { ns: 'agency' })),
          address: Yup.string().nullable(),
          trade_name: Yup.string().nullable(),
          email: Yup.string()
            .email(t('email_invalid', { ns: 'agency' }))
            .nullable(),
          industry_id: Yup.string()
        })}>
        {({ errors, touched }) => (
          <Paper
            sx={{
              p: 1,
              mt: 2,
              mr: 2,
              flexDirection: 'column',
              gap: '10px',
              display: 'flex'
            }}
            elevation={3}>
            <Typography variant='h5' sx={{ color: 'secondary.dark', height: '25px', margin: 2 }}>
              {action === create ? t('new', { ns: 'agency' }) : t('edit', { ns: 'agency' })}
            </Typography>

            <Form sx={{ display: 'flex', gap: '10px' }}>
              <AgencyFormFields
                errors={errors}
                touched={touched}
                translated={t}
              />

              <ButtonGroup
                variant='outlined'
                aria-label='outlined button group'
                sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', gap: '10px' }}>
                <Button color='primary' type='button' variant='contained' onClick={handleGoBack}>
                  {t('back_to_list', { ns: 'agency' })}
                </Button>
                <Button color='secondary' type='submit' variant='contained'>
                  {action === update ? t('save', { ns: 'translation' }) : t('create', { ns: 'agency' })}
                </Button>
              </ButtonGroup>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  )
}

AgencyForm.propTypes = {
  onSubmit: propTypes.func,
  agency: propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
    corporate_name: propTypes.string,
    national_id: propTypes.string,
    trade_name: propTypes.string,
    address: propTypes.string,
    email: propTypes.string,
  })
}
