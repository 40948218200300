import InfoIcon from '@mui/icons-material/Info'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import { Chip, IconButton, ListItem, Paper, Tooltip, Zoom, Checkbox } from '@mui/material'
import Typography from '@mui/material/Typography'
import propTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { detailsVariants } from '../../utils/detailsVariants'
import { vacancyStatuses } from '../../utils/vacancy_status'
import { AddToCartButton } from '../AddToCartButton'
import { AdSpaceDetails } from './AdSpaceDetails'
import { AdSpaceOccupanciesPopover } from './AdSpaceOccupanciesPopover'

import '../../styles/adSpaces/Card.css'

// TODO: add a loading component
// TODO: review for performance issues

export const cardVariants = {
  default: 'adSpace',
  bid: 'bid'
}

export default function AdSpaceCard({
  adSpace,
  variant = cardVariants.default,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  isChecked = false,
  onCheckboxChange
}) {
  //
  /* HOOKS */

  const [isOpened, setIsOpened] = useState(false)
  const [selected, setSelected] = useState(null)
  const { t } = useTranslation(['translation', 'adSpace'])

  //
  /* METHODS */
  const handleCloseModal = () => setIsOpened(false)
  const handleInfoClick = (adSpace) => {
    setSelected(adSpace.uuid)
    setIsOpened(true)
  }

  //
  /* RETURN */
  return (
    <>
      <ListItem
        alignItems='flex-start'
        className='ad-space-card'
        onMouseEnter={() => {
          onMouseEnter(adSpace.uuid)
        }}
        onMouseLeave={() => {
          onMouseLeave(adSpace.uuid)
        }}>
        <Paper className='ad-space-card-paper'>
          <Checkbox
            color='primary'
            checked={isChecked}
            onChange={onCheckboxChange}
            inputProps={{ 'aria-label': 'select ad space' }}
          />

          <Paper
            component={'img'}
            className='ad-space-card-image'
            src={adSpace?.small_photo || `${process.env.PUBLIC_URL}/assets/shared/thumb-default.png`}
          />

          <IconButton
            sx={{
              color: 'secondary.main',
              position: 'absolute',
              top: '10px',
              left: '45px',
              zIndex: 1
            }}
            size='large'
            onClick={() => {
              handleInfoClick(adSpace)
            }}>
            <InfoTwoToneIcon fontSize='inherit' />
          </IconButton>

          <Paper component={'div'} elevation={0} className='ad-space-card-content'>
            <Tooltip title={adSpace?.name} arrow>
              <Typography noWrap variant='p' className='ad-space-name'>
                {adSpace?.name}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                <>
                  <strong>{t('format', { ns: 'adSpace' })}</strong> : {adSpace.format_name} |
                  <strong> {t('provider', { ns: 'adSpace' })}</strong> : {adSpace.provider_name}
                </>
              }
              arrow>
              <Typography noWrap variant='caption' className='ad-space-address'>
                <strong>{t('format', { ns: 'adSpace' })}</strong> : {adSpace.format_name} |
                <strong> {t('provider', { ns: 'adSpace' })}</strong> : {adSpace.provider_name}
              </Typography>
            </Tooltip>

            <Typography noWrap variant='h6' className='ad-space-price'>
              {t('currency', { val: adSpace.leasing_price, ns: 'adSpace' })}
            </Typography>
            {variant === cardVariants.default && (
              <>
                <div>
                  <Chip
                    label={vacancyStatuses[adSpace.vacancy_status].label}
                    color={vacancyStatuses[adSpace.vacancy_status].color}
                    variant='outlined'
                    size='small'
                    sx={{
                      margin: '5px 0px 5px 0',
                      width: '75%'
                    }}
                  />
                  {adSpace.vacancy_with_delay ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      sx={{
                        position: 'relative',
                        top: '5px',
                        right: '20px'
                      }}
                      title={<h3>{t('vacancy_with_delay', { ns: 'adSpace' })}</h3>}>
                      <InfoIcon color='warning' fontSize='small' />
                    </Tooltip>
                  ) : null}
                  {adSpace.occupancies ? (
                    <AdSpaceOccupanciesPopover key={`${adSpace.id}-occupancies`} occupancies={adSpace.occupancies} />
                  ) : null}
                </div>
                <AddToCartButton adSpace={adSpace} />
              </>
            )}
          </Paper>
        </Paper>
      </ListItem>
      {selected && isOpened && (
        <AdSpaceDetails
          isOpened={isOpened}
          closeModal={handleCloseModal}
          id={selected}
          variant={variant === cardVariants.default ? detailsVariants.withAddToCartButton : detailsVariants.default}
        />
      )}
    </>
  )
}

AdSpaceCard.propTypes = {
  adSpace: propTypes.object,
  variant: propTypes.string,
  onMouseEnter: propTypes.func,
  onMouseLeave: propTypes.func
}
