import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const bidBounceSlice = createSlice({
  name: 'bidBounce',
  initialState,
  reducers: {
    setBidsBounce: (state, action) => {
      Object.keys(state).forEach(uuid => {
        delete state[uuid]
      })

      action.payload.forEach(uuid => {
        state[uuid] = { active: false };
      })
    },
    setActiveBid: (state, action) => {
      const { uuid, active } = action.payload;
      if (state[uuid]) {
        state[uuid].active = active
      }
    },
    clearBidsBounce: (state) => {
      Object.keys(state).forEach(uuid => {
        state[uuid].active = false
      })
    }
  }
})

export const { setBidsBounce, setActiveBid, clearBidsBounce } = bidBounceSlice.actions
export default bidBounceSlice.reducer
