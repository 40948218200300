import EditIcon from '@mui/icons-material/Edit'
import { Button, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EasyTable } from '../../components/EasyTable'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import translatedHeaders from '../../utils/translatedHeaders'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

const url = crudConstPath.companies.index

const attrs = [
  'name',
  'national_id',
  'corporate_name',
  'trade',
  'address',
  'email',
  'phone',
  'created_at',
  'updated_at']

export function CompanyIndex() {
  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'company'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()
  const companies = data?.companies

  const headers = translatedHeaders(attrs, 'company')

  const actions = [
    {
      label: t('edit', { ns: 'company' }),
      onClick: id => navigate(`${id}/edit`),
      icon: <EditIcon />,
      attrId: 'id'
    }
  ]

  useEffect(() => {
    sendRequest(url, crudConstNames.get)
  }, [])

  const handleClick = () => {
    navigate('/companies/new')
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return (
    companies
      ? <Paper
        elevation={0}
        sx={{
          p: 1,
          mt: 2,
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
        >
          <Button sx={{ maxWidth: '200px', marginLeft: 'auto' }} type='submit' color='secondary' variant='contained' onClick={handleClick}>
            {t('new', { ns: 'company' })}
          </Button>
          <EasyTable headers={headers} data={companies} actions={actions} />
        </Paper>
      : null
  )
}
