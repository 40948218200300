import { useEffect, useState } from 'react'

import { detailsVariants as variants } from '../../utils/detailsVariants'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import { getAdSpaceFromBackend, getPublicAdSpaceFromBackend } from '../../utils/getAdSpaceFromBackend'
import notify from '../../utils/notifications/notify'

export const useAdSpaceDetails = (adSpaceId, variant) => {
  const [adSpace, setAdSpace] = useState(null)

  useEffect(() => {
    const fetchAdSpace = async () => {
      try {
        const adSpace = variant === variants.public
          ? await getPublicAdSpaceFromBackend(adSpaceId)
          : await getAdSpaceFromBackend(adSpaceId)
        setAdSpace(adSpace.data.data)
      } catch (error) {
        notify(error.message, severities.error)
      }
    }
    fetchAdSpace()
  }, [])

  return { adSpace }
}
