import ClearIcon from '@mui/icons-material/Clear'
import { EasyTable } from '../../components/EasyTable'
import useBackendApi from '../../hooks/useBackendApi'
import propTypes from 'prop-types'
import { Button, ButtonGroup, FormControl, IconButton, Paper, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { formattedSaleListTotalPrices } from '../../utils/formattedSaleListTotalPrices'
import { Field, Formik } from 'formik'

import '../../styles/campaigns/CampaignIndex.css'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

const headers = (t) => {
  return {
    serial: t('serial', { ns: 'campaign' }),
    title: t('title', { ns: 'campaign' }),
    advertiser_name: t('advertiser', { ns: 'campaign' }),
    status: t('status', { ns: 'campaign' }),
    user_name: t('user', { ns: 'campaign' }),
    created_at: t('created_at', { nd: 'translation' }),
    updated_at: t('updated_at', { ns: 'translation' }),
    total_price: t('total_price', { ns: 'campaign' })
  }
}

const actions = (navigate, t) => [
  // {
  //   label: t('view', { ns: 'translation' }),
  //   onClick: (id) => navigate(`/campaigns/${id}`),
  //   icon: <PreviewIcon />,
  //   attrId: 'id'
  // }
]

export function CampaignIndex() {
  const { t } = useTranslation(['translation', 'campaign'])
  const [campaigns, setCampaigns] = useState([])
  const [statusFilter, setStatusFilter] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)

  const searchParams = { campaign: { status: statusFilter, search: searchTerm } }

  const navigate = useNavigate()

  const url = crudConstPath.campaigns.index
  const { data, sendRequest } = useBackendApi()


  useEffect(() => {
    setLoadingSearch(true)
    const delayDebounceFn = setTimeout(() => {
      handleSubmit()
      setLoadingSearch(false)
    }, 600)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    handleSubmit()
  }, [statusFilter])

  useEffect(() => {
    if (data?.campaigns) {
      setCampaigns(data.campaigns)
    }
  }, [data])

  const handleStatusFilter = (status) => {
    setStatusFilter(status === statusFilter ? null : status)
  }
  const handleSubmit = () => {
    sendRequest(url, crudConstNames.get, {}, null, searchParams)
  }

  const formattedPricesCampaigns = formattedSaleListTotalPrices(campaigns)

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        mt: 2,
        mr: 2,
        backgroundColor: '#ddd'
      }}>
      <ButtonGroup variant="outlined" aria-label="Basic button group">
        <Button variant={statusFilter === 1 ? 'contained' : 'outlined'} onClick={() => handleStatusFilter(1)}>Pre-campañas</Button>
        <Button variant={statusFilter === 2 ? 'contained' : 'outlined'} onClick={() => handleStatusFilter(2)}>Campañas</Button>
      </ButtonGroup>
      <Formik
        initialValues={{
          status: statusFilter || null,
          search: searchTerm || null
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <FormControl className='form-control-search form-input' variant='outlined' size='small' sx={{ mb: 1 }}>
              <Field
                as={TextField}
                name='search'
                id='search'
                label={t('search')}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                size='small'
                InputProps={{
                  endAdornment: values.query_filter && (
                    <IconButton onClick={() => setSearchTerm('search', '')} size='small'>
                      <ClearIcon fontSize='small' />
                    </IconButton>
                  )
                }}
              />
            </FormControl>
          )
        }}
      </Formik>
      <EasyTable headers={headers(t)} data={formattedPricesCampaigns} loading={loadingSearch} />
    </Paper>
  )
}

CampaignIndex.propTypes = {}