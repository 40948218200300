import { getAuthorizationCode } from '../utils/authorization'
import { crudConstPath } from '../utils/enums/crudConst'
import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import axios from 'axios'
import { useEffect, useState } from 'react'

const url = crudConstPath.quotations.index

export function useGetQuotation(id) {
  const [loading, setLoading] = useState(false)
  const [quotation, setQuotation] = useState({})

  const getQuotation = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${url}/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuthorizationCode()}`
        }
      })
      setQuotation(response.data.quotation)
    } catch (error) {
      notify(error.response.data.message, severities.error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getQuotation()
  }, [id])

  return { quotation, bids: quotation?.bids || [], loading }
}
