export const countries = [
  {
    uuid: 'db485ff7-5edc-4716-90a5-2c5336bedd03',
    name: 'Chile',
    code: 'CL',
    locale: 'es-CL',
    timezone: 'Santiago'
  },
  {
    uuid: '4ba3c393-e63a-49f7-8292-1f8ee6358be0',
    name: 'Perú',
    code: 'PE',
    locale: 'es-PE',
    timezone: 'Lima'
  },
  {
    uuid: 'd97a1952-ed7b-42be-b9f2-c11e92ebf1fa',
    name: 'Argentina',
    code: 'AR',
    locale: 'es-AR',
    timezone: 'Buenos Aires'
  },
  {
    uuid: 'd1953491-b40a-4605-a52b-214972f6b7dd',
    name: 'Ecuador',
    code: 'EC',
    locale: 'es-EC',
    timezone: 'Quito'
  },
  {
    uuid: '8a8b3ca9-da34-4ece-b19c-a33e91084275',
    name: 'Paraguay',
    code: 'PY',
    locale: 'es-PY',
    timezone: 'Atlantic Time (Canada)'
  },
  {
    uuid: 'c1c20755-fa30-4416-852d-2bd43aa13d7f',
    name: 'Uruguay',
    code: 'UY',
    locale: 'es-UY',
    timezone: 'America/Montevideo'
  }
]
