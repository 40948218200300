import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { useAppSelector } from '../store'
import useBackendApi from '../useBackendApi'
import { useEffect, useState } from 'react'

const AD_SPACES_URL = crudConstPath.adSpaces.catalog_index
const PAGE_SIZE = 30

// useAdspacesData table es un hook para
// obtener la lista de adspaces desde el backend y recorerlos pagina por pagina.
// TODO: cambiar nombre o utilizar useAdSpaceList que deberia hacer lo mismo, evaluar las diferencias.
export const useAdSpacesDataTable = (sortInfo, isActive) => {
  const mapFilters = useAppSelector((state) => state.mapFilter.adSpacesFilter)
  const { data: adSpacesData, loading, sendRequest } = useBackendApi()
  const [adSpaces, setAdSpaces] = useState([])
  const [totalPages, setTotalPages] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)

  const fetchAdSpaces = () => {
    if (isActive && !loading) {
      const params = { ...mapFilters, page: { size: pageSize, number: page }, sort_info: { ...sortInfo } }
      sendRequest(AD_SPACES_URL, crudConstNames.get, null, null, params)
    }
  }

  useEffect(() => {
    fetchAdSpaces()
  }, [page, mapFilters, sortInfo, pageSize, isActive])

  useEffect(() => {
    if (!loading && adSpacesData) {
      const {
        data,
        meta: { total_pages }
      } = adSpacesData
      setAdSpaces(data)
      setTotalPages(total_pages)
    }
  }, [loading])

  return { adSpaces, setPage, setPageSize, totalPages, loading }
}
