import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isActive: false
}

export const visitModeSlice = createSlice({
  name: 'visitMode',
  initialState,
  reducers: {
    setVisitMode: (state, action) => {
      state.isActive = action.payload
    }
  }
})

export const { setVisitMode } = visitModeSlice.actions
export default visitModeSlice.reducer
