import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// CONSTANTS
export const ShowUnavailableCheckbox = ({ values, setFieldValue }) => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])
  const date_present = values?.availability_end_date || values?.availability_start_date

  // RETURN
  return (
    <Tooltip title={t('show_unavailable_tooltip', { ns: 'adSpace' })}>
      <FormControlLabel
        disabled={!date_present}
        control={
          <Checkbox
            name='show_unavailable'
            id='show_unavailable'
            defaultChecked={false}
            onChange={(value) => setFieldValue('show_unavailable', value.target.checked)}
          />
        }
        label={t('show_unavailable', { ns: 'adSpace' })}
      />
    </Tooltip>
  )
}

ShowUnavailableCheckbox.propTypes = {
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired
}
