import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useEffect } from 'react'

import planningTheme from './Theme'
import useAnalytics from './hooks/useAnalytics'
import { AuthProvider } from './hooks/useAuth'
import { index as Routes } from './routes/index'

import 'moment/locale/es'

import './App.css'
import '@fontsource/lato/300.css'
import '@fontsource/lato/300-italic.css'
import '@fontsource/lato/400.css'
import '@fontsource/lato/400-italic.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/700-italic.css'

function App() {
  const { initAnalytics } = useAnalytics();

  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale('es')}>
      <ThemeProvider theme={planningTheme}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
