import PreviewIcon from '@mui/icons-material/Preview'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Badge } from '@mui/material'
import propTypes from 'prop-types'

import { useAppSelector } from '../../hooks/store'

const DataTableActionButtons = ({ item, handleInfoClick, handleAddToCart }) => {
  const bidsInCart = useAppSelector((state) =>
    state.bidsCart.bids.filter((bid) => bid.ad_space_uuid === item?.uuid && bid._destroy === false)
  )
  const badgeContent = bidsInCart.length > 9 ? '+9' : bidsInCart.length

  return (
    <>
      <PreviewIcon onClick={() => handleInfoClick(item.uuid)} className='custom-data-table-button' />
      <Badge badgeContent={badgeContent} color='primary'>
        <ShoppingCartIcon onClick={() => handleAddToCart(item.uuid)} className='custom-data-table-button' />
      </Badge>
    </>
  )
}

DataTableActionButtons.propTypes = {
  item: propTypes.shape({
    uuid: propTypes.string.isRequired
  }).isRequired,
  handleInfoClick: propTypes.func.isRequired,
  handleAddToCart: propTypes.func.isRequired
}

export default DataTableActionButtons
