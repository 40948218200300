import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import propTypes from 'prop-types'
import { DropdownMenu } from './shared/DropdownMenu'

export function EasyTableRow({ data, rowKeys, actions, index }) {
  return (
    <TableRow key={index}>
      {rowKeys.map((key) => (
        <TableCell align='center' key={index + key}>
          {data[key]}
        </TableCell>
      ))}
      {actions && (
        <TableCell align='center'>
          {actions.map((action) => (
            action.options ? (
              <DropdownMenu key={index + action.label} title={action.label} options={action.options} data={data} component='iconButton' />
             ) : (
              <Tooltip title={action.label} key={index + action.label} arrow>
                <IconButton
                  disabled={action.funcDisabled ? action.funcDisabled(data) : false}
                  key={index + action.label}
                  onClick={() => action.onClick(data[action.attrId])}>
                  {action.icon}
                </IconButton>
              </Tooltip>)
          ))}
        </TableCell>
      )}
    </TableRow>
  )
}

EasyTableRow.propTypes = {
  data: propTypes.object.isRequired,
  rowKeys: propTypes.array.isRequired,
  actions: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      onClick: propTypes.func,
      icon: propTypes.element.isRequired,
      funcDisabled: propTypes.func,
      attrId: propTypes.string
    })
  ),
  index: propTypes.number.isRequired
}
