import axios from 'axios'
import jsonToFormData from 'json-form-data'
import { useState } from 'react'

import { getAuthorizationCode } from '../utils/authorization'
import { notifyError } from '../utils/notifications/notify'

const useBackendApi = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const bodyToFetch = (body, formData) => {
    if (formData) {
      return jsonToFormData(body, { showLeafArrayIndexes: false })
    }
    return body
  }

  const sendRequest = async (
    url,
    method,
    headers = { Accept: 'application/json' },
    body = null,
    params = null,
    responseType = 'json',
    formData = false
  ) => {
    setLoading(true)
    try {
      const response = await axios({
        url,
        method,
        headers: {
          Authorization: `Bearer ${getAuthorizationCode()}`,
          ...headers
        },
        data: bodyToFetch(body, formData),
        params,
        responseType
      })
      setData(response.data)
      return response.data
    } catch (error) {
      notifyError(error.response.data)
      setData(error.response.data.error)

    } finally {
      setLoading(false)
    }
  }

  return { loading, data, sendRequest }
}

export default useBackendApi
