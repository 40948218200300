/* eslint-disable react/prop-types */
import { CardMedia, CardContent, Typography, CardActions, Card, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAdSpaceDetails } from '../../hooks/adSpaces/useAdSpaceDetails'
import { durationUnits } from '../../utils/durationUnits'
import { VisitModeWrapper } from '../VisitModeWrapper'
import { AdSpaceDetails } from '../adSpaces/AdSpaceDetails'

export function QuotationBidCard({ bid, onMouseEnter, onMouseLeave, detailsVariant, publicPage, packagePricing }) {
  const {
    ad_space_uuid,
    ad_space_sku,
    ad_space_format_name,
    ad_space_name,
    ad_space_provider_name,
    duration,
    duration_unit,
    leasing_price
  } = bid
  const [isOpened, setIsOpened] = useState(false)
  const { t } = useTranslation(['quotation', 'adSpace'])
  const { adSpace } = useAdSpaceDetails(ad_space_uuid, detailsVariant)
  const handleCloseModal = () => setIsOpened(false)
  const cardPhoto = adSpace?.photo || `${process.env.PUBLIC_URL}/assets/shared/default-photo.png`

  return (
    <>
      <Card
        className='quotation-card'
        onMouseEnter={() => onMouseEnter(ad_space_uuid)}
        onMouseLeave={() => onMouseLeave(ad_space_uuid)}>
        <div className='quotation-card-media-container'>
          <CardMedia
            sx={{ objectFit: 'contain', aspectRatio: '3/4' }}
            className='quotation-card-media-image'
            component='img'
            image={cardPhoto}
            alt={t('imageAlt', { ns: 'adSpace' })}
          />
        </div>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant='body2' component='div' className='quotation-card-adspace-format'>
            {ad_space_sku} / {ad_space_format_name}
          </Typography>
          <Typography gutterBottom variant='h6' component='div' className='quotation-card-adspace-name'>
            {ad_space_name}
          </Typography>
          {
            !publicPage &&
            <VisitModeWrapper>
              <Typography gutterBottom variant='body2' component='div' className='quotation-card-adspace-provider-name'>
                {ad_space_provider_name}
              </Typography>
            </VisitModeWrapper>
          }
          {
            packagePricing ?
            null
            :
            <Typography gutterBottom variant='subtitle1' component='div'>
              {t('leasingDetails', {
                price: t('currency', { val: leasing_price, ns: 'adSpace' }),
                duration,
                unit: durationUnits[duration_unit],
                ns: 'quotation'
              })}
            </Typography>
          }
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mt: 'auto' }}>
          <Button onClick={() => setIsOpened(true)} variant='contained' color='secondary'>
            {t('viewDetailsButton')}
          </Button>
        </CardActions>
      </Card>
      <AdSpaceDetails
        isOpened={isOpened}
        closeModal={handleCloseModal}
        id={bid?.ad_space_uuid}
        variant={detailsVariant}
      />
    </>
  )
}
