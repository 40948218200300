import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import { Box } from '@mui/system'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from '../modal/Modal'

// Main purpose is to use as a secondary action before submitting a main action
export function ConfirmationModal({
  isOpened,
  closeModalHandler,
  confirmHandler,
  titleContent,
  bodyContent,
  confirmContent,
  confirmButtonType,
  confirmParams
}) {
  const { t } = useTranslation(['translation'])
  return (
    <Modal isOpened={isOpened} closeModal={closeModalHandler}>
      <Card>
        <CardHeader
          sx={{ bgcolor: 'secondary.main', color: 'white' }}
          bgcolor='secondary.main'
          title={titleContent}
          className='header'
        />
        <CardContent className='content'>
          <Box
            sx={{ flex: 4, display: 'flex', flexDirection: 'column', px: 1 }}>
            <Typography variant='body1'>
              { bodyContent }
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          <Box className='actions' sx={{ flex: 1, display: 'flex', flexDirection: 'row', pl: 2 }}>
            <Button variant='outlined' onClick={closeModalHandler}>
              {t('close')}
            </Button>
            <Button variant='contained' color={confirmButtonType} onClick={() => confirmHandler(confirmParams)}>
              {confirmContent}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  isOpened: propTypes.bool,
  closeModalHandler: propTypes.func,
  confirmHandler: propTypes.func,
  titleContent: propTypes.string,
  bodyContent: propTypes.string,
  confirmContent: propTypes.string,
  confirmButtonType: propTypes.string,
  confirmParams: propTypes.string
}