export const extractMarkersFromBids = (bids) => {
  return (bids?.map(bid => {
    return {
      lat: bid.ad_space_lat,
      lng: bid.ad_space_lng,
      icon: bid.ad_space_icon,
      uuid: bid.ad_space_uuid,
      item_ids: [bid.ad_space_uuid],
      num_points: 1

    }
  }))
}
