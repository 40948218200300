import { setQuotationTab  } from '../../store/quotationTabSlice'
import { useAppDispatch } from '../store'

export const useQuotationTabActions = () => {
  const dispatch = useAppDispatch()
  const setCurrentQuotationTab = (tab) => {
    dispatch(setQuotationTab(tab))
  }

  return { setCurrentQuotationTab }
}
