import ReactGA from 'react-ga4'

const useAnalytics = () => {

  const initAnalytics = () => {
    const isDebugMode = process.env.NODE_ENV === 'development' && process.env.REACT_APP_ANALYTICS_DEBUG_ON === 'true';
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_KEY, {
      gaOptions: {
        debug_mode: isDebugMode
      }
    })
  }

  const setUserProperties = (userId, companyName) => {
    ReactGA.set({ userId })
    ReactGA.gtag('set', 'user_properties', { company: companyName })
  }

  return { initAnalytics, setUserProperties }
}

export default useAnalytics
