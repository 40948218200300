// TODO: cardVariants should be an enum from utils
// TODO:
import { cardVariants } from '../components/adSpaces/AdSpaceCard.jsx'
import { mappedFormats } from './formats.js'

// TODO: rename adSpaceCardDataCleaner to one of the following:
// FormatBackendAdSpaceData
// FormatAdSpaceCardData
// FormatAdSpaceData
// FormatAdSpaceCardBackendData
// FormatAdSpaceCardDataBackend

// this function is used to format the data for the adSpaceCard
// is not cleaning anything
export const adSpaceCardDataCleaner = (data, variant) => {
  if (variant === cardVariants.default) {
    return {
      ...data,
      format_name: mappedFormats[data.format_id]
    }
  } else {
    return {
      ...data,
      uuid: data.ad_space_uuid,
      name: data.ad_space_name,
      thumb_photo: data.ad_space_thumb_photo,
      format_name: data.ad_space_format_name,
      lat: data.ad_space_lat,
      lng: data.ad_space_lng,
      icon: data.ad_space_icon,
      provider_id: data.ad_space_provider_id,
      provider_name: data.ad_space_provider_name
    }
  }
}
