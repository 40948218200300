import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AgencyForm } from '../../components/agencies/AgencyForm'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export function AgencyNew() {

  //
  /* CONSTANTS */
  const agencyCreateUrl = crudConstPath.agencies.create

  //
  /* HOOKS */
  const { t } = useTranslation(['agency'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()

  useEffect(() => {
    sendRequest(crudConstPath.agencies.new, crudConstNames.get)
  }, [])

  //
  /* METHODS */
  const handleCreate = (values) => {
    const agencyData = { agency: values }
    sendRequest(agencyCreateUrl, crudConstNames.post, {}, agencyData).then((response) => {
      if (response.success) {
        notify(t('created', { ns: 'agency' }), severities.success)
        navigate('/agencies')
      }
    })
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return <AgencyForm onSubmit={handleCreate} />
}