import ExportQuotationToPdfButton from './ExportQuotationToPdfButton'
import ExportQuotationToXLSXButton from './ExportQuotationToXLSXButton'
import ExportQuotationToPdfDropdown from './ExportQuotationToPdfDropdown'
import propTypes from 'prop-types'

const ExportQuotationOptions = ({ quotationId, filename, packagePricing }) => {
  return (
    <>
      <ExportQuotationToXLSXButton quotationId={quotationId} filename={filename}/>
      {
        packagePricing ?
        (<ExportQuotationToPdfButton quotationId={quotationId} filename={filename}/>)
        :
        (<ExportQuotationToPdfDropdown quotationId={quotationId} filename={filename}/>)

      }
    </>
  )
}

ExportQuotationOptions.propTypes = {
  quotationId: propTypes.string.isRequired,
  filename: propTypes.string.isRequired,
  packagePricing: propTypes.bool
}

export default ExportQuotationOptions
