import axios from 'axios'
import { useEffect, useState } from 'react'

import { getAuthorizationCode } from '../utils/authorization'
import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import { isEmptyObject } from '../utils/object/isEmptyObject'
import { useAppSelector } from './store'
import { crudConstPath } from '../utils/enums/crudConst'

const MARKERS_URL = crudConstPath.adSpaces.geo_index
// const insideOldBounds = (bounds, oldBounds) => {
//   return oldBounds.contains(bounds.getSouthWest()) && oldBounds.contains(bounds.getNorthEast())
// }

// el use effect cambia cuando cambian los filtros o cuando cambian los bounds
// los bounds cambian solo cuando se cargan tiles nuevos lo que no es muy amenudo
export function useQuotationMapMarkersFetcher() {
  const [loading, setLoading] = useState(true)
  const [loadedMarkers, setLoadedMarkers] = useState(null)

  // const [oldBounds, setOldBounds] = useState(null)
  // const [oldMapFilters, setOldMapFilters] = useState(null)

  const mapFilters = useAppSelector((state) => state.mapFilter)

  useEffect(() => {
    const fetchMarkers = async () => {
      if (isEmptyObject(mapFilters.bounds)) {
        return
      }

      // TODO: Volver a poner esto cuando el backend responda con los id y los iconos
      // Tenemos clusters y cuando haya zoom in, descomponenmos estos cluster
      // y mostramos los markers individuales sin pedir al backend nuevamente
      // const currentBounds = window.google?.maps?.LatLngBounds
      //   ? new window.google.maps.LatLngBounds(mapFilters.bounds)
      //   : null

      // este if es para no cargar los markers si no cambian los filtros y si se hace zoom in
      // if (
      //   oldBounds &&
      //   currentBounds &&
      //   JSON.stringify(mapFilters.adSpacesFilter) === JSON.stringify(oldMapFilters) &&
      //   (oldBounds.equals(currentBounds) || insideOldBounds(currentBounds, oldBounds))
      // ) {
      //   const markersInViewport = loadedMarkers.filter((marker) => {
      //     const markerPosition = { lat: marker.lat, lng: marker.lng }
      //     return currentBounds.contains(markerPosition)
      //   })
      //   setViewPortMarkers(markersInViewport)
      //   return
      // }
      const { adSpacesFilter, bounds, zoom } = mapFilters
      try {
        setLoading(true)
        let allData = []
        const response = await axios.get(MARKERS_URL, {
          params: { ...adSpacesFilter, bounds, zoom },
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${getAuthorizationCode()}`
          }
        })
        const { data } = response.data
        allData = [...allData, ...data]
        setLoadedMarkers(allData)
        // setLoadedMarkers(allData)
        // setOldBounds(currentBounds)
        // setOldMapFilters(mapFilters.adSpacesFilter)
      } catch (error) {
        notify(error.message, severities.error)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 500);
      }
    }

    fetchMarkers()
  }, [mapFilters]) // was [mapFilters, oldBounds]

  return { loadedMarkers, loading }
}
