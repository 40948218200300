import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import propTypes from 'prop-types'

import { crudConstPath } from '../../utils/enums/crudConst'
import { useReplicateRecord } from '../../hooks/useReplicateRecord'
import { DropdownMenu } from '../shared/DropdownMenu'

export function DropdownQuotationButton({data}) {
    const { t } = useTranslation(['translation', 'quotation'])
    const { replicateRecord } = useReplicateRecord()
    const navigate = useNavigate()

    const handleOnClickReplicate = async (id) => {
      try {
        const response = await replicateRecord(crudConstPath.quotations.replicate(id))
        navigate(`/quotations/${response.message.id}/edit`)
        notify(t('replicate_success', { ns: 'quotation' }), severities.success)

      } catch (error) {
        console.warn(error)
      }
    }

    const dropdownOptions = {
      label: t('more-options', { ns: 'translation' }),
      icon: <MoreVertIcon/>,
      options: [
        {
          label: t('replicate', { ns: 'quotation' }),
          onClick: (id) => handleOnClickReplicate(id),
          attrId: 'id'
        },
      ]
    }

    return (<DropdownMenu title={dropdownOptions.label} options={dropdownOptions.options} data={data} />)
}
DropdownQuotationButton.propTypes = {
  data: propTypes.object
}