/**
 * Este archivo contiene el contexto de autenticación
 * y el proveedor de contexto.
 *
 * El contexto de autenticación contiene:
 * - isAuthenticated: booleano que indica si el usuario está autenticado o no
 * - user: objeto con los datos del usuario autenticado
 * - isLoading: booleano que indica si se está realizando una petición al servidor
 * - login: función para iniciar sesión
 * - logout: función para cerrar sesión
 * - error: string con el mensaje de error
 * - getToken: función para obtener el token de autenticación
 */

import { isAuthenticated as isLoggedIn } from '../utils/authorization'
import useAnalytics from './useAnalytics'
import axios from 'axios'
import Cookies from 'js-cookie'
import { createContext, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { crudConstPath } from '../utils/enums/crudConst'

const authUrl = crudConstPath.session.login
const logoutUrl = crudConstPath.session.logout

// funcion para usar el contexto
// con esto puedo obtener el objeto que se encuentra en el contexto
const AuthContext = createContext()

export const useAuth = () => {
  const auth = useContext(AuthContext)
  return auth
}

// componente que provee el contexto a los componentes hijos
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user')) || null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [lang, setLang] = useState(document.documentElement.getAttribute('lang') || 'es-CL')
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { setUserProperties } = useAnalytics()

  useEffect(() => {
    // Guardar el estado de isAuthenticated en el almacenamiento local cada vez que cambie
    if (window.location.pathname === '/') {
      isLoggedIn() ? navigate('/quotations/new') : navigate('/')
    }
    window.localStorage.setItem('user', JSON.stringify(user))
    i18n.changeLanguage(lang)
  }, [user])

  const login = async (email, password) => {
    setIsLoading(true)
    try {
      const response = await axios.post(authUrl, { user: { email, password } })
      const jwt = response.headers.authorization.split(' ')[1]
      const name = response.data.data.name
      const locale = response.data.data.locale
      const userId = response.data.data.id
      const companyName = response.data.data.company_name
      const visitMode = response.data.data.visit_mode
      setUserProperties(userId, companyName)
      const userPermissions = response.data.data.permissions
      Cookies.set('token', jwt, {
        expires: 15,
        secure: true,
        sameSite: 'strict'
      })
      Cookies.set('permissions', JSON.stringify(userPermissions), {
        expires: 15,
        secure: true,
        sameSite: 'strict'
      })
      Cookies.set('visitMode', visitMode, {
        secure: true,
        sameSite: 'strict'
      })
      setLang(locale)
      setError(null)
      setUser({ email, name, userId })
      setIsLoading(false)
    } catch (error) {
      setUser({})
      setError(t('login_error', { ns: 'user' }))
    } finally {
      setIsLoading(false)
    }
  }

  const logout = () => {
    setIsLoading(true)
    try {
      axios.delete(logoutUrl)
      setUser(null)
      setError(null)
      setUserProperties(null, null);
      Cookies.remove('token')
      Cookies.remove('permissions')
    } catch (error) {
      setError(t('logout_error', { ns: 'user' }))
    } finally {
      setIsLoading(false)
    }
  }

  const value = {
    user,
    isLoading,
    login,
    logout,
    error
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
