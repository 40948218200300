import '../../styles/navbar/SideMenuButton.css'
import { SideMenu } from './SideMenu'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'
import { useState } from 'react'

export function SideMenuButton() {
  // HOOKS

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // METHODS

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
  }

  // RETURN

  return (
    <>
      <IconButton edge='start' color='inherit' aria-label='menu' sx={{ mr: 2 }} onClick={toggleMenu}>
        <MenuIcon />
      </IconButton>
      <SideMenu isActive={isMenuOpen} closeMenu={closeMenu} />
    </>
  )
}
