
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import { useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuButtonUnderline,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  RichTextEditorProvider,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  RichTextField,
  LinkBubbleMenu,
  LinkBubbleMenuHandler,
} from "mui-tiptap"
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import '../../styles/shared/CustomRichField.css'

// Main purpose is to act as formik field and set values properly
export function CustomRichField({ setFieldValue, content, name, size = 'sm' }) {
  const { t } = useTranslation(['translation'])
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // These options were added to prevent an error loading multiple times the same libraries
        gapcursor: false,
        dropcursor: false,
      }),
      Underline,
      LinkBubbleMenuHandler,
      Link.configure({
        openOnClick: false,
        autolink: true,
        labels: {
          editLinkAddTitle: 'Agregar enlace',
          editLinkEditTitle: 'Editar',
          editLinkTextInputLabel: 'Editar',
        }
      }),
      Highlight.configure({ multicolor: true })],
    content,
    onUpdate({ editor }) {
      setFieldValue(name, editor.getHTML())
    },
    editorProps: {
      attributes: {
        class: `tiptap-container-${size}`,
      }
    }
  })


  return (
    <RichTextEditorProvider editor = { editor } >
      <RichTextField
        controls={
          <MenuControlsContainer>
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuButtonUnderline />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
            <MenuButtonEditLink />
            <MenuButtonHighlightColor />
          </MenuControlsContainer>
        }
      />
      <LinkBubbleMenu
        labels={{
          viewLinkEditButtonLabel: t('edit', { ns: 'translation' }),
          viewLinkRemoveButtonLabel: t('clear', { ns: 'translation' }),
          editLinkAddTitle: t('add', { ns: 'translation' }),
          editLinkEditTitle: t('update', { ns: 'translation' }),
          editLinkCancelButtonLabel: t('cancel', { ns: 'translation' }),
          editLinkTextInputLabel: t('text', { ns: 'translation' }),
          editLinkHrefInputLabel: t('url', { ns: 'translation' }),
          editLinkSaveButtonLabel: t('save', { ns: 'translation' }),
        }}/>
    </RichTextEditorProvider>
  )
}

CustomRichField.propTypes = {
  setFieldValue: propTypes.func,
  content: propTypes.string,
  name: propTypes.string,
  size: propTypes.string
}
