import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setBidsBounce, setActiveBid } from '../../store/bidBounceSlice'

const useBidsBounce = (bids) => {
  const dispatch = useDispatch()
  const quotationTabValue = useSelector(state => state.quotationTab.currentTab)
  const bidsBounce = useSelector(state => state.bidBounce)
  const item_uuids = bids.map( bid => bid.ad_space_uuid)

  useEffect(() => {
    if (quotationTabValue === 2) {
      dispatch(setBidsBounce(item_uuids))
    }
  }, [quotationTabValue, bids])

  const handleSetActiveBounce = (uuid, active) => {
    dispatch(setActiveBid({ uuid, active }))
  };

  return {
    bidsBounce,
    handleSetActiveBounce
  };
};

export default useBidsBounce
