import EditIcon from '@mui/icons-material/Edit'
import { Button, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EasyTable } from '../../components/EasyTable'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import translatedHeaders from '../../utils/translatedHeaders'
import { useCheckPermissions } from '../../utils/useCheckPermissions'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export const UserIndex = () => {
  //
  /* CONSTANTS */
  const url = crudConstPath.users.index
  const isSysAdmin = useCheckPermissions(['manage'], 'all')

  const attrs = [
    'name',
    'phone',
    'email',
    'role',
  ]
  if (isSysAdmin) attrs.push('company')


  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'user'])
  const navigate = useNavigate()
  const { data, loading, error, sendRequest } = useBackendApi()
  const users = data?.users
  const headers = translatedHeaders(attrs, 'user')

  const actions = [
    {
      label: t('edit', { ns: 'translation' }),
      onClick: id => navigate(`${id}/edit`),
      icon: <EditIcon />,
      attrId: 'id'
    }
  ]

  useEffect(() => {
    sendRequest(url, crudConstNames.get)
  }, [])

  const handleClick = () => {
    navigate('/users/new')
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return (
    users
      ? <Paper
        elevation={0}
        sx={{
          p: 1,
          mt: 2,
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <Button sx={{ maxWidth: '200px', marginLeft: 'auto' }} type='submit' color='secondary' variant='contained' onClick={handleClick}>
          {t('new', { ns: 'user' })}
        </Button>
        <EasyTable headers={headers} data={users} actions={actions} />
      </Paper>
      : null
  )
}
