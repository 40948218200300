import propTypes from 'prop-types'

import { BidsTotalsTable } from './BidsTotalsTable'

export function QuotationBidTotalTable({ action }) {
  return (
    <BidsTotalsTable action={action}/>
  )
}

QuotationBidTotalTable.propTypes = {
  action: propTypes.number
}
