import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { AgencyForm } from '../../components/agencies/AgencyForm'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export function AgencyEdit() {
  //
  /* CONSTANTS */
  const { id } = useParams()
  const agencyUpdateUrl = crudConstPath.agencies.update(id)
  const agencyEditUrl = crudConstPath.agencies.edit(id)

  //
  /* HOOKS */
  const { t } = useTranslation(['agency'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()

  useEffect(() => {
    sendRequest(agencyEditUrl, crudConstNames.get)
  }, [])

  const agency = data?.agency

  //
  /* METHODS */
  const handleCreate = (values) => {
    const agencyData = { agency: values }
    sendRequest(agencyUpdateUrl, crudConstNames.put, {}, agencyData).then((response) => {
      if (response?.success) {
        notify(t('updated', { ns: 'agency' }), severities.success)
        navigate('/agencies')
      }
    })
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return agency ? <AgencyForm onSubmit={handleCreate} agency={agency} /> : null
}