import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

import '../../../styles/utils/data-table/custom-pagination.scss'
import { Box } from '@mui/system'

const generatePageNumbers = (currentPage, totalPages) => {
  const maxPages = totalPages < 4 ? totalPages : 4
  const middleIndex = Math.floor(maxPages / 2)
  let startPage

  if (totalPages <= maxPages || currentPage <= middleIndex) {
    startPage = 1
  } else if (currentPage >= totalPages - middleIndex) {
    startPage = totalPages - maxPages + 1
  } else {
    startPage = currentPage - middleIndex
  }

  const pageNumbers = Array.from({ length: maxPages }, (_, index) => startPage + index).filter(
    (pageNumber) => pageNumber <= totalPages
  )

  return pageNumbers
}

export function CustomPagination({
  rowCount: totalPagesFromBackend,
  onChangePage,
  currentPage,
  onChangeRowsPerPage,
  rowsPerPage,
  paginationRowsPerPageOptions,
  paginationComponentOptions
}) {
  const [totalPages, setTotalPages] = useState([])
  const totalNumberOfPages = totalPagesFromBackend

  // TODO: sacar este useEffect, hacer lo mismo pero de otra forma.
  // useEffect se utiliza para efectos secundarios(como hacer llamadas a una API),
  // no para calcular valores que se van a utilizar en el componente.
  useEffect(() => {
    setTotalPages(generatePageNumbers(currentPage, totalNumberOfPages))
  }, [currentPage, totalPagesFromBackend])

  const goToPage = (page) => {
    onChangePage(page)
  }
  const goToNextPage = () => {
    if (currentPage >= totalNumberOfPages) return
    onChangePage(currentPage + 1)
  }
  const goToPreviousPage = () => {
    if (currentPage === 1) return
    onChangePage(currentPage - 1)
  }

  return (
    <div className='custom-pagination-component'>
      <Box component='span' sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <p>{paginationComponentOptions.rowsPerPageText}</p>
        <select
          value={rowsPerPage}
          onChange={() => {
            onChangeRowsPerPage(event.target.value)
          }}>
          {paginationRowsPerPageOptions.map((perPage) => (
            <option key={perPage} value={perPage}>
              {perPage}
            </option>
          ))}
        </select>
      </Box>
      <ChevronLeftIcon onClick={() => goToPreviousPage()} />
      {totalPages?.map((pageNumber) => (
        <div
          key={pageNumber}
          className={`custom-pagination-component-page-item ${pageNumber === currentPage && 'page-item-active'}`}
          aria-hidden='true'
          onClick={() => goToPage(pageNumber)}>
          {pageNumber}
        </div>
      ))}
      <p>de {totalNumberOfPages}</p>
      <ChevronRightIcon onClick={() => goToNextPage()} />
    </div>
  )
}

export default CustomPagination

CustomPagination.propTypes = {
  rowCount: PropTypes.number,
  onChangePage: PropTypes.func,
  currentPage: PropTypes.number
}
