import { FormControl, Paper, TextField } from '@mui/material'
import { Field } from 'formik'
import propTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelectForm } from '../../hooks/useSelectForm'
import { SimpleAdvertiserForm } from '../advertisers/SimpleAdvertiserForm'
import { ModalAgencyForm } from '../agencies/ModalAgencyForm'
import { SelectForm } from '../shared/SelectForm'

export function QuotationData({ errors, touched, advertisers, agencies, industries }) {
  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'quotation'])

  const [isOpenedAdvertiserModal, setIsOpenedAdvertiserModal] = useState(false)
  const [isOpenedAgencyModal, setIsOpenedAgencyModal] = useState(false)

  const advertiserSelector = useSelectForm('advertiser', 'advertiser',advertisers, setIsOpenedAdvertiserModal)
  const agencySelector = useSelectForm('agency', 'agencies',agencies, setIsOpenedAgencyModal)

  //
  /* RETURN */
  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 3 }} elevation={3}>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 2 }} fullWidth>
        <Field
          as={TextField}
          name='title'
          error={errors.title && touched.title}
          helperText={errors.title && touched.title ? errors.title : ''}
          type='title'
          id='title'
          label={`${t('title', { ns: 'quotation' })} *`}
          fullWidth
          sx={{ flex: 1 }}
          size='small'
        />
      </FormControl>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 2 }} fullWidth>
        <SelectForm
          resource='advertiser'
          value={advertiserSelector.optionRef.current}
          onChange={advertiserSelector.handleSelectChange}
          options={advertiserSelector.selectOptions}
          ModalForm={SimpleAdvertiserForm}
          isModalOpen={isOpenedAdvertiserModal}
          onModalClose={() => setIsOpenedAdvertiserModal(false)}
          onSubmit={advertiserSelector.handleCreateResource}
          isRequired
          modalProps={{ industries }}
          error={errors.advertiser_id && touched.advertiser_id}
          helperText={errors.advertiser_id && touched.advertiser_id ? errors.advertiser_id : ''}
        />

        <SelectForm
          resource='agency'
          value={agencySelector.optionRef.current}
          onChange={agencySelector.handleSelectChange}
          options={agencySelector.selectOptions}
          ModalForm={ModalAgencyForm}
          isModalOpen={isOpenedAgencyModal}
          onModalClose={() => setIsOpenedAgencyModal(false)}
          onSubmit={agencySelector.handleCreateResource}
        />
      </FormControl>
      <Field
        as={TextField}
        name='comments'
        type='comments'
        sx={{ flex: 1 }}
        id='comments'
        label={t('comments', { ns: 'quotation' })}
        multiline
        rows={4}
      />
    </Paper>
  )
}

QuotationData.propTypes = {
  errors: propTypes.object,
  touched: propTypes.object,
  advertisers: propTypes.array,
  agencies: propTypes.array,
  industries: propTypes.array
}
