import { bidPricesTypes } from "../enums/bidPrices"
import { calculateLeasingPrice } from "./calculateLeasingPrice"
import { calculateLeasingTotal } from "./calculateLeasingTotal"

const FROM_BASE_PRICE = true

// Separar valor con el decimal para usar en un acumulador
const adjustValue = (price, decimalAccumulator) => {
  if (decimalAccumulator > 0) {
    price += Math.round(decimalAccumulator);
  }
  return {
    truncated: Math.trunc(price),
    decimalPart: price - Math.trunc(price)
  }
}

export const getPriceByAttribute = (
  bid,
  attribute,
  totalPrice,
  baseTotalPrice,
  isBaseTotalZero,
  decimalAccumulator = 0
) => {
  let newPrice, newLeasingPrice, adjustmentValues

  switch (attribute) {
    case bidPricesTypes.leasing:
      newPrice = baseTotalPrice > 0 ? totalPrice / baseTotalPrice * calculateLeasingTotal(bid, FROM_BASE_PRICE) : 0
      newLeasingPrice = calculateLeasingPrice(bid, newPrice)
      adjustmentValues = adjustValue(newLeasingPrice, decimalAccumulator)
      return {
        [attribute]: adjustmentValues.truncated,
        total_leasing_price: adjustValue(newPrice, decimalAccumulator).truncated,
        decimalAccumulator: adjustmentValues.decimalPart
      }
    case bidPricesTypes.production:
      if (isBaseTotalZero) {
        newPrice = baseTotalPrice
      } else {
        newPrice = totalPrice / baseTotalPrice * bid[bidPricesTypes.base_production_price] * bid.quantity
      }
      adjustmentValues = adjustValue(newPrice, decimalAccumulator)

      return { [attribute]: adjustmentValues.truncated, decimalAccumulator: adjustmentValues.decimalPart }
    case bidPricesTypes.installation:
      if (isBaseTotalZero) {
        newPrice = baseTotalPrice
      } else {
        newPrice = totalPrice / baseTotalPrice * bid[bidPricesTypes.base_installation_price] * bid.quantity
      }
      adjustmentValues = adjustValue(newPrice, decimalAccumulator)

      return { [attribute]: adjustmentValues.truncated, decimalAccumulator: adjustmentValues.decimalPart }
  }
}
