import { getAuthorizationCode } from '../utils/authorization'
import { crudConstNames, crudConstPath } from '../utils/enums/crudConst'
import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import useBackendApi from './useBackendApi'
import { saveAs } from 'file-saver'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const headers = {
  Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Authorization: `Bearer ${getAuthorizationCode()}`,
  ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export function useDownloadQuotation(quotationId, filename, format) {
  const { data, loading, sendRequest } = useBackendApi()
  const { t } = useTranslation(['translation'])

  const getQuotationXLSX = () => {
    sendRequest(crudConstPath.quotations.xlsx(quotationId), crudConstNames.get, headers, null, null, 'arraybuffer')
  }

  useEffect(() => {
    if (!loading && data) {
      notify(t('file_downloaded', { ns: 'translation' }), severities.success)
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      saveAs(blob, `${filename}.${format}`)
    }
  }, [loading])

  return { loading, getQuotationXLSX }
}
