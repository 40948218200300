import { FormControl, InputLabel, MenuItem, Select, ListSubheader, Button, Autocomplete, TextField } from '@mui/material'
import { Field } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormPaper } from '../../Utils/Form/FormPaper'

// CONSTANTS
const ITEM_HEIGHT = 48
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10,
      minWidth: '200px'
    }
  }
}

export const ProviderInput = ({ providers, values, setFieldValue }) => {
  // CONSTANTS
  const formValues = providers.filter(format => values.includes(format.uuid))
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])

  // METHODS
  // RETURN
  return (
    providers
      ? <FormControl className="filter-input" variant='outlined' size='small' fullWidth>
          <Autocomplete
            value={formValues}
            id='provider-select'
            disablePortal
            name='provider_ids'
            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
            disableCloseOnSelect={true}
            className='autocomplete-input filter-input'
            onChange={(_event, newValue) => {
              setFieldValue('provider_ids',
                newValue.length > 0 ?
                  newValue.map(provider => provider.uuid) :
                  []
              )
            }}
            ChipProps={{
              clickable: false,
              classes: 'input-chip',
              variant: 'outlined',
              onDelete: null
            }}
            options={providers}
            multiple
            size='small'
            getOptionLabel={(option) => option.name}
            PaperComponent={FormPaper}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('provider', { ns: 'adSpace' })}
                className='filters-menu-item'
              />
            )}
          />
        </FormControl>
      : null
  )
}

ProviderInput.propTypes = {
  providers: propTypes.array.isRequired
}
