import { Card, CardContent, Typography, Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const StatisticCard = ({ data, title }) => {
  // HOOKS
  const { t } = useTranslation(['dashboard'])

  return (
    <Card className='statistic-card'>
      <CardContent>
        <Typography variant='h6' className='statistic-card-text' gutterBottom>
          {title}
        </Typography>
      </CardContent>

      <CardContent className='statistic-card-content'>
        <Typography className='statistic-card-text' gutterBottom>
          {t('daily', { ns: 'dashboard' })}
        </Typography>
        <Box className='statistic-box'>
          <Typography className='statistic-title' component='div'>
            {data?.daily.toLocaleString()}
          </Typography>
        </Box>
      </CardContent>
      <CardContent className='statistic-card-content'>
        <Typography className='statistic-card-text' gutterBottom>
          {t('monthly', { ns: 'dashboard' })}
        </Typography>
        <Box className='statistic-box'>
          <Typography className='statistic-title' component='div'>
            {data?.monthly.toLocaleString()}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

StatisticCard.propTypes = {
  data: {
    daily: PropTypes.number,
    monthly: PropTypes.number
  },
  title: PropTypes.string.isRequired
}

export default StatisticCard
