import { FormControl, ListSubheader, Button, Autocomplete, TextField, Paper } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormPaper } from '../../Utils/Form/FormPaper'
import { GroupedAutoComplete } from '../../Utils/Form/GroupedAutoComplete'

export const FormatInput = ({ formats, values, setFieldValue }) => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])
  const formValues = formats.filter(format => values.includes(format.uuid))
  // METHODS
  const handleOnClick = (format_name) => {
    const newValues = formats
      .filter(format => format.header === format_name)
      .map(format => format.uuid)

    const oldValues = values

    if (newValues.every((value) => oldValues.includes(value))) {
      setFieldValue(
        'format_ids',
        oldValues.filter((value) => !newValues.includes(value))
      )
    } else {
      setFieldValue('format_ids', [...oldValues, ...newValues.filter((value) => !oldValues.includes(value))])
    }
  }

  // RETURN
  return (
    <FormControl className="filter-input" variant='outlined' size='small' fullWidth>
      <GroupedAutoComplete
        label={t('format', { ns: 'adSpace' })}
        value={formValues}
        field_name='format_ids'
        options={formats}
        setFieldValue={setFieldValue}
        handleOnClick={handleOnClick}
      />
    </FormControl>
  )
}

FormatInput.propTypes = {
  formats: propTypes.array.isRequired,
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired
}
