import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import propTypes from 'prop-types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { useBidsCountBySlice } from '../../hooks/useBidsCountBySlice'
import bidStoreSlices from '../../utils/enums/bidStoreSlices'
import BidRow from './BidRow'
import { ProviderBidTotalTable } from './ProviderBidTotalTable'
import { QuotationBidTotalTable } from './QuotationBidTotalTable'

import '../../styles/adSpaces/Table.css'
import { useSelector } from 'react-redux'
import { VisitModeWrapper } from '../VisitModeWrapper'
const BidsTable = ({ action, storeSliceId, errors, bidUnavailables, providerUuid = null, setActiveBounce, bidsBounce }) => {
  const { t } = useTranslation(['translation', 'bid'])
  const bidsCount = useBidsCountBySlice(storeSliceId, providerUuid)
  const packagePricing = useSelector((state) => state.bidsCart.packagePricing)

  // Función para renderizar las filas de oferta

  // errors[bid.provider_uuid]
  //
  // {
  //   providerId: {
  //     missingDates: [ bids ],
  //     duplicatedBids: [ bids ]
  //   }
  // }
  const renderBidRows = () => {
    if (bidsCount === 0 || storeSliceId === null) {
      return (
        <TableRow>
          <TableCell colSpan={10}>
            <p>{t('noBids', { ns: 'quotation' })}</p>
          </TableCell>
        </TableRow>
      )
    }
    return Array.from({ length: bidsCount }, (_, index) =>
      <BidRow
        key={index}
        index={index}
        bidsBounce={bidsBounce}
        setActiveBounce={setActiveBounce}
        storeSliceId={storeSliceId}
        errors={errors}
        packagePricing={packagePricing}
        bidUnavailables={bidUnavailables}
        providerUuid={providerUuid}
      />
    )
  }

  return (
    <Paper elevation={3}>
      <TableContainer className='table-container'>
        <Table stickyHeader className='bids-table'>
          <TableHead>
            <TableRow>
              <TableCell className='strech-table-cell'></TableCell>
              <TableCell className='strech-table-cell'>{t('name', { ns: 'bid' })}</TableCell>
              <TableCell className='strech-table-cell'>{t('monthly_price', { ns: 'bid' })}</TableCell>
              <VisitModeWrapper>
                <TableCell className='strech-table-cell'></TableCell>
              </VisitModeWrapper>
              <TableCell className='strech-table-cell'>{t('quantity', { ns: 'bid' })}</TableCell>
              <TableCell className='strech-table-cell'>{t('duration', { ns: 'bid' })}</TableCell>
              <TableCell className='strech-table-cell'>{t('period', { ns: 'bid' })}</TableCell>
              <TableCell className='strech-table-cell'>{t('leasing_price', { ns: 'bid' })}</TableCell>
              <TableCell className='strech-table-cell'>{t('production_price', { ns: 'bid' })}</TableCell>
              <TableCell className='strech-table-cell'>{t('installation_price', { ns: 'bid' })}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderBidRows()}</TableBody>
        </Table>
      </TableContainer>
      {storeSliceId === bidStoreSlices.bidByProvider ? (
        <ProviderBidTotalTable providerUuid={providerUuid} />
      ) : (
        <QuotationBidTotalTable action={action} />
      )}
    </Paper>
  )
}

BidsTable.propTypes = {
  action: propTypes.number,
  height: propTypes.number,
  storeSliceId: propTypes.number.isRequired,
  errors: propTypes.object,
  setActiveBounce: propTypes.func,
  bidsBounce: propTypes.object,
  bidUnavailables: propTypes.array.isRequired,
  providerUuid: propTypes.string
}

export default memo(BidsTable)
