import { calculateLeasingTotal } from "./calculateLeasingTotal"
import { calculateSumByAttribute } from "./calculateSumByAttribute"

const FROM_BASE_PRICE = true

export const calculateBaseTotalPrices = (bids) => {
  const baseTotalLeasingPrice = parseInt(bids.reduce((sum, bid) => sum + calculateLeasingTotal(bid, FROM_BASE_PRICE), 0)) || 0
  const baseTotalProductionPrice = parseInt(calculateSumByAttribute(bids, 'ad_space_production_price')) || 0
  const baseTotalInstallationPrice = parseInt(calculateSumByAttribute(bids, 'ad_space_installation_price')) || 0
  return { baseTotalLeasingPrice, baseTotalProductionPrice, baseTotalInstallationPrice }
}
