import { Button } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { LoadingBox } from '../../components/shared/LoadingBox'
import { ChangePasswordForm } from '../../components/users/ChangePasswordForm'
import { UserForm } from '../../components/users/UserForm'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export const UserEdit = () => {
  //
  /* CONSTANTS */
  const { id } = useParams()
  const { t } = useTranslation(['user'])

  const userUpdateUrl = crudConstPath.users.update(id)
  const userEditUrl = crudConstPath.users.edit(id)
  //
  /* HOOKS */
  const navigate = useNavigate()
  const { data, loading, error, sendRequest } = useBackendApi()
  const companies = data?.companies
  const roles = data?.roles
  const user = data?.user

  useEffect(() => {
    sendRequest(userEditUrl, crudConstNames.get)
  }, [id])

  //
  /* METHODS */
  const handleUpdate = async values => {
    try {
      await sendRequest(userUpdateUrl, 'patch', {}, values)
      navigate('/users/')
    } catch (error) {
      notify(error.messages, severities.error)
    }
  }

  const handleGoBack = () => {
    navigate('/users')
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return user && companies ?
    <>
      <UserForm submitAction={handleUpdate} user={user} companies={companies} roles={roles} />
      <ChangePasswordForm user={user} />

      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem', paddingTop: '10px' }}>
        <Button color='primary' type='button' variant='contained' onClick={handleGoBack}>
            { t('back', { ns: 'user' })}
        </Button>
      </div>
    </> : null
}
