import PropTypes from 'prop-types'

import { useAppSelector } from '../../hooks/store'
import { BidsTotalsTable } from './BidsTotalsTable'

export function ProviderBidTotalTable({ providerUuid }) {
  const { providers } = useAppSelector(state => state.bidsByProvider)

  if (!providers[providerUuid]) {
    return null
  }

  const { totalLeasingPrice, totalProductionPrice, totalInstallationPrice, totalSumPrice } = providers[providerUuid]
  return (
    <BidsTotalsTable totalLeasingPrice={totalLeasingPrice} totalProductionPrice={totalProductionPrice} totalInstallationPrice={totalInstallationPrice} totalSumPrice={totalSumPrice} />
  )
}
ProviderBidTotalTable.propTypes = {
  providerUuid: PropTypes.string.isRequired
}
