import MapIcon from '@mui/icons-material/Map'
import { Button, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useJsApiLoader } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'
import { libraries } from '../../utils/mapVariables'
import BidsTable from '../bids/BidsTable'
import { QuotationFormMap } from './QuotationFormMap'
import useBidsBounce from '../../hooks/actions/useBidsBounce'

import '../../styles/quotations/QuotationFormBidsSection.css'

export function QuotationFormBidsSection({ action, storeSliceId }) {
  const { t } = useTranslation(['translation', 'quotation'])
  const [isMapOpen, setIsMapOpen] = useState(false)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  const showMapText = isMapOpen ? t('hide_map', { ns: 'quotation' }) : t('show_map', { ns: 'quotation' })
  const toggleMap = () => setIsMapOpen(!isMapOpen)
  const packagePricing = useSelector((state) => state.bidsCart.packagePricing)
  const bids = useSelector((state) => state.bidsCart.bids)

  const { bidsBounce, handleSetActiveBounce } = useBidsBounce(bids)

  const { setPackagePricingInCart, resetOriginalPriceInCart } = useBidsCartActions()
  const handlePackagePricingChange = ({ target: { value } }) => {
    const PackagePricing = value === 'true'
    setPackagePricingInCart(PackagePricing)
    if (!PackagePricing) {
      resetOriginalPriceInCart(bids)
    }
  }

  return (
    <>
      <Paper elevation={1} className='bids-section-container'>
        <Box className='bids-section-row'>
          <Typography variant='h5' className='bids-section-title'>
            {t('bids_section', { ns: 'quotation' })}
          </Typography>
          <Button variant='outlined' startIcon={<MapIcon />} onClick={toggleMap}>
            {showMapText}
          </Button>
        </Box>
        <Box className='bids-section-radio-group'>
          <FormLabel id="demo-controlled-radio-buttons-group">{t('package_pricing', { ns: 'quotation' })}</FormLabel>
          <RadioGroup
            onChange={handlePackagePricingChange}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={packagePricing}
          >
            <FormControlLabel value="false" control={<Radio />} label={t('individual', { ns: 'quotation' })} />
            <FormControlLabel value="true" control={<Radio />} label={t('package', { ns: 'quotation' })} />
          </RadioGroup>
        </Box>
      </Paper>
      <div className='quotation-bids-container'>
        <Paper className='table-container'>
          <BidsTable
            action={action}
            storeSliceId={storeSliceId}
            setActiveBounce={handleSetActiveBounce}
            bidsBounce={bidsBounce}
          />
        </Paper>
        <Paper className={`bid-map-container ${isMapOpen ? 'bid-map-container-show' : 'bid-map-container-hide'}`}>
          {isLoaded && isMapOpen ? (
            <QuotationFormMap bidsBounce={bidsBounce} />
          ) : null}
        </Paper>
      </div>
    </>
  )
}

QuotationFormBidsSection.propTypes = {
  action: PropTypes.string.isRequired,
  storeSliceId: PropTypes.string.isRequired
}
