import { Dialog } from '@mui/material'
import { createPortal } from 'react-dom'

export function Modal({ children, isOpened, closeModal, ...props }) {
  // HOOKS
  // METHODS

  // RETURN
  return (
    isOpened &&
    createPortal(
      <Dialog open={isOpened} onClose={closeModal} {...props}>
        {children}
      </Dialog>,
      document.getElementById('modal-root')
    )
  )
}
