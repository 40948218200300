import '../../../styles/utils/data-table/data-table.scss'
import CustomPagination from './CustomPagination'
import { CircularProgress } from '@mui/material'
import propTypes from 'prop-types'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'

// https://www.npmjs.com/package/react-data-table-component

const ComponentDataTable = ({
  totalPages,
  setPage,
  moreData,
  RowsPage,
  setPageSize,
  handleSortCase,
  resourceRequest,
  rangePicker,
  queryPlaceholder = 'Buscar',
  queryFilter = true,
  setSortInfo,
  ...props
}) => {
  const [rowsPage, setRowsPage] = useState(RowsPage)
  const { t } = useTranslation(['commons'])

  const onChangePage = (pageValue) => {
    setPage(pageValue)
  }

  const onChangeRowsPage = (RowsPageValue) => {
    setPageSize(RowsPageValue)
  }

  const { onRequest, data } = props

  return (
    <div className='custom-data-table' style={{ position: 'relative' }}>
      {onRequest && (
        <div className='container-spinner'>
          <CircularProgress color='secondary' />
        </div>
      )}
      <DataTable
        pagination
        noDataComponent={onRequest ? '' : t('not_found', { ns: 'commons' })}
        paginationServer
        paginationTotalRows={totalPages}
        paginationPerPage={rowsPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página',
          rangeSeparatorText: 'de'
        }}
        paginationComponent={CustomPagination}
        disabled={onRequest}
        data={data}
        paginationRowsPerPageOptions={[30, 60, 90, 120]}
        onSort={(colValue, dirValue) => {
          const sortInfo = { [colValue.sortField]: dirValue }
          if (colValue.sortField !== undefined) {
            setSortInfo(sortInfo)
          }
        }}
        {...props}
      />
    </div>
  )
}

export default ComponentDataTable

ComponentDataTable.propTypes = {
  totalPages: propTypes.number.isRequired,
  setPage: propTypes.func.isRequired,
  moreData: propTypes.bool.isRequired,
  RowsPage: propTypes.number.isRequired,
  handleSortCase: propTypes.func.isRequired,
  resourceRequest: propTypes.func.isRequired,
  rangePicker: propTypes.bool.isRequired,
  queryPlaceholder: propTypes.string,
  queryFilter: propTypes.bool,
  setSortInfo: propTypes.func.isRequired,
  data: propTypes.array.isRequired,
  onRequest: propTypes.bool.isRequired
}
