import { LoginForm } from '../../components/auth/LoginForm'
import { Logo } from '../../components/Logo'
import { Grid, CssBaseline, Box, Paper } from '@mui/material'

export const Login = () => {
  document.title = 'OOH Planning'
  return (
    <Grid
      container
      component='main'
      sx={{
        height: '80vh',
        alignContent: 'center',
        justifyContent: 'center'
      }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Logo component='div' style={{ marginBottom: '20px' }} />
          <LoginForm />
        </Box>
      </Grid>
    </Grid>
  )
}
