import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AdvertiserForm } from '../../components/advertisers/AdvertiserForm'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export function AdvertiserNew() {
  //
  /* CONSTANTS */
  const advertiserCreateUrl = crudConstPath.advertiser.create

  //
  /* HOOKS */
  const { t } = useTranslation(['advertiser'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()

  useEffect(() => {
    sendRequest(crudConstPath.advertiser.index, crudConstNames.get)
  }, [])

  const industries = data?.industries || []

  //
  /* METHODS */
  const handleCreate = (values) => {
    const advertiserData = { advertiser: values }
    sendRequest(advertiserCreateUrl, crudConstNames.post, {}, advertiserData).then((response) => {
      if (response.success) {
        notify(t('created', { ns: 'advertiser' }), severities.success)
        navigate('/advertisers')
      }
    })
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return <AdvertiserForm onSubmit={handleCreate} industries={industries} />
}
