import { setCampaignTab  } from '../../store/campaignTabSlice'
import { useAppDispatch } from '../store'

export const useCampaignTabActions = () => {
  const dispatch = useAppDispatch()
  const setCurrentCampaignTab = (tab) => {
    dispatch(setCampaignTab(tab))
  }

  return { setCurrentCampaignTab }
}
