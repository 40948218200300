import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { Box } from '@mui/system'
import { Formik, Form } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup';

import { Modal } from '../modal/Modal'
import { AgencyFormFields } from './AgencyFormFields'

export function ModalAgencyForm({
  isOpened,
  closeModalHandler,
  onSubmit
}) {
  const { t } = useTranslation(['translation'])

  const initialValues = {
    name: '',
    corporate_name: '',
    national_id: '',
    trade_name: '',
    address: '',
    email: '',
  }

  const handleSubmit = (values) => {
    onSubmit(values)
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(t('name_required', { ns: 'agency' })),
    corporate_name: Yup.string().nullable(),
    national_id: Yup.string()
      .matches(/\b[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[0-9kK]\b/, t('national_id_match', { ns: 'agency' })),
    address: Yup.string().nullable(),
    trade_name: Yup.string().nullable(),
    email: Yup.string()
      .email(t('email_invalid', { ns: 'agency' }))
      .nullable(),
    industry_id: Yup.string()
  })

  return(
    <Modal isOpened={isOpened} closeModal={closeModalHandler} fullWidth={true}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <Card>
              <CardHeader
                sx={{ bgcolor: 'secondary.main', color: 'white' }}
                bgcolor='secondary.main'
                title={t('new', { ns: 'agency' })}
                className='header'
              />
              <CardContent className='content'>
                <Box sx={{width: '100%'}}>
                  <AgencyFormFields
                    errors={errors}
                    touched={touched}
                    translated={t}
                  />
                </Box>
              </CardContent>
              <CardActions>
                <Box className='actions' sx={{ flex: 1, display: 'flex', flexDirection: 'row', pl: 2 }}>
                  <Button variant='outlined' onClick={closeModalHandler}>
                    {t('close')}
                  </Button>
                  <Button variant='contained' color={'secondary'} type='submit'>
                    {t('create', { ns: 'agency' })}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

ModalAgencyForm.propTypes = {
  isOpened: propTypes.bool,
  closeModalHandler: propTypes.func,
  onSubmit: propTypes.func
}