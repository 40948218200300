import { durationUnitsEnum } from '../utils/durationUnits'
import moment from 'moment'

const updateEndDate = (newDate, newDuration, newDurationUnit, onEndDateChange) => {
  const durationUnitMultiplier = newDurationUnit === durationUnitsEnum.days ? 'days' : 'months'
  const updatedEndDate = moment(newDate).add(newDuration, durationUnitMultiplier).subtract(1, 'days')
  onEndDateChange(updatedEndDate)
}

const useDurationWithDatesCalculator = (onDurationChange, onDurationUnitChange, onStartDateChange, onEndDateChange) => {
  /**
   * it updates the end date when the duration changes
   */
  const atDurationChange = ({ duration, startDate, durationUnit }) => {
    const durationValue = parseInt(duration)
    onDurationChange(durationValue || 1)
    startDate && updateEndDate(startDate, durationValue, durationUnit, onEndDateChange)
  }

  /**
   * it updates the end date when the duration unit changes
   */
  const atDurationUnitChange = ({ durationUnit, startDate, duration }) => {
    onDurationUnitChange(durationUnit)
    updateEndDate(startDate, duration, durationUnit, onEndDateChange)
  }

  /**
   * it updates the end date when the start date changes
   * it also updates the duration and duration unit
   */
  const atStartDateChange = ({ startDate, duration, durationUnit }) => {
    onStartDateChange(startDate)
    updateEndDate(startDate, duration, durationUnit, onEndDateChange)
  }

  /**
   * it updates the duration, duration unit and end date when the end date changes
   */
  const atEndDateChange = ({ endDate, startDate }) => {
    onEndDateChange(endDate)
    if (!startDate) onStartDateChange(moment(startDate))

    const startDateMoment = moment(startDate)
    const endDateMoment = moment(endDate)
    const diffInMonths = endDateMoment.diff(startDateMoment.subtract(1, 'days'), 'months', true)

    const duration = Number.isInteger(diffInMonths) ? diffInMonths : endDateMoment.diff(startDateMoment, 'days')
    const durationUnit = Number.isInteger(diffInMonths) ? durationUnitsEnum.months : durationUnitsEnum.days

    onDurationChange(duration)
    onDurationUnitChange(durationUnit)
  }

  return {
    atDurationChange,
    atDurationUnitChange,
    atStartDateChange,
    atEndDateChange
  }
}

export default useDurationWithDatesCalculator
