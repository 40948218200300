import { useAuth } from '../../hooks/useAuth'
import '../../styles/login/LoginForm.css'
import { Alert } from '../Alert'
import { TextField, Button, Checkbox, FormControlLabel } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export function LoginForm() {
  const [showError, setShowError] = useState(false)
  const { login, error, isLoading } = useAuth()
  const { t } = useTranslation(['translation', 'user'])

  const handleSubmit = async values => {
    await login(values.email, values.password)
  }

  useEffect(() => {
    if (error) {
      setShowError(true)
    }
  }, [isLoading])

  return (
    <>
      <Alert message={error} severity='error' showError={showError} setShowError={setShowError} />

      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          email: Yup.string().email().required(t('email_invalid', { ns: 'user' })),
          password: Yup.string().required(t('password_required', { ns: 'user' }))
        })}
      >
        {({ errors, touched }) => (
          <Form>
            <Field
              as={TextField}
              error={errors.email && touched.email}
              helperText={errors.email && touched.email ? errors.email : ''}
              name='email'
              variant='outlined'
              margin='normal'
              autoFocus
              fullWidth
              type='text'
              placeholder={t('email', { ns: 'user' })}
              label={t('email', { ns: 'user' })}
            />
            <Field
              as={TextField}
              error={errors.password && touched.password}
              helperText={errors.password && touched.password ? errors.password : ''}
              name='password'
              variant='outlined'
              fullWidth
              className='form-control'
              type='password'
              placeholder={t('password', { ns: 'user' })}
              label={t('password', { ns: 'user' })}
            />
            <FormControlLabel
              control={<Field as={Checkbox} className='custom-control-input' type='checkbox' name='rememberMe' />}
              label={t('remember_me', { ns: 'user' })}
            />

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
              {' '}
              {t('login', { ns: 'user' })}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
