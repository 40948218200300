import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CompanyForm } from '../../components/companies/CompanyForm'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export function CompanyNew() {
  //
  /* CONSTANTS */
  const companiesCreateUrl = crudConstPath.companies.create

  //
  /* HOOKS */
  const { t } = useTranslation(['company'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()

  useEffect(() => {
    if (data) {
      notify(t('created', { ns: 'company' }), severities.success)
      navigate('/companies')
    }
  }, [data])

  //
  /* METHODS */
  const handleCreate = async values => {
    const isFormData = true
    await sendRequest(companiesCreateUrl, crudConstNames.post, {}, { company: values }, null, 'json', isFormData)
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return <CompanyForm submitAction={handleCreate} />
}
