import { setVisitMode  } from '../../store/visitModeSlice'
import { useAppDispatch } from '../store'

export const useVisitModeActions = () => {
  const dispatch = useAppDispatch()
  const setActiveVisitMode = (active) => {
    dispatch(setVisitMode(active))
  }

  return { setActiveVisitMode }
}
