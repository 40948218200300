import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Card, CardHeader, CardMedia, Chip, Divider, List, ListItem, Paper, Popover, Tooltip, Typography, Zoom } from "@mui/material"
import { Box } from "@mui/system"
import { useJsApiLoader } from '@react-google-maps/api'
import moment from "moment"
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom"

import { AdSpaceOccupanciesPopover } from "../../components/adSpaces/AdSpaceOccupanciesPopover"
import { Map } from '../../components/map/Map'
import { ObjectList } from '../../components/shared/ObjectList'
import { useAdSpaceDetails } from '../../hooks/adSpaces/useAdSpaceDetails'
import { useAdSpaceKeyContent } from '../../hooks/adSpaces/useAdSpaceKeyContent'
import { attributesList, detailsVariants } from '../../utils/detailsVariants'
import { mappedFormats } from '../../utils/formats.js'
import { libraries } from '../../utils/mapVariables'
import { vacancyStatuses } from '../../utils/vacancy_status'

import '../../styles/adSpaces/Show.css'
import { generateAdSpacePublicUrl } from '../../utils/adSpaces/generatePublicUrl'
import { PermissionWrapper } from '../../components/permissions/PermissionWrapper'
import { ClipboardButton } from '../../components/shared/ClipboardButton'
import { VisitModeWrapper } from '../../components/VisitModeWrapper'

const default_photo_path = `${process.env.PUBLIC_URL}/assets/shared/default-photo.png`
const variant = 'default'

export function AdSpaceShow({ publicPage = false }) {
  const { t } = useTranslation(['translation', 'adSpace'])
  const { id } = useParams()
  const detailsVariant = publicPage ? detailsVariants.public : detailsVariants.default
  const { adSpace } = useAdSpaceDetails(id, detailsVariant)
  const { contentKey } = useAdSpaceKeyContent()
  const [anchorEl, setAnchorEl] = useState(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })
  const adSpaceGeneralInfo = {
    name: adSpace?.name,
    format: mappedFormats[adSpace?.format_id],
    update_at: moment(adSpace?.update_date).format(t('date_format_with_time'))
  }
  const markers = [
    {
      lat: adSpace?.lat,
      lng: adSpace?.lng,
      icon: adSpace?.icon,
      uuid: adSpace?.uuid,
      item_ids: [adSpace?.uuid],
      num_points: 1
    }
  ]
  const attributesShown = attributesList[detailsVariant] || attributesList.default
  const adSpaceData = adSpace ? attributesShown.reduce((map, key) => {
    map[key] = contentKey(key, adSpace)
    return map
  }, {}) : {}

  const fullUrl = generateAdSpacePublicUrl(adSpace?.uuid)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverClick = (event) => {
    event.stopPropagation()
  }

  return adSpace ? (
    <Box className='ad-space-container'>
      {/* TODO: change subjext to AdSpace when module is included */}
      <Box className='ad-space-public-link'>
        <PermissionWrapper subject='Quotation' actions={['manage']}>
          <ClipboardButton copyContent={fullUrl}>{t('copy_link')}</ClipboardButton>
        </PermissionWrapper>
      </Box>
      <Box className="ad-space-multiple-columns">
        <Box className='ad-space-show-left-container'>
          <CardMedia
            sx={{ aspectRatio: '3/2', objectFit: 'contain' }}
            component='img'
            image={adSpace.photo || default_photo_path}
            alt={t('imageAlt', { ns: 'adSpace' })}
          />

          {isLoaded ? (
            <Box className='ad-space-show-map'>
              <Map
                initialCenter={{ lat: adSpace.lat, lng: adSpace.lng }}
                markers={markers}
                showAllMarkers={true}
                variant={detailsVariant}
              />
            </Box>
          ) : null}
        </Box>
        <Box className='ad-space-show-right-container'>

          {/* TODO: Sección información general (abstraer) */}
          <Paper className='ad-space-general-info'>
            <Typography variant='body2'>
              {adSpace.name}
            </Typography>

            {t('sku', { ns: 'adSpace' })}: {adSpace.sku}
            <PermissionWrapper subject='Quotation' actions={['manage']}>
              <VisitModeWrapper>
                <InfoOutlinedIcon
                  key={`${adSpace.uuid}Icon`}
                  className='code-info-icon'
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup='true'
                  onClick={handleClick}
                />
                <Popover
                  id='mouse-over-popover'
                  onClick={handlePopoverClick}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}>
                  <Box className='ad-space-code'>
                    <Typography variant='body2' component='div' key={`${adSpace.uuid}Text`} px={2}>
                        <b>{t('code', { ns: 'adSpace' })}</b>
                    </Typography>
                    <Typography variant='body2' component='div' px={2}>
                      {adSpace.code}
                    </Typography>
                  </Box>
                </Popover>
              </VisitModeWrapper>
            </PermissionWrapper>
            <Divider />
            <Typography variant='body1' sx={{fontWeight: 'bold'}}>
              {t('format_id', { ns: 'adSpace' })}
            </Typography>
            <Typography variant='body2'>
              {mappedFormats[adSpace.format_id]}
            </Typography>
            <Typography variant='body1' sx={{fontWeight: 'bold'}}>
              {t('vacancy_status', { ns: 'adSpace' })}
            </Typography>
            <Chip
              label={vacancyStatuses[adSpace.vacancy_status].label}
              color={vacancyStatuses[adSpace.vacancy_status].color}
              variant='outlined'
              size='small'
              sx={{
                margin: '5px 0px 5px 0',
                pr: 2
              }}
            />
            {adSpace.vacancy_with_delay ? (
              <Tooltip
                TransitionComponent={Zoom}
                sx={{
                  position: 'relative',
                  top: '5px',
                  right: '20px'
                }}
                title={<h3>{t('vacancy_with_delay', { ns: 'adSpace' })}</h3>}>
                <InfoIcon color='warning' fontSize='small' />
              </Tooltip>
            ) : null}
            {adSpace.occupancies ? (
              <AdSpaceOccupanciesPopover key={`${adSpace.id}-occupancies`} occupancies={adSpace.occupancies} />
            ) : null}
          </Paper>
          <Paper className='ad-space-specifications'>
            <Box>
              <Typography variant='h6'>
                {t('specs', { ns: 'adSpace' })}
              </Typography>
            </Box>
            <ObjectList data={adSpaceData} nameSpace={'adSpace'}/>
          </Paper>

          <Paper className='ad-space-notes'>
            <Box>
              <Typography variant='h6'>
                {t('notes', { ns: 'adSpace' })}
              </Typography>
            </Box>
            <Typography className='ad-space-content'>
              {adSpace.notes ? adSpace.notes : t('no_info', { ns: 'adSpace' })}
            </Typography>
          </Paper>
          {/* TODO: Make sure to replace this for adSpace when module is added */}
          <PermissionWrapper subject='Quotation' actions={['manage']}>
            <Paper className='ad-space-comments'>
              <Box>
                <Typography variant='h6'>
                  {t('comments', { ns: 'adSpace' })}
                </Typography>
                <Typography className='ad-space-content'>
                  {adSpace.comments ? adSpace.comments : t('no_info', { ns: 'adSpace' })}
                </Typography>
              </Box>
            </Paper>
          </PermissionWrapper>
        </Box>
      </Box>
    </Box>
  ) : null
}