import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import { Popover, Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ObjectList } from './ObjectList'

export function ContactPopover({ object }) {
  const { t } = useTranslation(['translation'])

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const name = object?.name
  const listBody = object ? Object.keys(object).reduce((map, key) => {
    if (key !== 'name') {
      map[key] = object[key]
    }
    return map
  }, {}) : {}
  const open = Boolean(anchorEl)
  const popoverId = open ? 'simple-popover' : undefined

  return name && Object.keys(listBody).length !== 0 ? (
    <Box className='list-item-content'>
      <Typography variant='body1'>{name}</Typography>
      <ContactsOutlinedIcon className='contact' onClick={handleClick} />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: { style: { width: '300px' } }
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Box>
          <Typography variant='h6' className='list-title'>{t('contact_info')}</Typography>
          <ObjectList data={listBody} nameSpace='translation' />
        </Box>
      </Popover>
    </Box>
  ) : <Typography variant='body1'>{t('unavailable_contact_info')}</Typography>
}

ContactPopover.propTypes = {
  object: PropTypes.shape({
    name: PropTypes.string
  }).isRequired
}
