import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Box, Button, CardMedia, IconButton } from '@mui/material'
import propTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useAdSpacesFetcher } from '../../hooks/adSpaces/useAdSpacesFetcher'
import { Modal } from '../modal/Modal'
import { AdSpaceDetailsContent } from './AdSpaceDetailsContent'

// Import Swiper styles
import 'swiper/css'

const default_photo_path = `${process.env.PUBLIC_URL}/assets/shared/default-photo.png`
export function MultiAdSpaceDetails({ isOpened, closeModal, ids, position, variant }) {
  const { t } = useTranslation(['translation', 'adSpace'])
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [activeStep, setActiveStep] = useState(0)
  const slidesContainerRef = useRef(null)

  const handleChange = (step) => {
    controlledSwiper?.slideTo(step)
    setActiveStep(step)
  }

  const handleNext = () => {
    controlledSwiper.slideNext()
    setActiveStep(controlledSwiper?.activeIndex)
  }

  const handleBack = () => {
    controlledSwiper.slidePrev()
    setActiveStep(controlledSwiper?.activeIndex)
  }

  const isValidEnvironment = (slidesContainer, controlledSwiper) => {
    return slidesContainer && controlledSwiper
  }

  // Verificar si elemento seleccionado esta fuera de los slides
  const isSlideOutOfView = (slidesContainer, activeSlide) => {
    const containerRect = slidesContainer.getBoundingClientRect();
    const slideRect = activeSlide.getBoundingClientRect();

    return slideRect.right > containerRect.right || slideRect.left < containerRect.left;
  }

  // Centrar elemento seleccionado de los slides
  const centerSlideInView = (slidesContainer, activeSlide) => {
    const containerRect = slidesContainer.getBoundingClientRect();
    const slideRect = activeSlide.getBoundingClientRect();

    slidesContainer.scrollTo({
      left: activeSlide.offsetLeft - (containerRect.width - slideRect.width) / 2,
      behavior: 'smooth'
    })
  }

  // useEffect: Cuando cambia el elemento seleccionado.
  useEffect(() => {
    const slidesContainer = slidesContainerRef.current

    // Validar entorno
    if (!isValidEnvironment(slidesContainer, controlledSwiper)) return

    const activeSlideIndex = controlledSwiper.activeIndex
    const activeSlide = slidesContainer.children[activeSlideIndex]

    // Si no hay slide seleccionado, retornar
    if (!activeSlide) return

    // Si elemento esta fuera de los slides, centrarlo
    if (isSlideOutOfView(slidesContainer, activeSlide)) {
      centerSlideInView(slidesContainer, activeSlide)
    }
  }, [activeStep, controlledSwiper])

  const { adSpaces } = useAdSpacesFetcher(position)

  return adSpaces.length === 0 ? null : (
    <Modal isOpened={isOpened} closeModal={closeModal} fullWidth={true} maxWidth={'lg'}>
      <Box>
        <Swiper
          slidesPerView={1}
          controller={{ control: controlledSwiper }}
          onSwiper={setControlledSwiper}
          spaceBetween={30}
          modules={[Controller]}
          className="ad-space-swiper"
          allowTouchMove={false}
        >
          {adSpaces?.map((adSpace) => {
            return (
              <SwiperSlide key={adSpace.uuid}>
                <AdSpaceDetailsContent adSpace={adSpace} variant={variant} closeModal={closeModal} withHeader={true} key={adSpace.uuid}/>
              </SwiperSlide>
            )
          })}
          <Box className='slides-actions'>
            <Button
              onClick={handleBack}
              disabled={activeStep === 0}
              color='secondary'
            >
              <KeyboardArrowLeft />
              {t('previous', { ns: 'translation' })}
            </Button>
            <Box className='slides-container' ref={slidesContainerRef}>

              {adSpaces?.map((adSpace, index) => {
                return (
                  <IconButton onClick={() => handleChange(index)} key={adSpace.uuid}>
                    <CardMedia
                      component='img'
                      image={adSpace.mini_photo || default_photo_path}
                      alt={t('imageAlt', { ns: 'adSpace' })}
                      className={index === activeStep ? 'active-slide' : null}
                    />
                  </IconButton>
                )
              })}
            </Box>

            <Button
              onClick={handleNext}
              disabled={activeStep === ids.length - 1}
              color='secondary'
            >
              {t('next', { ns: 'translation' })}
              <KeyboardArrowRight />
            </Button>
          </Box>
        </Swiper>
      </Box>
    </Modal>
  )
}

MultiAdSpaceDetails.propTypes = {
  isOpened: propTypes.bool,
  closeModal: propTypes.func,
  position: propTypes.shape({
    lat: propTypes.number,
    lng: propTypes.number
  }),
  ids: propTypes.array,
  variant: propTypes.string
}
