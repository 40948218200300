export const markersInViewport = (markers, currentBounds) =>
  markers.filter((marker) => {
    const markerPosition = { lat: marker.lat, lng: marker.lng }
    return currentBounds.contains(markerPosition)
  })

export const markersIdsInBounds = (clusters, currentBounds) => {
  const clusterInBounds = markersInViewport(clusters, currentBounds)
  const markerIdsArray = clusterInBounds.map((cluster) => cluster.item_ids)
  return [].concat.apply([], markerIdsArray)
}