import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { bidPricesTypes } from '../../utils/enums/bidPrices'

const PRICE_ROWS = [
  bidPricesTypes.leasing,
  bidPricesTypes.production,
  bidPricesTypes.installation,
  bidPricesTypes.total
]

const renderEmpty = (headers, t) => {
  return (
    <TableRow>
      <TableCell colSpan={Object.values(headers).length}>
        <p> {t('no_rows_table', { ns: 'translation' })} </p>
      </TableCell>
    </TableRow>
  )
}

export function BidsTableWithPackageprice({ headers, data }) {
  const { t } = useTranslation(['translation'])
  const rowKeys = Object.keys(headers)

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {Object.values(headers).map(header => (
              <TableCell align='center' key={header}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0
            ?
              renderEmpty(headers, t)
            : (
                data.map((dataElement, index) => {
                  return (
                    <TableRow key={index}>
                      {
                        rowKeys.map((key) => (
                            PRICE_ROWS.includes(key) && index === 0 ?
                              <TableCell align='center' key={index + key} rowSpan={data.length}>
                                  {dataElement[key]}
                              </TableCell> : !PRICE_ROWS.includes(key) && <TableCell align='center' key={index + key}>
                                  {dataElement[key]}
                              </TableCell>
                          )
                        )
                      }
                    </TableRow>
                  )
                })
              )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

BidsTableWithPackageprice.propTypes = {
  headers: propTypes.object.isRequired,
  data: propTypes.array.isRequired
}
