import axios from 'axios'

import { getAuthorizationCode } from './authorization'
import { crudConstPath } from './enums/crudConst'
// url from locals env
const ADSPACE_URL = crudConstPath.adSpaces.index
const getHeaders = () => {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${getAuthorizationCode()}`
  }
}

export const getAdSpaceFromBackend = async adspaceId => {
  const headers = getHeaders()
  return await axios.get(`${ADSPACE_URL}/${adspaceId}`, { headers })
}

export const getPublicAdSpaceFromBackend = async adspaceId => {
  const headers = getHeaders()
  return await axios.get(`${ADSPACE_URL}/${adspaceId}/public`, { headers })
}
