import { removeBidFromProvider, updateBidInProvider, setBidsInProvider, updateCommentInProvider } from '../../store/bidsByProviderSlice'
import { useAppDispatch } from '../store'

export const useBidsByProviderActions = () => {
  const dispatch = useAppDispatch()

  const removeBidFromCart = (bid, index) => {
    const providerUuid = bid.provider_uuid
    dispatch(removeBidFromProvider({ providerUuid, index }))
  }

  const updateBidInCart = (bid, index) => {
    const providerUuid = bid.provider_uuid
    dispatch(updateBidInProvider({ providerUuid, updatedBid: bid, index }))
  }

  const setBidsInCart = (providers) => {
    dispatch(setBidsInProvider(providers))
  }

  const clearBidsInCart = () => {
    dispatch(setBidsInProvider([]))
  }
  const updateCommentInCart = (providerUuid, commentText) => {
    dispatch(updateCommentInProvider({ providerUuid, commentText }))
  }

  return { removeBidFromCart, updateBidInCart, setBidsInCart, clearBidsInCart, updateCommentInCart }
}
