import { calculateLeasingTotal } from '../utils/bid/calculateLeasingTotal'
import { getTotalPrices } from '../utils/bid/getTotalPrices'
import { createSlice } from '@reduxjs/toolkit'
const removeBid = (bid) => ({
  ...bid,
  _destroy: true,
  quantity: 0,
  total_leasing_price: 0,
  production_price: 0,
  installation_price: 0
})

const calculateGlobalTotals = (providers) => {
  return Object.values(providers).reduce(
    (accumulator, provider) => {
      accumulator.totalLeasingPrice += provider.totalLeasingPrice
      accumulator.totalProductionPrice += provider.totalProductionPrice
      accumulator.totalInstallationPrice += provider.totalInstallationPrice
      accumulator.totalSumPrice += provider.totalSumPrice
      return accumulator
    },
    {
      totalLeasingPrice: 0,
      totalProductionPrice: 0,
      totalInstallationPrice: 0,
      totalSumPrice: 0
    }
  )
}

const initialState = {
  providers: {
    // <uuid>: {
    //  uuid: '',
    //  name: '',
    //  totalLeasingPrice: 0,
    //  totalProductionPrice: 0,
    //  totalInstallationPrice: 0,
    //  totalSumPrice: 0,
    // }
  },
  bids: {
  },
  comments: {},
  bidsCount: 0,
  totalLeasingPrice: 0,
  totalProductionPrice: 0,
  totalInstallationPrice: 0,
  totalSumPrice: 0
}

// Función auxiliar para actualizar el proveedor y recalcular totales
const updateProviderAndTotals = (state, providerUuid, updateCallback) => {
  const provider = state.providers[providerUuid]
  if (provider) {
    updateCallback(provider)
    // Recalcula los totales del proveedor
    const providerTotals = getTotalPrices(provider.bids)
    state.providers[providerUuid] = { ...provider, ...providerTotals }

    // Recalcula los totales globales
    const globalTotals = calculateGlobalTotals(state.providers)
    Object.assign(state, globalTotals)
  }
}

const updateBidAndTotals = (state, providerUuid, updateCallback) => {
  const bidsByProvider = state.bids[providerUuid] // bidsByProvider es un array de bids
  const provider = state.providers[providerUuid]
  if (bidsByProvider) {
    updateCallback(bidsByProvider)
    // Recalcula los totales del proveedor
    const providerTotals = getTotalPrices(bidsByProvider)
    state.providers[providerUuid] = { ...provider, ...providerTotals }

    // Recalcula los totales globales
    const globalTotals = calculateGlobalTotals(state.providers)
    Object.assign(state, globalTotals)
  }
}

export const bidsByProviderSlice = createSlice({
  name: 'bidsByProvider',
  initialState,
  reducers: {
    removeBidFromProvider: (state, action) => {
      const { providerUuid, index } = action.payload
      updateBidAndTotals(state, providerUuid, (bids) => {
        if (index >= 0 && index < bids.length) {
          const removedBid = removeBid(bids[index])
          bids[index] = removedBid
        }
      })
    },
    updateBidInProvider: (state, action) => {
      const { providerUuid, index, updatedBid } = action.payload
      updateBidAndTotals(state, providerUuid, (bids) => {
        if (index >= 0 && index < bids.length) {
          bids[index] = { ...bids[index], ...updatedBid }
          bids[index].total_leasing_price = calculateLeasingTotal(bids[index])
        }
      })
    },
    setBidsInProvider: (state, action) => {
      // providers : [{
      //   uuid: '',
      //     name: '',
      //       bids: [{}, {}, ...]
      // }, ...]
      const providers = action.payload
      if (providers.length === 0) {
        state.providers = {}
        state.bids = {}
        state.comments = {}
        state.totalLeasingPrice = 0
        state.totalProductionPrice = 0
        state.totalInstallationPrice = 0
        state.totalSumPrice = 0
        return
      }

      providers.forEach((provider) => {
        const providerTotals = getTotalPrices(provider.bids)
        state.providers[provider.uuid] = {
          name: provider.name,
          uuid: provider.uuid,
          bidsCount: provider.bids.length,
          ...providerTotals
        }
        state.bids[provider.uuid] = provider.bids
      })
      const globalTotals = calculateGlobalTotals(state.providers)
      Object.assign(state, globalTotals)
    },

    updateCommentInProvider: (state, action) => {
      const { providerUuid, commentText } = action.payload
      state.comments[providerUuid] = commentText
    }
  }
})

export const { removeBidFromProvider, updateBidInProvider, setBidsInProvider, updateCommentInProvider } = bidsByProviderSlice.actions
export default bidsByProviderSlice.reducer
