import React, { useCallback, useState } from 'react'
import propTypes from 'prop-types'
import { IconButton, Menu, MenuItem, Tooltip, Button } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

export function DropdownMenu({ data, title, options, component }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  const handleOptionClick = useCallback((option) => {
    handleClose();
    if (option.onClick) {
      option.onClick(data[option.attrId]);
    }
  }, [data, handleClose])

  const renderComponent = () => {
    switch (component) {
      case 'iconButton':
        return (
          <Tooltip title={title} arrow>
            <IconButton
              aria-controls="dropdown-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        )

      case 'button':
        default:
        return (
          <Button
            onClick={handleClick}
            variant='outlined'
            startIcon={<MoreVertIcon />}
            sx={{ ml: '8px' }}
            aria-controls="dropdown-menu"
            aria-haspopup="true">
            {title}
          </Button>
        )
    }
  }

  return (
    <>
      {renderComponent()}
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionClick(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

DropdownMenu.propTypes = {
  data: propTypes.object,
  title: propTypes.string,
  options: propTypes.array,
  component: propTypes.string
}