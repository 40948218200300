import { setadSpacesFilter, clearMapFilter, setBoundsFilter, setZoomFilter } from '../../store/mapFilterSlice'
import { useAppDispatch } from '../store'

export const useMapFilterActions = () => {
  const dispatch = useAppDispatch()

  const setAdSpacesFilterState = (mapFilter) => {
    dispatch(setadSpacesFilter(mapFilter))

  }
  const setBoundsFilterState = (bounds) => {
    dispatch(setBoundsFilter(bounds))
  }

  const clearMapFilterState = () => {
    dispatch(clearMapFilter())
  }

  const setZoomFilterState = (zoom) => {
    dispatch(setZoomFilter(zoom))
  }
  return { setAdSpacesFilterState, clearMapFilterState , setBoundsFilterState, setZoomFilterState }
}