import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'

export function SideMenuItem({ title, icon, to, onClick }) {
  return (
    <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={to} end>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton onClick={onClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </ListItem>
    </NavLink>
  )
}
