import { useCheckPermissions } from '../../utils/useCheckPermissions'
import propTypes from 'prop-types'

export function PermissionWrapper({ children, actions, subject, placeholder = null }) {
  const hasPermission = useCheckPermissions(actions, subject)
  return hasPermission ? children : placeholder
}

PermissionWrapper.propTypes = {
  children: propTypes.object,
  actions: propTypes.array.isRequired,
  subject: propTypes.string.isRequired
}
