import useDownloadPdf from '../../hooks/useDownloadPdf'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Button } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { crudConstPath } from '../../utils/enums/crudConst'

const url = crudConstPath.quotations.index

const ExportQuotationToPdfButton = ({ quotationId, filename }) => {
  const { t } = useTranslation(['transaltion', 'quotation'])
  const pdf_url = `${url}/${quotationId}.pdf`
  const { getPdf, withTotalsRef } = useDownloadPdf(pdf_url, filename)

  const handleDownload = () => {
    withTotalsRef.current = false
    return getPdf()
  }

  return (
    <Button
    variant='outlined'
    startIcon={<FileDownloadIcon />}
    onClick={handleDownload}>
      {t('export_to_pdf', { ns: 'quotation' })}
    </Button>
  )
}
ExportQuotationToPdfButton.propTypes = {
  quotationId: propTypes.string.isRequired,
  filename: propTypes.string.isRequired
}

export default ExportQuotationToPdfButton
