import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CompanyForm } from '../../components/companies/CompanyForm'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export function CompanyEdit() {
  //
  /* CONSTANTS */
  const { id } = useParams()
  const companyUpdateUrl = crudConstPath.companies.update(id)
  const companyEditUrl = crudConstPath.companies.edit(id)

  //
  /* HOOKS */
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()
  const company = data?.company

  useEffect(() => {
    sendRequest(companyEditUrl, crudConstNames.get)
  }, [id])

  const handleUpdate = async (values) => {
    const isFormData = true

    try {
      await sendRequest(companyUpdateUrl, 'patch', {}, { company: values }, null, 'json', isFormData)
      navigate('/companies')
    } catch (error) {
      notify(error.messages, severities.error)
    }
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox />
  return company ? <CompanyForm submitAction={handleUpdate} company={company} /> : null
}
