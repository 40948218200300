import { useTranslation } from 'react-i18next'

import { mappedFormats } from '../../utils/formats.js'
import { vacancyStatuses } from '../../utils/vacancy_status.js'

export const useAdSpaceKeyContent = () => {
  const { t } = useTranslation(['translation', 'adSpace'])
  const contentKey = (key, adSpace) => {
    const vacancyLabel = vacancyStatuses[adSpace.vacancy_status]?.label || ''
    const vacancyWithDelay = adSpace.vacancy_with_delay ? ` - (${t('vacancy_with_delay', { ns: 'adSpace' })})` : ''

    if (key.includes('price') || key.includes('cost')) {
      return t('currency', { val: adSpace[key], ns: 'adSpace' })
    }

    if (['daily_flips', 'pixel_pitch'].includes(key)){
      return t('number', { val: adSpace[key], ns: 'adSpace' })
    }

    if (key === 'full_size' || key === 'partial_size') {
      const widthInMeters = adSpace[key].width
      const heightInMeters = adSpace[key].height
      const invalidSize = !widthInMeters || !heightInMeters || widthInMeters === 0 || heightInMeters === 0
      if (invalidSize) return ''
      return t('ad_space_details_info.dimensions', {
        ns: 'adSpace',
        width: widthInMeters.toFixed(2),
        height: heightInMeters.toFixed(2)
      })
    }

    return (
      {
        format_id: mappedFormats[adSpace.format_id] || '',
        vacancy_status: adSpace.vacancy_status ? `${vacancyLabel}${vacancyWithDelay}` : ''
      }[key] ||
      adSpace[key] ||
      ''
    )
  }

  return { contentKey }
}