import { Form, Formik } from 'formik'
import propTypes from 'prop-types'
import { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import bidStoreSlices from '../../utils/enums/bidStoreSlices'
import { crudConst } from '../../utils/enums/crudConst'
import { QuotationData } from './QuotationData'
import { QuotationFormActions } from './QuotationFormActions'
import { QuotationFormBidsSection } from './QuotationFormBidsSection'

import '../../styles/quotations/QuotationDetails.css'
import { dealStatus } from '../../utils/enums/dealStatus'

const { create, update } = crudConst

const QuotationFormBidsSectionMemo = memo(QuotationFormBidsSection)

export const QuotationForm = ({ submitAction, quotation, advertisers, agencies, industries }) => {
  const action = quotation ? update : create
  const formRef = useRef()
  const isQuotationForm = dealStatus.default === quotation?.status
  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'quotation'])
  //
  /* METHODS */

  // .required(t('advertiser_required', { ns: 'quotation' }))
  /* RETURN */
  return (
    <div className='quotation-details-container'>
      <Formik
        initialValues={{
          title: quotation?.title || '',
          comments: quotation?.comments || '',
          advertiser_id: quotation?.advertiser?.id || '',
          agency_id: quotation?.agency?.id || ''
        }}
        innerRef={formRef}
        validationSchema={Yup.object({
          title: Yup.string().required(t('title_required', { ns: 'quotation' })),
          comments: Yup.string().nullable(),
          advertiser_id: Yup.number().required(t('advertiser_required', { ns: 'quotation' }))
        })}>
        {({ errors, touched, values }) => (
          <Form className='quotation-form-container' data-testid="quotation-form">
            <QuotationData errors={errors} touched={touched} advertisers={advertisers} agencies={agencies} industries={industries} />
            <QuotationFormBidsSectionMemo action={action} storeSliceId={bidStoreSlices.bidCart} />
            <QuotationFormActions action={action} submitAction={submitAction} formRef={formRef} quotationId={quotation?.id} isQuotationForm={isQuotationForm} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

QuotationForm.propTypes = {
  submitAction: propTypes.func.isRequired,
  quotation: propTypes.shape({
    id: propTypes.number,
    title: propTypes.string,
    comments: propTypes.string,
    name: propTypes.string,
    status: propTypes.number,
    advertiser_id: propTypes.number,
    advertiser: propTypes.shape({
      id: propTypes.number
    }),
    agency: propTypes.shape({
      id: propTypes.number
    })
  }),
  advertisers: propTypes.array,
  agencies: propTypes.array,
  industries: propTypes.array
}
