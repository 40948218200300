export const crudConst = {
  create: 0,
  update: 1,
  delete: 2,
  read: 3
}

export const crudConstNames = {
  create: 'new',
  get: 'GET',
  patch: 'PATCH',
  post: 'POST',
  put: 'PUT',
  update: 'updated'
}

export const crudConstPath = {
  advertiser: {
    create: process.env.REACT_APP_ADVERTISERS_PATH,
    edit: (id) => `${process.env.REACT_APP_ADVERTISERS_PATH}/${id}/edit`,
    index: process.env.REACT_APP_ADVERTISERS_PATH,
    update: (id) => `${process.env.REACT_APP_ADVERTISERS_PATH}/${id}`
  },
  adSpaces: {
    catalog_index: process.env.REACT_APP_AD_SPACES_CATALOG_INDEX_PATH,
    detailed_index: process.env.REACT_APP_AD_SPACES_DETAILED_INDEX_PATH,
    full_index: process.env.REACT_APP_AD_SPACES_FULL_INDEX_PATH,
    geo_index: process.env.REACT_APP_AD_SPACES_GEO_INDEX_PATH,
    index: process.env.REACT_APP_AD_SPACES_PATH,
    show: (id) => `/soportes/${id}`,
    public_show: (id) => `/soporte/${id}`
  },
  agencies: {
    create: process.env.REACT_APP_AGENCIES_PATH,
    edit: (id) => `${process.env.REACT_APP_AGENCIES_PATH}/${id}/edit`,
    index: process.env.REACT_APP_AGENCIES_PATH,
    new: process.env.REACT_APP_AGENCIES_NEW_PATH,
    update: (id) => `${process.env.REACT_APP_AGENCIES_PATH}/${id}`
  },
  campaigns: {
    index: process.env.REACT_APP_CAMPAINGS_PATH,
    pre_campaign: (id) => `${process.env.REACT_APP_CAMPAINGS_PATH}/${id}/pre_campaign`,
    update: (id) => `${process.env.REACT_APP_CAMPAINGS_PATH}/${id}`,
  },
  companies: {
    create: process.env.REACT_APP_COMPANIES_PATH,
    edit: (id) => `${process.env.REACT_APP_COMPANIES_PATH}/${id}/edit`,
    index: process.env.REACT_APP_COMPANIES_PATH,
    update: (id) => `${process.env.REACT_APP_COMPANIES_PATH}/${id}`
  },
  dashboard: process.env.REACT_APP_DASHBOARD_PATH,
  localities: process.env.REACT_APP_LOCALITIES_PATH,
  quotations: {
    create: process.env.REACT_APP_QUOTATIONS_PATH,
    edit: (id) => `${process.env.REACT_APP_QUOTATIONS_PATH}/${id}/edit`,
    new: process.env.REACT_APP_QUOTATIONS_NEW_PATH,
    index: process.env.REACT_APP_QUOTATIONS_PATH,
    request_pre_campaign: (id) => `${process.env.REACT_APP_QUOTATIONS_PATH}/${id}/request_pre_campaign`,
    replicate: (id) => `${process.env.REACT_APP_QUOTATIONS_PATH}/${id}/replicate`,
    xlsx: (id) => `${process.env.REACT_APP_QUOTATIONS_PATH}/${id}.xlsx`,
  },
  regions: process.env.REACT_APP_REGIONS_PATH,
  session: {
    login: process.env.REACT_APP_AUTH_PATH,
    logout: process.env.REACT_APP_LOGOUT_PATH
  },
  users: {
    index: process.env.REACT_APP_USERS_PATH,
    change_password: (id) => `${process.env.REACT_APP_USERS_PATH}/${id}/change_password`,
    edit: (id) => `${process.env.REACT_APP_USERS_PATH}/${id}/edit`,
    new: process.env.REACT_APP_USERS_PATH + '/new',
    preferences: (id) => `${process.env.REACT_APP_USER_PREFERENCES_PATH}/${id}`,
    update: (id) => `${process.env.REACT_APP_USERS_PATH}/${id}`
  }
}