import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAdSpaceDetails } from '../../hooks/adSpaces/useAdSpaceDetails'
import { detailsVariants as variants } from '../../utils/detailsVariants'
import { Modal } from '../modal/Modal'
import { AdSpaceDetailsContent } from './AdSpaceDetailsContent.jsx'

import '../../styles/adSpaces/Details.css'
export function AdSpaceDetails({ isOpened, closeModal, id, variant = variants.default }) {
  //
  /* HOOKS */
  const { adSpace } = useAdSpaceDetails(id, variant)
  //
  /* RETURN */
  if (!isOpened) return null
  return (
    <Modal isOpened={isOpened} closeModal={closeModal} fullWidth={true} maxWidth={'lg'}>
      <AdSpaceDetailsContent id={id} variant={variant} closeModal={closeModal} adSpace={adSpace} withHeader />
    </Modal>
  )
}

AdSpaceDetails.propTypes = {
  isOpened: propTypes.bool,
  closeModal: propTypes.func,
  id: propTypes.string,
  variant: propTypes.string
}
