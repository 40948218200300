const adSpace = require('./adSpace.json')
const bid = require('./bid.json')
const campaign = require('./campaign.json')
const commons = require('./commons.json')
const company = require('./company.json')
const country = require('./country.json')
const dashboard = require('./dashboard.json')
const provider = require('./provider.json')
const quotation = require('./quotation.json')
const translation = require('./translation.json')
const user = require('./user.json')
const sale = require('./sale.json')
const advertiser = require('./advertiser.json')
const agency = require('./agency.json')

export default {
  adSpace,
  advertiser,
  agency,
  bid,
  campaign,
  commons,
  company,
  country,
  dashboard,
  provider,
  quotation,
  sale,
  translation,
  user
}
