import Translations from './locales'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const resources = Translations
// for future use manage of translation
// we could use https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'es-CL',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
