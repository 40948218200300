import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

export function LoadingBox() {
  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}>
      <CircularProgress />
    </Box>
  )
}
