import { addBid, addBids, removeBid, updateBid, updatePackagePrice, resetOriginalPrice, setBids, setTotalsFromQuotation, setPackagePricing } from '../../store/bidsCartSlice'
import { useAppDispatch } from '../store'

export const useBidsCartActions = () => {
  const dispatch = useAppDispatch()

  const addBidToCart = (bid) => {
    dispatch(addBid(bid))
  }

  const addBidsToCart = (bids) => {
    dispatch(addBids(bids))
  }

  const removeBidFromCart = (bid, index) => {
    dispatch(removeBid({ bid, index }))
  }

  const updateBidInCart = (bid, index) => {
    dispatch(updateBid({ bid, index }))
  }

  const updatePackagePriceInCart = (bidsCart, attributes) => {
    dispatch(updatePackagePrice({bidsCart, attributes}))
  }

  const setBidsInCart = (bids) => {
    dispatch(setBids(bids))
  }

  const clearBidsInCart = () => {
    dispatch(setBids([]))
  }

  const setTotalsFromQuotationInCart = (total_leasing_price, total_installation_price, total_production_price, total_price) => {
    dispatch(setTotalsFromQuotation({
      totalLeasingPrice: total_leasing_price,
      totalInstallationPrice: total_installation_price,
      totalProductionPrice: total_production_price,
      totalSumPrice: total_price
    }))
  }

  const setPackagePricingInCart = (packagePricing) => {
    dispatch(setPackagePricing(packagePricing))
  }

  const resetOriginalPriceInCart = (bids) => {
    dispatch(resetOriginalPrice(bids))
  }

  return { addBidToCart, addBidsToCart, removeBidFromCart, updateBidInCart, updatePackagePriceInCart, setBidsInCart, clearBidsInCart, setTotalsFromQuotationInCart, setPackagePricingInCart, resetOriginalPriceInCart }
}
