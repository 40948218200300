import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LoadingBox } from '../../components/shared/LoadingBox'
import { UserForm } from '../../components/users/UserForm'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export const UserNew = () => {
  //
  /* CONSTANTS */
  const userCreateUrl = crudConstPath.users.create
  const userNewUrl = crudConstPath.users.new
  //
  /* HOOKS */
  const { t } = useTranslation(['user'])
  const navigate = useNavigate()
  const { data, loading, error, sendRequest } = useBackendApi()
  const companies = data?.companies
  const roles = data?.roles

  useEffect(() => {
    sendRequest(userNewUrl, crudConstNames.get)
  }, [])

  //
  /* METHODS */
  const handleCreate = async values => {
    try {
      await sendRequest(userCreateUrl, crudConstNames.post, {}, values)
      navigate('/users')
      notify(t('created', { ns: 'user' }), severities.success)
    } catch (error) {
      notify(error.messages, severities.error)
    }
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return companies ? <UserForm submitAction={handleCreate} companies={companies} roles={ roles } /> : null
}
