import propTypes from 'prop-types'

import '../../styles/campaigns/CampaignMainMenu.css'
import { CampaignTabs } from './CampaignTabs'

export function CampaignMainMenu({ initialTabOpen }) {
  return (
    <div className='campaign-tabs-and-filters'>
      <CampaignTabs tab={initialTabOpen} />
    </div>
  )
}
CampaignMainMenu.propTypes = {
  initialTabOpen: propTypes.number
}
