import bidStoreSlices from '../utils/enums/bidStoreSlices'
import { useBidsByProviderActions } from './actions/useBidsByProviderActions'
import { useBidsCartActions } from './actions/useBidsCartActions'

export const useBidsActionBySlice = (slice) => {
  const bidCartActions = useBidsCartActions()
  const bidByProviderActions = useBidsByProviderActions()
  const actions = slice === bidStoreSlices.bidCart ? bidCartActions : bidByProviderActions
  return actions
}
