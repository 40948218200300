import '../../styles/navbar/SideMenu.css'
import { PermissionWrapper } from '../permissions/PermissionWrapper'
import { SideMenuItem } from './SideMenuItem'
import { People } from '@mui/icons-material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CardTravelIcon from '@mui/icons-material/CardTravel'
import BadgeIcon from '@mui/icons-material/Badge'
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import { List } from '@mui/material'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// CONSTANTS
const drawerWidth = 240

// HOOKS

// METHODS

const openedMixin = (theme) => ({
  marginTop: 64,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),

  overflowX: 'hidden',
  marginTop: 64,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const itemIcon = (icon, text, isActive) => {
  return isActive ? (
    icon
  ) : (
    <Tooltip title={text} placement='right'>
      {icon}
    </Tooltip>
  )
}

export function SideMenu({ isActive, closeMenu }) {
  const { t } = useTranslation(['translation', 'company', 'quotation', 'campaign', 'user', 'advertiser'])

  // RETURN
  return (
    <Box
      role='presentation'
      onClick={closeMenu}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        top: '64px',
        width: `${isActive ? '100vw' : '0px'}`,
        height: `${isActive ? '100vh' : '0px'}`,
        position: 'absolute'
      }}>
      <Drawer variant='permanent' open={isActive} classes={{ docked: isActive ? 'docked' : 'docked-closed' }}>
        <List>
          <SideMenuItem
            title={t('new', { ns: 'quotation' })}
            icon={itemIcon(<AssignmentIcon />, t('new', { ns: 'quotation' }), isActive)}
            to='/quotations/new'
            onClick={closeMenu}
          />
          <SideMenuItem
            title={t('quotations', { ns: 'quotation' })}
            icon={itemIcon(<ListAltRoundedIcon />, t('quotations', { ns: 'quotation' }), isActive)}
            to='/quotations'
            onClick={closeMenu}
          />
          <SideMenuItem
            title={t('campaigns', { ns: 'campaign' })}
            icon={itemIcon(<AssignmentTurnedInIcon />, t('campaigns', { ns: 'campaign' }), isActive)}
            to='/campaigns'
            onClick={closeMenu}
          />
          {/* SALE_MODULE: hidden temporarily */}
          {/* <PermissionWrapper actions={['manage']} subject='Campaign'>
            <SideMenuItem
              title={t('sales', { ns: 'sale' })}
              icon={itemIcon(<ShoppingBasketIcon />, t('sales', { ns: 'sale' }), isActive)}
              to='/sales'
              onClick={closeMenu}
            />
          </PermissionWrapper> */}
          <PermissionWrapper actions={['manage']} subject='Advertiser'>
            <SideMenuItem
              title={t('advertisers', { ns: 'advertiser' })}
              icon={itemIcon(<CardTravelIcon />, t('advertisers', { ns: 'advertiser' }), isActive)}
              to='/advertisers'
              onClick={closeMenu}
            />
          </PermissionWrapper>
          <PermissionWrapper actions={['manage']} subject='Agency'>
            <SideMenuItem
              title={t('agencies', { ns: 'agency' })}
              icon={itemIcon(<BadgeIcon />, t('agencies', { ns: 'agency' }), isActive)}
              to='/agencies'
              onClick={closeMenu}
            />
          </PermissionWrapper>
          <PermissionWrapper actions={['manage']} subject='User'>
            <SideMenuItem
              title={t('users', { ns: 'user' })}
              icon={itemIcon(<People />, t('users', { ns: 'user' }), isActive)}
              to='/users'
              onClick={closeMenu}
            />
          </PermissionWrapper>
          <PermissionWrapper actions={['manage']} subject='Company'>
            <SideMenuItem
              title={t('companies', { ns: 'company' })}
              icon={itemIcon(<ApartmentIcon />, t('companies', { ns: 'company' }), isActive)}
              to='/companies'
              onClick={closeMenu}
            />
          </PermissionWrapper>
          <PermissionWrapper actions={['manage']} subject='Dashboard'>
            <SideMenuItem
              title={t('dashboard', { ns: 'commons' })}
              icon={itemIcon(<DashboardCustomizeIcon />, t('dashboard', { ns: 'commons' }), isActive)}
              to='/dashboard'
              onClick={closeMenu}
            />
          </PermissionWrapper>
        </List>
      </Drawer>
    </Box>
  )
}

SideMenu.propTypes = {
  isActive: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired
}
