import memoize from 'memoize-one'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import ComponentDataTable from '../../components/Utils/DataTable/index'
import DataTableActionButtons from '../ActionBtns/DataTableActionButtons'

const columns = memoize(({ handleInfoClick, handleAddToCart, t }) => [
  {
    name: t('sku', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'sku',
    cell: ({ sku }) => sku,
    grow: '1'
  },
  {
    name: t('code', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'provider_ref',
    cell: ({ code }) => code,
    grow: '1'
  },
  {
    name: t('provider', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'provider_name',
    cell: ({ provider_name }) => provider_name,
    grow: '1'
  },
  {
    name: t('format', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'format_name',
    cell: ({ format_name }) => format_name,
    grow: '1'
  },
  {
    name: t('name', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'name',
    cell: ({ name }) => name,
    grow: '1'
  },
  {
    name: t('locality', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'locality_name',
    cell: ({ locality_name }) => locality_name,
    grow: '1'
  },
  {
    name: t('leasing_price', { ns: 'adSpace' }),
    sortable: true,
    sortField: 'suggested_price',
    cell: ({ leasing_price }) => t('currency', { val: leasing_price, ns: 'adSpace' }),
    grow: '1'
  },
  {
    cell: (item) => (
      <DataTableActionButtons item={item} handleInfoClick={handleInfoClick} handleAddToCart={handleAddToCart} />
    ),
    ignoreRowClick: true,
    grow: '1'
  }
])

export function AdSpacesDataTable({
  tableData,
  totalPages,
  setPage,
  setPageSize,
  handleInfoClick,
  handleAddToCart,
  isFetching,
  setSortInfo
}) {
  const { t } = useTranslation(['translation', 'adSpace'])

  return (
    <>
      <ComponentDataTable
        data={tableData}
        onRequest={isFetching}
        totalPages={totalPages}
        setPage={setPage}
        columns={columns({
          handleInfoClick,
          handleAddToCart,
          t
        })}
        setPageSize={setPageSize}
        RowsPage={30}
        setSortInfo={setSortInfo}
      />
    </>
  )
}

export default AdSpacesDataTable

AdSpacesDataTable.propTypes = {
  tableData: propTypes.array.isRequired,
  totalPages: propTypes.number.isRequired,
  setPage: propTypes.func.isRequired,
  handleInfoClick: propTypes.func.isRequired,
  handleAddToCart: propTypes.func.isRequired,
  isFetching: propTypes.bool.isRequired,
  setSortInfo: propTypes.func.isRequired,
  setPageSize: propTypes.func.isRequired
}
