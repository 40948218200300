import Button from '@mui/material/Button'
import propTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuotationTabActions } from '../../hooks/actions/useQuotationTabActions'
import { useAppSelector } from '../../hooks/store'
import { quotationTabNames, quotationTabTypes } from '../../utils/enums/quotationTabTypes'

import '../../styles/QuotationTabs.css'

export function QuotationTabs({ tab }) {
  // HOOKS
  const { t } = useTranslation(['translation', 'quotation'])
  const { setCurrentQuotationTab } = useQuotationTabActions()
  const currentTab = useAppSelector((state) => state.quotationTab.currentTab)

  useEffect(() => {
    // carga el tab inicial
    if (tab) {
      setCurrentQuotationTab(tab)
    }
  }, [])

  // METHODS
  const handleTabClick = (event) => {
    setCurrentQuotationTab(quotationTabTypes[event.currentTarget.id])
  }

  // RETURN
  return (
    <div className='quotation-tabs'>
      <Button
        id={quotationTabNames.map_tab_open}
        className='quotation-tabs-button'
        variant={currentTab === quotationTabTypes.map_tab_open ? 'contained' : 'outlined'}
        onClick={handleTabClick}>
        {t('map', { ns: 'translation' })}
      </Button>

      <Button
        id={quotationTabNames.catalog_tab_open}
        className='quotation-tabs-button'
        variant={currentTab === quotationTabTypes.catalog_tab_open ? 'contained' : 'outlined'}
        onClick={handleTabClick}>
        {t('catalog', { ns: 'quotation' })}
      </Button>
    </div>
  )
}

QuotationTabs.propTypes = {
  tab: propTypes.number
}
