/* eslint-disable react/prop-types */
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Field } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// CONSTANTS
const MenuProps = {
  PaperProps: {
    style: {
      minWidth: '200px'
    }
  }
}

export const LocalityFilterInput = ({ localities }) => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])

  // METHODS

  // RETURN
  return (
    localities
      ? <FormControl className="form-control-filters" variant='outlined' size='small' fullWidth>
          <InputLabel htmlFor='locality-select' >{t('locality', { ns: 'adSpace' })}</InputLabel>
          <Field
            as={Select}
            name='locality_ids'
            id='region-select'
            label={ t('region', { ns: 'adSpace' })}
            multiple
            disabled={localities.length === 0}
            MenuProps={MenuProps}
            className="filters-input-select"
          >
            {localities.map((locality, index) => {
              const { uuid, name } = locality
              return (
                <MenuItem
                  key={index}
                  value={uuid}
                  className='filters-menu-item'
                >
                  {name}
              </MenuItem>
              )
            })}
          </Field>
        </FormControl>
      : null
  )
}

LocalityFilterInput.propTypes = {
  localities: propTypes.array.isRequired
}
