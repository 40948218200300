import AddIcon from '@mui/icons-material/Add'
import { Button, Chip } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useBidsCartActions } from '../hooks/actions/useBidsCartActions'
import { useAppSelector } from '../hooks/store'

// TODO: ver si esto se puede usar en otro lado para agregar al carrito
export function AddToCartButton({ adSpace }) {
  const availability_start_date = useAppSelector((state) => {
    return state.mapFilter.adSpacesFilter.availability_start_date
  })

  const availability_end_date = useAppSelector((state) => {
    return state.mapFilter.adSpacesFilter.availability_end_date
  })
  const selectBids = (state) => state.bidsCart.bids

  // Selector para obtener el uuid del adSpace
  const selectAdSpaceUuid = (state, adSpace) => adSpace?.uuid
  const selectBidsInCart = createSelector([selectBids, selectAdSpaceUuid], (bids, adSpaceUuid) =>
    bids.filter((bid) => bid.ad_space_uuid === adSpaceUuid && bid._destroy === false)
  )
  const bidsInCart = useAppSelector((state) => selectBidsInCart(state, adSpace))
  const quantityLabel = bidsInCart.length > 9 ? '9+' : bidsInCart.length
  const { t } = useTranslation(['translation'])
  const { addBidToCart } = useBidsCartActions()

  const quantityChip = (label) => {
    return label > 0 ? (
      <Chip label={label} sx={{ ml: 1, backgroundColor: 'white' }} size='small' color='secondary' variant='outlined' />
    ) : null
  }

  const handleAddToCart = () => {
    adSpace.start_date = availability_start_date
    adSpace.end_date = availability_end_date
    addBidToCart(adSpace)
  }

  return (
    <Button variant='contained' color='secondary' startIcon={<AddIcon />} onClick={handleAddToCart}>
      {t('add', { ns: 'translation' })}
      {quantityChip(quantityLabel)}
    </Button>
  )
}

AddToCartButton.propTypes = {
  adSpace: propTypes.object.isRequired,
  afterAddFn: propTypes.func
}
