import { useMemo, useState } from 'react'

import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'
import { useAdSpacesDataTable } from '../../hooks/adSpaces/useAdSpacesDataTable'
import { adSpaceCardDataCleaner } from '../../utils/adSpaceCardDataCleaner'
import { detailsVariants } from '../../utils/detailsVariants'
import { AdSpaceDetails } from './AdSpaceDetails'
import AdSpacesDataTable from './AdSpacesDataTable'
import propTypes from 'prop-types'

import '../../styles/adSpaces/List.css'

export function AdSpacesIndex({isTabActive}) {
  const [isOpened, setIsOpened] = useState(false)
  const [selected, setSelected] = useState(null)
  const [sortInfo, setSortInfo] = useState({})

  /* HOOKS */
  // TODO: cambiar el nombre del hook, no es claro que hace
  // podria ser un hook mas generico, que se utilice en otros listados de AdSpaces
  const { adSpaces, setPage, setPageSize, totalPages, loading } = useAdSpacesDataTable(sortInfo, isTabActive)
  const { addBidToCart } = useBidsCartActions()

  /* METHODS */
  const handleCloseModal = () => setIsOpened(false)

  const cleanedAdSpaces = useMemo(() => {
    return adSpaces?.map((adSpace) => adSpaceCardDataCleaner(adSpace, 'adSpace'))
  }, [adSpaces])

  const handleAddToCart = (adSpaceUuid) => {
    const adSpace = adSpaces.find((adSpace) => adSpace.uuid === adSpaceUuid)
    addBidToCart(adSpace)
  }

  const handleInfoClick = (adSpaceUuid) => {
    setSelected(adSpaceUuid)
    setIsOpened(true)
  }

  /* RETURN */
  return (
    <>
      <AdSpacesDataTable
        tableData={cleanedAdSpaces}
        totalPages={totalPages}
        setPage={setPage}
        setPageSize={setPageSize}
        handleInfoClick={handleInfoClick}
        handleAddToCart={handleAddToCart}
        isFetching={loading}
        setSortInfo={setSortInfo}
      />
      {selected && isOpened ? (
        // TODO: Evaluar si se puede separar esta funcionalidad en tal vez un onClick,
        // ya que es muy parecido a los markers y adSpaceCard tal vez mejor podria ser un hoook
        <AdSpaceDetails
          isOpened={isOpened}
          closeModal={handleCloseModal}
          id={selected}
          variant={detailsVariants.withAddToCartButton}
        />
      ) : null}
    </>
  )
}

AdSpacesIndex.propTypes = {
  isActive: propTypes.bool
}
