import { MenuItem, TextField } from '@mui/material';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

export const SelectForm = ({
  resource,
  value,
  onChange,
  options,
  ModalForm,
  isModalOpen,
  onModalClose,
  onSubmit,
  isRequired,
  modalProps,
  error,
  helperText
}) => {

  const resourceId = `${resource}_id`
  const { t } = useTranslation(['translation', resource, 'quotation'])
  const label = isRequired ? `${t(resource, { ns: 'quotation' })} *` : t(resource, { ns: 'quotation' })

  return (
    <>
      <Field
        as={TextField}
        name={resourceId}
        id={resourceId}
        label={label}
        labelId={resourceId}
        placeholder={t(`select_${resource}`, { ns: `${resource}` })}
        error={!!error}
        helperText={helperText}
        fullWidth
        select
        sx={{ flex: 1 }}
        size="small"
        value={value}
        onChange={onChange}
      >
        <MenuItem value=''>{t(`select_${resource}`, { ns: `${resource}` })}</MenuItem>
        <MenuItem value='new'>{t('new', { ns: resource })}</MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Field>
      {isModalOpen && (
        <ModalForm
          isOpened={isModalOpen}
          closeModalHandler={onModalClose}
          onSubmit={onSubmit}
          {...modalProps}
        />
      )}
    </>
  );
};

SelectForm.propTypes = {
  resource: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  ModalForm: PropTypes.elementType.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  modalProps: PropTypes.object,
  error: PropTypes.string,
  helperText: PropTypes.string
};
