import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentTab: 0
}

export const campaignTabSlice = createSlice({
  name: 'campaignTab',
  initialState,
  reducers: {
    setCampaignTab: (state, action) => {
      state.currentTab = action.payload
    }
  }
})

export const { setCampaignTab } = campaignTabSlice.actions
export default campaignTabSlice.reducer
