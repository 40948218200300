import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  CardActions,
} from '@mui/material'
import { Box } from '@mui/system'
import { useJsApiLoader } from '@react-google-maps/api'
import propTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAdSpaceKeyContent } from '../../hooks/adSpaces/useAdSpaceKeyContent'
import { attributesList, detailsVariants as variants } from '../../utils/detailsVariants'
import { crudConstPath } from '../../utils/enums/crudConst'
import { libraries } from '../../utils/mapVariables'
import { AddToCartButton } from '../AddToCartButton'
import { Map } from '../map/Map'
import { PermissionWrapper } from '../permissions/PermissionWrapper'
import { ObjectList } from '../shared/ObjectList'
import { useAppSelector } from '../../hooks/store'

export function AdSpaceDetailsContent({ variant = variants.default, adSpace, closeModal, withHeader, showStreetView }) {
  const attributesShown = attributesList[variant] || attributesList.default
  const { t } = useTranslation(['translation', 'adSpace'])
  const [streetView, setStreetView] = useState(false)
  const { contentKey } = useAdSpaceKeyContent()
  const isActiveVisitMode = useAppSelector((state) => state.visitMode.isActive)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  const adSpaceData = adSpace ? attributesShown.reduce((map, key) => {
    map[key] = contentKey(key, adSpace)
    return map
  }, {}) : {}

  if (!adSpace) return null

  const adSpaceUrl = variant === variants.public ? crudConstPath.adSpaces.public_show(adSpace.uuid) : crudConstPath.adSpaces.show(adSpace.uuid)
  const adSpaceCode = variant === variants.public || isActiveVisitMode ? '' : `// ${t('code', { ns: 'adSpace' })}: ${adSpace.code || ''}`

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      {withHeader ? (
        <CardHeader
          sx={{ bgcolor: 'secondary.main', color: 'white' }}
          bgcolor='secondary.main'
          title={adSpace.name}
          subheader={`${t('sku', { ns: 'adSpace' })}: ${adSpace.sku || ''} ${adSpaceCode}`}
          className='header'
        />
      ) : null}
      <CardContent className='content'>
        <CardMedia
          sx={{ flex: 5, aspectRatio: '3/2', objectFit: 'contain', width: '50%' }}
          component='img'
          image={adSpace.profile_photo || `${process.env.PUBLIC_URL}/assets/shared/default-photo.png`}
          alt={t('imageAlt', { ns: 'adSpace' })}
          loading="lazy"
        />
        <Box
          sx={{ flex: 4, display: 'flex', flexDirection: 'column', px: 1 }}
          style={{ height: '400px', overflowY: 'scroll' }}>
          <Typography variant='h6' sx={{ marginBottom: '10px' }}>
            Especificaciones
          </Typography>
          <ObjectList data={adSpaceData} nameSpace={'adSpace'}/>

          <Typography variant='h6' className='sub-divition-title'>
            {t('notes', { ns: 'adSpace' })}
          </Typography>
          <Typography>
            {adSpace.notes ? adSpace.notes : t('no_info', { ns: 'adSpace' })}
          </Typography>

          {/* TODO: Make sure to replace this for adSpace when module is added */}
          <PermissionWrapper subject='Quotation' actions={['manage']}>
            <Typography variant='h6' className='sub-divition-title'>
              {t('comments', { ns: 'adSpace' })}
            </Typography>
            <Typography>
              {adSpace.comments ? adSpace.comments : t('no_info', { ns: 'adSpace' })}
            </Typography>
          </PermissionWrapper>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            pl: '15px',
            pr: '15px',
            pt: '15px',
            display: streetView ? 'block' : 'none'
          }}>
          {isLoaded ? (
            <Map
              key={`${adSpace.uuid}`}
              initialCenter={{ lat: adSpace.lat, lng: adSpace.lng }}
              showAllMarkers={false}
              streetViewOpened={true}/>
          ) : null}
        </Box>
      </CardContent>
      <CardActions>
        <Box className='actions' sx={{ flex: 1, display: 'flex', flexDirection: 'row', pl: 2 }}>
          <Button variant='outlined'
            onClick={() => window.open(adSpaceUrl, '_blank')}>
            {t('more-details')}
          </Button>
          {variant === variants.withAddToCartButton && closeModal ? (
            <AddToCartButton adSpace={adSpace} />
          ) : null}
          <Button variant='outlined' onClick={() => setStreetView(!streetView)}>
            {streetView ? t('close-stree-view') : t('open-stree-view')}
          </Button>
          {closeModal ? (
            <Button variant='outlined' onClick={closeModal}>
              {t('close')}
            </Button>
          ) : null}
        </Box>
      </CardActions>
    </Card>
  )
}

AdSpaceDetailsContent.propTypes = {
  adSpace: propTypes.object,
  variant: propTypes.string,
  closeModal: propTypes.func,
  withHeader: propTypes.bool,
}
