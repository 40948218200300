import '../styles/Alert.css'
import CloseIcon from '@mui/icons-material/Close'
import { Alert as MuiAlert } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import propTypes from 'prop-types'

export function Alert({ message, severity, showError, setShowError }) {
  // HOOKS

  // METHODS
  const closeAlert = event => {
    event.preventDefault()
    setShowError(false)
    message = null
  }

  // RETURN
  return (
    <>
      {' '}
      {showError && (
        <MuiAlert
          severity={severity}
          action={
            <IconButton aria-label='close' color='inherit' size='small' onClick={closeAlert}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          {message}
        </MuiAlert>
      )}
    </>
  )
}

Alert.propTypes = {
  message: propTypes.string,
  severity: propTypes.string,
  showError: propTypes.bool,
  setShowError: propTypes.func
}
