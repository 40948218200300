import '../index.css'
import './DefaultLayout.css'
import { Container, Grid, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function DefaultLayout({ docTitle, children }) {
  const location = useLocation()

  useEffect(() => {
    document.title = docTitle
  }, [location])

  const theme = useTheme()
  return (
    <Container
      fluid='true'
      maxWidth='false'
      disableGutters={true}
      className='d-layout-container'
      sx={{ pt: `${theme.mixins.toolbar.minHeight + 10}px` }}
    >
      {children}
    </Container>
  )
}
