export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

export const isEmptyObjectDeep = (obj) => {
  return Object.values(obj).every(val =>
    val === null ||
    val === undefined ||
    val === '' ||
    (Array.isArray(val) && val.length === 0))
}
