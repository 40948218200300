
/**
 * Gets map's bounds with an scaled bound.
 * @link   http://stackoverflow.com/a/34900987
 * @param  {Integer} offset - Bounds offset. A positive number results in inwards padding.
 *                            A negative number pads outwards.
 * @return {google.maps.LatLngBounds} Padded bounds
 */

export const getPaddedMapBounds = (map, offset = -150) => {
  const SW = map.getBounds().getSouthWest();
  const NE = map.getBounds().getNorthEast();
  const topRight = map.getProjection().fromLatLngToPoint(NE);
  const bottomLeft = map.getProjection().fromLatLngToPoint(SW);
  const scale = Math.pow(2, map.getZoom());

  const SWtopoint = map.getProjection().fromLatLngToPoint(SW);
  const SWpoint = new window.google.maps.Point(((SWtopoint.x - bottomLeft.x) * scale) + offset, ((SWtopoint.y - topRight.y) * scale) - offset);
  const SWworld = new window.google.maps.Point(SWpoint.x / scale + bottomLeft.x, SWpoint.y / scale + topRight.y);
  const pt1 = map.getProjection().fromPointToLatLng(SWworld);

  const NEtopoint = map.getProjection().fromLatLngToPoint(NE);
  const NEpoint = new window.google.maps.Point(((NEtopoint.x - bottomLeft.x) * scale) - offset, ((NEtopoint.y - topRight.y) * scale) + offset);
  const NEworld = new window.google.maps.Point(NEpoint.x / scale + bottomLeft.x, NEpoint.y / scale + topRight.y);
  const pt2 = map.getProjection().fromPointToLatLng(NEworld);

  return new window.google.maps.LatLngBounds(pt1, pt2)
}