import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import useBackendApi from './useBackendApi'

export const useDestroyRecord = () => {
  const { sendRequest } = useBackendApi()
  const destroyRecord = (id, url, notification_message) => {
    sendRequest(`${url}/${id}`, 'delete')
    notify(notification_message, severities.success)
  }
  return { destroyRecord }
}
