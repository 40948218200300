import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Field } from 'formik'
import moment from 'moment'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export function DateRangePicker({ values, setFieldValue }) {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])
  // METHODS

  // RETURN

  return (
    <div className='date-range-picker'>
      <Field name='availability_start_date'>
        {({ field }) => (
          <DatePicker
            {...field}
            label={t('availability_start_date', { ns: 'adSpace' })}
            slotProps={{ textField: { size: 'small' } }}
            format={t('date_format', { ns: 'translation' })}
            value={values.availability_start_date}
            onChange={(date) => setFieldValue('availability_start_date', date)}
            inputFormat={t('date_input_format', { ns: 'translation' })}
            className='filter-input'
          />
        )}
      </Field>

      <Field name='availability_end_date'>
        {({ field }) => (
          <DatePicker
            {...field}
            label={t('availability_end_date', { ns: 'adSpace' })}
            format={t('date_format', { ns: 'translation' })}
            value={values.availability_end_date}
            minDate={values.availability_start_date ? moment(values.availability_start_date) : null}
            onChange={(date) => setFieldValue('availability_end_date', date)}
            inputFormat={t('input_date_format', { ns: 'translation' })}
            slotProps={{ textField: { size: 'small' } }}
            className='filter-input'
          />
        )}
      </Field>
    </div>
  )
}

DateRangePicker.propTypes = {
  values: propTypes.object.isRequired,
  setFieldValue: propTypes.func.isRequired
}
