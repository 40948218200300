import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

export const AdvertiserFormFields = ({ errors, touched, translated, industries }) => (
  <>
    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        error={errors.name && touched.name}
        helperText={errors.name && touched.name ? errors.name : ''}
        name='name'
        id='name'
        label={`${translated('name', { ns: 'advertiser' })} *`}
        fullWidth
        sx={{ flex: 1 }}
        size='small'
      />
      <Field name='national_id'>
        {({ field }) => (
          <MaskedInput
            {...field}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9kK]/]}
            guide={false}
            render={(ref, inputProps) => (
              <TextField
                {...inputProps}
                label={`${translated('national_id', { ns: 'company' })} *`}
                error={errors.national_id && touched.national_id}
                helperText={errors.national_id && touched.national_id ? errors.national_id : ''}
                id='national_id'
                size='small'
                fullWidth
                sx={{ flex: 1 }}
                inputRef={ref}
              />
            )}
          />
        )}
      </Field>
    </FormControl>

    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        error={errors.corporate_name && touched.corporate_name}
        helperText={errors.corporate_name && touched.corporate_name ? errors.corporate_name : ''}
        name='corporate_name'
        id='corporate_name'
        label={translated('corporate_name', { ns: 'advertiser' })}
        fullWidth
        sx={{ flex: 1 }}
        size='small'
      />

      <Field
        as={TextField}
        name='trade_name'
        id='trade_name'
        label={translated('trade_name', { ns: 'advertiser' })}
        sx={{ flex: 1 }}
        size='small'
      />
    </FormControl>

    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        name='industry_id'
        id='industry_id'
        label={translated('industry', { ns: 'advertiser' })}
        sx={{ flex: 1 }}
        size='small'
        fullWidth
        select
        error={errors.industry_id && touched.industry_id}
        helperText={errors.industry_id && touched.industry_id ? errors.industry_id : ''}
      >
        <MenuItem value=''>{translated('select_industry', { ns: 'advertiser' })}</MenuItem> {/* Opción vacía */}
        {industries.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Field>

      <Field
        as={TextField}
        error={errors.email && touched.email}
        helperText={errors.email && touched.email ? errors.email : ''}
        name='email'
        type='email'
        id='email'
        label={translated('email', { ns: 'advertiser' })}
        fullWidth
        sx={{ flex: 1 }}
        size='small'
      />
    </FormControl>

    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        name='address'
        id='address'
        label={translated('address', { ns: 'advertiser' })}
        sx={{ flex: 1 }}
        size='small'
      />
    </FormControl>
  </>
);

AdvertiserFormFields.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  translated: PropTypes.func.isRequired,
  industries: PropTypes.array.isRequired,
};