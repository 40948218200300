import { useTranslation } from 'react-i18next'
import { Routes, Route } from 'react-router-dom'

import { Page } from '../pages/Page'
import { PublicPage } from '../pages/PublicPage'
import { AdSpaceShow } from '../pages/ad_spaces/AdSpaceShow'
import { AdvertiserEdit } from '../pages/advertisers/AdvertiserEdit'
import { AdvertiserIndex } from '../pages/advertisers/AdvertiserIndex'
import { AdvertiserNew } from '../pages/advertisers/AdvertiserNew'
import { AgencyIndex } from '../pages/agencies/AgencyIndex'
import { AgencyNew } from '../pages/agencies/AgencyNew'
import { AgencyEdit } from '../pages/agencies/AgencyEdit'
import { Login } from '../pages/auth/Login'
import { CompanyEdit } from '../pages/companies/CompanyEdit'
import { CompanyIndex } from '../pages/companies/CompanyIndex'
import { CompanyNew } from '../pages/companies/CompanyNew'
import { Dashboard } from '../pages/dashboard/Dashboard'
import { QuotationEdit } from '../pages/quotations/QuotationEdit'
import { QuotationIndex } from '../pages/quotations/QuotationIndex'
import { QuotationNew } from '../pages/quotations/QuotationNew'
import { QuotationShow } from '../pages/quotations/QuotationShow'
import { SaleConfirmation } from '../pages/sales/SaleConfirmation'
import { SaleShow } from '../pages/sales/SaleShow'
import { SalesIndex } from '../pages/sales/SalesIndex'
import { CampaignIndex } from '../pages/campaigns/CampaignIndex'
import { UserEdit } from '../pages/users/UserEdit'
import { UserIndex } from '../pages/users/UserIndex'
import { UserNew } from '../pages/users/UserNew'
import { CampaignNew } from '../pages/campaigns/CampaignNew'
export const index = () => {
  const { t } = useTranslation()

  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route
        path='/quotation/:id'
        element={
          <PublicPage title={t('show', { ns: 'quotation' })}>
            <QuotationShow publicPage={true} />
          </PublicPage>
        }
      />
      <Route
        path='/soporte/:id'
        element={
          <PublicPage title={t('show', { ns: 'adSpace' })}>
            <AdSpaceShow publicPage={true} />
          </PublicPage>
        }
      />
      <Route path='/soportes'>
        <Route
            path=':id'
            element={
              // TODO: change subjext to AdSpace when module is included
              <Page title={t('show', { ns: 'adSpace' })} subject='Quotation' actions={['manage']}>
                <AdSpaceShow />
              </Page>
            }
          />
      </Route>
      <Route path='/quotations'>
        <Route
          index
          element={
            <Page
              title={t('list', { ns: 'quotation' })}
              subject='Quotation'
              actions={['manage', 'read']}
             >
              <QuotationIndex />
            </Page>
          }
        />
        <Route
          path='new'
          element={
            <Page title={t('new', { ns: 'quotation' })} subject='Quotation' actions={['manage']}>
              <QuotationNew />
            </Page>
          }
        />
        <Route
          path=':id/edit'
          element={
            <Page title={t('edit', { ns: 'quotation' })} subject='Quotation' actions={['manage']}>
              <QuotationEdit />
            </Page>
          }
        />
        <Route
          path=':id'
          element={
            <Page title={t('show', { ns: 'quotation' })} subject='Quotation' actions={['manage']}>
              <QuotationShow />
            </Page>
          }
        />
      </Route>
      <Route path='/companies'>
        <Route
          index
          element={
            <Page
              title={t('list', { ns: 'company' })}
              subject='Company'
              actions={['manage', 'read']}
             >
              <CompanyIndex />
            </Page>
          }
        />
        <Route
          path='new'
          element={
            <Page title={t('new', { ns: 'company' })} subject='Company' actions={['manage']}>
              <CompanyNew />
            </Page>
          }
        />
        <Route
          path=':id/edit'
          element={
            <Page title={t('edit', { ns: 'company' })} subject='Company' actions={['manage']}>
              <CompanyEdit />
            </Page>
          }
        />
      </Route>
      <Route path='/users'>
        <Route
          index
          element={
            <Page title={t('list', { ns: 'user' })} subject='User' actions={['manage', 'read']}>
              <UserIndex />
            </Page>
          }
        />
        <Route
          path='new'
          element={
            <Page title={t('new', { ns: 'user' })} subject='User' actions={['manage']}>
              <UserNew />
            </Page>
          }
        />
        <Route
          path=':id/edit'
          element={
            <Page title={t('edit', { ns: 'user' })} subject='User' actions={['manage']}>
              <UserEdit />
            </Page>
          }
        />
      </Route>
      <Route path='/campaigns'>
        <Route
          index
          element={
            <Page
              title={t('list', { ns: 'campaign' })}
              subject='Quotation'
              actions={['manage', 'read']}
             >
              <CampaignIndex />
            </Page>
          }
        />
        <Route
          path=':id/pre_campaign'
          element={
            <Page
              title={t('list', { ns: 'campaign' })}
              subject='Quotation'
              actions={['manage', 'read']}
             >
              <CampaignNew />
            </Page>
          }
        />
      </Route>
      {/* @TO-DO: manage SALE subject */}
      {/* SALE_MODULE: hidden temporarily */}
      {/* <Route path='/sales'>
        <Route
          index
          element={
            <Page
              title={t('list', { ns: 'sale' })}
              subject='Quotation'
              actions={['manage', 'read']}
             >
              <SalesIndex />
            </Page>
          }
        />
        <Route
          path=':id/confirmation'
          element={
            <Page
              title={t('confirm_sale', { ns: 'sale' })}
              subject='Campaign'
              actions={['manage']}
             >
              <SaleConfirmation />
            </Page>
          }
        />
        <Route
          path=':id'
          element={
            <Page title={t('show', { ns: 'sale' })} subject='Campaign' actions={['manage']}>
              <SaleShow />
            </Page>
          }
        />
      </Route> */}
      <Route path='/dashboard'>
        <Route
          index
          element={
            <Page
              title={t('dashboard', { ns: 'commons' })}
              subject='Dashboard'
              actions={['manage', 'read']}
             >
              <Dashboard />
            </Page>
          }
        />
      </Route>
      <Route path='/advertisers'>
        <Route
          index
          element={
            <Page
              title={t('list', { ns: 'advertiser' })}
              subject='Advertiser'
              actions={['manage', 'read']}
             >
              <AdvertiserIndex />
            </Page>
          }
        />
        <Route
          path='new'
          element={
            <Page title={t('new', { ns: 'advertiser' })} subject='Advertiser' actions={['manage']}>
              <AdvertiserNew />
            </Page>
          }
        />
        <Route
          path=':id/edit'
          element={
            <Page
              title={t('edit', { ns: 'advertiser' })}
              subject='Advertiser'
              actions={['manage']}
             >
              <AdvertiserEdit />
            </Page>
          }
        />
      </Route>
      <Route path='/agencies'>
        <Route
          index
          element={
            <Page
              title={t('list', { ns: 'agency' })}
              subject='Agency'
              actions={['manage', 'read']}
             >
              <AgencyIndex />
            </Page>
          }
        />
        <Route
          path='new'
          element={
            <Page title={t('new', { ns: 'agency' })} subject='Agency' actions={['manage']}>
              <AgencyNew />
            </Page>
          }
        />
        <Route
          path=':id/edit'
          element={
            <Page
              title={t('edit', { ns: 'agency' })}
              subject='Agency'
              actions={['manage']}
             >
              <AgencyEdit />
            </Page>
          }
        />
      </Route>
    </Routes>
  )
}
