import { useState } from 'react'

/**
 * returns a function that wraps a function in a timeout
 */
export const useTimeoutWraper = () => {
  const [timeoutId, setTimeoutId] = useState(null)

  const wrapedFunction = (functionToWrap, milliseconds) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const newTimeoutId = setTimeout(functionToWrap, milliseconds)
    setTimeoutId(newTimeoutId)
  }
  return wrapedFunction
}
