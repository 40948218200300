import '../index.css'
import './PublicLayout.css'
import { Container } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function PublicLayout({ docTitle, children }) {
  const location = useLocation()

  useEffect(() => {
    document.title = docTitle
  }, [location])

  const theme = useTheme()
  return (
    <Container
      fluid='true'
      maxWidth='false'
      disableGutters={true}
      className='d-public-layout-container'
    >
      {children}
    </Container>
  )
}
