import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { Button, ButtonGroup, FormControl, Paper, TextField, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { crudConstPath } from '../../utils/enums/crudConst'

export const ChangePasswordForm = ({ user }) => {
  //
  /* CONSTANTS */
  const { sendRequest } = useBackendApi()
  const userChangePasswordUrl = crudConstPath.users.change_password(user.id)

  //
  /* HOOKS */
  const navigate = useNavigate()
  const { t } = useTranslation(['translation', 'user'])

  //
  /* METHODS */
  const handleChangePassword = async values => {
    try {
      await sendRequest(userChangePasswordUrl, 'patch', {}, {user: values})
      navigate('/users/')
    } catch (error) {
      notify(error.messages, severities.error)
    }
  }
  //
  /* RETURN */
  const initialValues = {
    password: '',
    password_confirmation: ''
  }

  const yupValidationsHash = {
    password: Yup.string()
      .required(t('password_required', { ns: 'user' }))
      .min(8, t('password_min', { ns: 'user' }))
      .matches(/^(?=.*[a-z])/, t('password_lowercase', { ns: 'user' }))
      .matches(/^(?=.*[A-Z])/, t('password_uppercase', { ns: 'user' }))
      .matches(/^(?=.*[0-9])/, t('password_number', { ns: 'user' })),
    password_confirmation: Yup.string()
      .required(t('password_required', { ns: 'user' }))
      .oneOf([Yup.ref('password'), null], t('password_match', { ns: 'user' }))
  }
  const yupValidations = Yup.object(yupValidationsHash)
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleChangePassword}
        validationSchema={yupValidations}
      >
        {({ errors, touched }) => (
          <Paper
            sx={{
              p: 1,
              mt: 2,
              mr: 2,
              flexDirection: 'column',
              gap: '10px',
              display: 'flex'
            }}
            elevation={3}
          >
            <Typography variant='h5' sx={{ color: 'secondary.dark', height: '25px', margin: 2 }}>
              { t('change_password', { ns: 'user' }) }
            </Typography>

            <Form sx={{ display: 'flex', gap: '10px' }}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  name='password'
                  id='password'
                  type='password'
                  label={t('password', { ns: 'user' })}
                  error={errors.password && touched.password}
                  helperText={errors.password && touched.password ? errors.password : ''}
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  name='password_confirmation'
                  type='password'
                  id='password_confirmation'
                  label={t('password_confirmation', { ns: 'user' })}
                  error={errors.password_confirmation && touched.password_confirmation}
                  helperText={errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : ''}
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>
              <ButtonGroup variant='outlined' aria-label='outlined button group' sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', gap: '10px' }}>
                <Button color='secondary' type='submit' variant='contained'>
                  { t('change_password', { ns: 'user' }) }
                </Button>
              </ButtonGroup>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  )
}

ChangePasswordForm.propTypes = {
  user: propTypes.object
}
