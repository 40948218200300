import '../../styles/map/MapLocationSearch.css'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import { Autocomplete } from '@react-google-maps/api'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export function MapLocationSearch({ setCenter }) {
  const { t } = useTranslation(['translation', 'adSpace'])
  const handlePlaceSelect = place => {
    const location = place.geometry.location
    setCenter({ lat: location.lat(), lng: location.lng() })
  }

  const handleAutocomplete = autocomplete => {
    autocomplete.addListener('place_changed', () => handlePlaceSelect(autocomplete.getPlace()))
  }

  return (
    <Autocomplete onLoad={handleAutocomplete} options={{ componentRestrictions: { country: 'CL' } }}>
      <Paper component='form' elevation={0} className='q-map-location-search' onSubmit={e => e.preventDefault()}>
        <InputBase
          className='search-input'
          placeholder={t('search_location', { ns: 'adSpace' })}
          inputProps={{ 'aria-label': 'search google maps' }}
          aria-label='search'
        />
      </Paper>
    </Autocomplete>
  )
}

MapLocationSearch.propTypes = {
  setCenter: propTypes.func.isRequired
}
