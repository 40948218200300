import { createPortal } from 'react-dom'
import { ToastContainer, Slide, useToastContainer } from 'react-toastify'

import '../../styles/NotificationContainer.css'
import 'react-toastify/dist/ReactToastify.css'

export function NotificationContainer({ children, ...props }) {
  const Container = (
    <ToastContainer
      position='top-center'
      transition={Slide}
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='colored'
    />
  )

  const { getToastToRender } = useToastContainer(Container)
  const notifications = getToastToRender()

  return notifications && createPortal(Container, document.getElementById('modal-root'))
}
