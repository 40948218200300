import { useDownloadQuotation } from '../../hooks/useDownloadQuotation'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Button } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const ExportQuotationToXLSXButton = ({ quotationId, filename }) => {
  const { t } = useTranslation(['translation', 'quotation'])
  const { getQuotationXLSX } = useDownloadQuotation(quotationId, filename, 'xlsx')

  const handleDownload = () => {
    return getQuotationXLSX()
  }
  return (
    <Button
    variant='outlined'
    startIcon={<FileDownloadIcon />}
    onClick={handleDownload}
    sx={{ mr: '8px' }}>
      {t('export_to_xlsx', { ns: 'quotation' })}
    </Button>
  )
}
ExportQuotationToXLSXButton.propTypes = {
  quotationId: propTypes.string.isRequired,
  filename: propTypes.string.isRequired
}

export default ExportQuotationToXLSXButton
