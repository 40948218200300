import { EasyTableRow } from './EasyTableRow'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LoadingBox } from './shared/LoadingBox'

export function EasyTable({ headers, data, actions, loading = false }) {
  const { t } = useTranslation(['translation'])
  const rowKeys = Object.keys(headers)
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {Object.values(headers).map(header => (
              <TableCell align='center' key={header}>
                {header}
              </TableCell>
            ))}
            {actions && <TableCell align='center'>{t('actions', { ns: 'translation' })}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? (
              <TableRow>
                <TableCell colSpan={Object.values(headers).length + (actions ? 1 : 0)}>
                  <LoadingBox />
                </TableCell>
              </TableRow>
            ) : data.length === 0
            ? (
              <TableRow>
                <TableCell colSpan={Object.values(headers).length + (actions ? 1 : 0)}>
                  <p> {t('no_rows_table', { ns: 'translation' })} </p>
                </TableCell>
              </TableRow>
              )
            : (
                data.map((dataElement, index) => {
                  return <EasyTableRow key={dataElement.id} data={dataElement} rowKeys={rowKeys} actions={actions} index={index} />
                })
              )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

EasyTable.propTypes = {
  headers: propTypes.object.isRequired,
  data: propTypes.array.isRequired,
  actions: propTypes.array
}
