import EventBusyIcon from '@mui/icons-material/EventBusy'
import { Popover, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function AdSpaceOccupanciesPopover({ key, occupancies }) {
  const { t } = useTranslation(['translation', 'adSpace'])
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePopoverClick = (event) => {
    event.stopPropagation()
  }

  useEffect(() => {
    const handleCloseOutside = (event) => {
      if (open && anchorEl && !anchorEl.contains(event.target)) {
        handleClose()
      }
    }
    document.addEventListener('click', handleCloseOutside, true)

    return () => {
      document.removeEventListener('click', handleCloseOutside, true)
    }
  }, [open, anchorEl])

  return (
    <>
      <EventBusyIcon
        color='warning'
        key={`${key}Icon`}
        className='occupancies-icon'
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      />
      <Popover
        id='mouse-over-popover'
        onClick={handlePopoverClick}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Typography variant='body2' component='div' key={`${key}Text`} px={2}>
          <dl>
            <dt>
              <b>{t('unavailable', { ns: 'adSpace' })}</b>
            </dt>
            {occupancies.map((occupancy) => (
              <li key={occupancy.id}>
                <b>{t('starts_at', { ns: 'translation' })}:</b> {occupancy.starts_at} |
                <b> {t('ends_at', { ns: 'translation' })}: </b> {occupancy.ends_at}
              </li>
            ))}
          </dl>
        </Typography>
      </Popover>
    </>
  )
}

AdSpaceOccupanciesPopover.propTypes = {
  key: PropTypes.string,
  occupancies: PropTypes.arrayOf(
    PropTypes.shape({
      starts_at: PropTypes.string,
      ends_at: PropTypes.string
    })
  )
}
