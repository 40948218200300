import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Field, Form, Formik, getIn } from 'formik'
import moment from 'moment-timezone'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'

import UploadImage from '../../components/Utils/Upload/Image'
import { countries } from '../../utils/countries'
import { crudConst } from '../../utils/enums/crudConst'
import { CustomRichField } from '../shared/CustomRichField'

import '../../styles/company/CompanyForm.scss'

const { create, update } = crudConst

export const CompanyForm = ({ submitAction, company }) => {
  //
  /* CONSTANTS */
  const action = company ? update : create

  //
  /* HOOKS */
  const navigate = useNavigate()
  const { t } = useTranslation(['translation', 'company', 'country', 'common'])

  //
  /* METHODS */
  const handleSubmit = (values) => {
    submitAction(values)
  }

  const handleGoBack = () => {
    navigate('/companies')
  }

  //
  /* RETURN */
  return (
    <>
      <Formik
        initialValues={{
          name: company?.name || '',
          corporate_name: company?.corporate_name || '',
          national_id: company?.national_id || '',
          trade: company?.trade || '',
          country_id: company?.country_id || '',
          address: company?.address || '',
          phone: company?.phone || '',
          email: company?.email || '',
          timezone: company?.timezone || moment.tz.guess(),
          description: company?.description || '',
          policies: company?.policies || '',
          additional_info: company?.additional_info || '',
          bank_owner: company?.bank_owner || '',
          bank_account_type: company?.bank_account_type || '',
          bank_account_number: company?.bank_account_number || '',
          bank_national_id: company?.bank_national_id || '',
          bank_name: company?.bank_name || '',
          bank_email: company?.bank_email || ''
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          name: Yup.string().required(t('name_required', { ns: 'company' })),
          corporate_name: Yup.string().required(t('corporate_name_required', { ns: 'company' })),
          national_id: Yup.string()
            .required(t('national_id_required', { ns: 'company' }))
            .matches(/\b[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[0-9kK]\b/, t('national_id_match', { ns: 'company' })),
          country_id: Yup.string().required(t('country_required', { ns: 'company' })),
          address: Yup.string().nullable(),
          trade: Yup.string().nullable(),
          phone: Yup.string().nullable(),
          description: Yup.string().nullable(),
          policies: Yup.string().nullable(),
          email: Yup.string()
            .email(t('email_invalid', { ns: 'company' }))
            .nullable(),
          bank_national_id: Yup.string()
            .matches(/\b[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[0-9kK]\b/, t('national_id_match', { ns: 'company' })),
          bank_name: Yup.string().nullable(),
          bank_email: Yup.string()
            .email(t('email_invalid', { ns: 'company' }))
            .nullable(),
          additional_info: Yup.string().nullable(),
          bank_owner: Yup.string().nullable(),
          bank_account_type: Yup.string().nullable(),
          bank_account_number: Yup.string().nullable()
        })}>
        {({ errors, touched, setFieldValue }) => (
          <Paper
            sx={{
              p: 1,
              mt: 2,
              mr: 2,
              flexDirection: 'column',
              gap: '10px',
              display: 'flex'
            }}
            elevation={3}>
            <Typography variant='h5' sx={{ color: 'secondary.dark', height: '25px', margin: 2 }}>
              {action === create ? t('new', { ns: 'company' }) : t('edit', { ns: 'company' })}
            </Typography>

            <Form sx={{ display: 'flex', gap: '10px' }}>
              <Typography variant='h6' className='companySeparator'>
                {t('main_data', { ns: 'company' })}
              </Typography>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  name='name'
                  id='name'
                  label={t('name', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                  name='email'
                  type='email'
                  id='email'
                  label={t('email', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <FormControl
                sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}
                error={errors.country_id && touched.country_id}>

                <InputLabel required htmlFor='country_id'>
                  {t('country', { ns: 'company' })}
                </InputLabel>
                <div style={{ flex: 1 }}>
                  <Field
                    as={Select}
                    name='country_id'
                    id='country_id'
                    label={t('country', { ns: 'company' })}
                    sx={{ flex: 1 }}
                    size='small'
                    fullWidth
                    error={errors.country_id && touched.country_id}
                    helperText={errors.country_id && touched.country_id ? errors.country_id : ''}>
                    <MenuItem value=''>{t('select', { ns: 'country' })}</MenuItem> {/* Opción vacía */}
                    {countries.map((option, index) => {
                      const { uuid, name } = option

                      return (
                        <MenuItem key={index} value={uuid}>
                          {name}
                        </MenuItem>
                      )
                    })}
                  </Field>

                  {errors.country_id && touched.country_id && (
                    <FormHelperText error>{errors.country_id}</FormHelperText>
                  )}
                </div>

                <Field
                  as={Autocomplete}
                  disablePortal
                  name='timezone'
                  id='timezone'
                  label={t('timezone', { ns: 'commons' })}
                  sx={{ flex: 1 }}
                  size='small'
                  fullWidth
                  options={moment.tz.names()}
                  renderInput={(params) => <TextField {...params} label={t('timezone', { ns: 'commons' })} />}
                />
              </FormControl>

              <FormControl className='companyFormFormControl'>
                <div className='companyFormFieldContainer'>
                  <Field name='logo'>
                    {({ field }) => {
                      return (
                        <UploadImage
                          {...field}
                          modelType='logo'
                          label={t('logoLabel', { ns: 'company' })}
                          imagePreviewSize={{ width: '270px', height: '90px' }}
                          accept='image/jpg, image/png, image/jpeg'
                          setFieldValue={setFieldValue}
                          imageUrl={getIn(company, 'logo.file_url')}
                          fileName={getIn(company, 'logo.filename')}
                          onChange={(images) => setFieldValue('logo', images)}
                          error={getIn(errors, 'logo')}
                          touched={getIn(touched, 'logo')}
                        />
                      )
                    }}
                  </Field>
                </div>
                <div className='companyFormFieldContainer'>
                  <Field name='banner'>
                    {({ field }) => {
                      return (
                        <UploadImage
                          {...field}
                          modelType='banner'
                          label={t('bannerLabel', { ns: 'company' })}
                          imagePreviewSize={{ width: '300px', height: '230px' }}
                          accept='image/jpg, image/png, image/jpeg'
                          setFieldValue={setFieldValue}
                          imageUrl={getIn(company, 'banner.file_url')}
                          fileName={getIn(company, 'banner.filename')}
                          onChange={(images) => setFieldValue('banner', images)}
                          error={getIn(errors, 'banner')}
                          touched={getIn(touched, 'banner')}
                        />
                      )
                    }}
                  </Field>
                </div>
              </FormControl>

              <Typography variant='h6' className='companySeparator'>
                {t('description', { ns: 'company' })}
              </Typography>
              <CustomRichField
                setFieldValue={setFieldValue}
                content={company?.description}
                name='description'
              />

              <Typography variant='h6' className='companySeparator'>
              {t('policies', { ns: 'company' })}
              </Typography>
              <CustomRichField
                setFieldValue={setFieldValue}
                content={company?.policies}
                name='policies'
                size='md'
              />

              <Typography variant='h6' className='companySeparator'>
                {t('billing_info', { ns: 'company' })}
              </Typography>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  error={errors.corporate_name && touched.corporate_name}
                  helperText={errors.corporate_name && touched.corporate_name ? errors.corporate_name : ''}
                  name='corporate_name'
                  id='corporate_name'
                  label={t('corporate_name', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  name='address'
                  id='address'
                  label={t('address', { ns: 'company' })}
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field name='national_id'>
                  {({ field }) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9k]/]}
                      guide={false}
                      render={(ref, inputProps) => (
                        <TextField
                          {...inputProps}
                          label={t('national_id', { ns: 'company' })}
                          error={errors.national_id && touched.national_id}
                          helperText={errors.national_id && touched.national_id ? errors.national_id : ''}
                          id='national_id'
                          size='small'
                          fullWidth
                          sx={{ flex: 1 }}
                          inputRef={ref}
                        />
                      )}
                    />
                  )}
                </Field>
                <Field
                  as={TextField}
                  name='phone'
                  id='phone'
                  label={t('phone', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  name='trade'
                  id='trade'
                  label={t('trade', { ns: 'company' })}
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  name='additional_info'
                  id='additional_info'
                  label={t('additional_info', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <Typography variant='h6' className='companySeparator'>
                {t('transaction_info', { ns: 'company' })}
              </Typography>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  name='bank_owner'
                  id='bank_owner'
                  label={t('bank_owner', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  name='bank_account_type'
                  id='bank_account_type'
                  label={t('bank_account_type', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field name='bank_national_id'>
                  {({ field }) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9k]/]}
                      guide={false}
                      render={(ref, inputProps) => (
                        <TextField
                          {...inputProps}
                          label={t('national_id', { ns: 'company' })}
                          error={errors.bank_national_id && touched.bank_national_id}
                          helperText={errors.bank_national_id && touched.bank_national_id ? errors.bank_national_id : ''}
                          id='bank_national_id'
                          size='small'
                          fullWidth
                          sx={{ flex: 1 }}
                          inputRef={ref}
                        />
                      )}
                    />
                  )}
                </Field>
                <Field
                  as={TextField}
                  name='bank_account_number'
                  id='bank_account_number'
                  label={t('bank_account_number', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  name='bank_name'
                  id='bank_name'
                  label={t('bank_name', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  name='bank_email'
                  id='bank_email'
                  label={t('email', { ns: 'company' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <ButtonGroup variant='outlined' aria-label='outlined button group' className='companyFormButtonGroup'>
                <Button color='primary' type='button' variant='contained' onClick={handleGoBack}>
                  {t('back_to_list', { ns: 'company' })}
                </Button>
                <Button color='secondary' type='submit' variant='contained'>
                  {action === update ? t('save', { ns: 'translation' }) : t('create', { ns: 'company' })}
                </Button>
              </ButtonGroup>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  )
}

CompanyForm.propTypes = {
  submitAction: propTypes.func,
  company: propTypes.object.isRequired
}
