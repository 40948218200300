import { useQuotationTabActions } from '../../hooks/actions/useQuotationTabActions'
import { useAppSelector } from '../../hooks/store'
import { useAuth } from '../../hooks/useAuth'
import '../../styles/navbar/UserMenu.css'
import { quotationTabTypes } from '../../utils/enums/quotationTabTypes'
import { AccountCircle } from '@mui/icons-material'
import LogoutIcon from '@mui/icons-material/Logout'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Badge } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useVisitModeActions } from '../../hooks/actions/useVisitModeAction'
import useBackendApi from '../../hooks/useBackendApi'
import Cookies from 'js-cookie'
import { updateVisitMode } from '../../utils/updateVisitMode'
import { crudConstPath } from '../../utils/enums/crudConst'

export default function UserMenu() {
  // HOOKS
  const { t } = useTranslation(['translation', 'user'])
  const { logout, user } = useAuth()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const userButtonRef = useRef(null)
  const badgeRef = useRef(null)
  const bids = useAppSelector((state) => state.bidsCart.bids)
  const currentTab = useAppSelector((state) => state.bidsCart.currentTab)
  const isActiveVisitMode = Cookies.get('visitMode') === 'true'
  const bidsCounter = bids.filter((bid) => !bid._destroy).length
  const { setCurrentQuotationTab } = useQuotationTabActions()
  const { setActiveVisitMode } = useVisitModeActions()
  setActiveVisitMode(isActiveVisitMode)

  const url = crudConstPath.users.preferences(user?.userId)
  const { sendRequest } = useBackendApi()

  // METHODS
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleVisitView = (active) => {
    const visit_mode_params = { user_preference: { visit_mode: !active } }
    updateVisitMode(visit_mode_params, url, sendRequest)
    setActiveVisitMode(!active)
    Cookies.set('visitMode', !active, {
      secure: true,
      sameSite: 'strict'
    })
  }

  useEffect(() => {
    if (isMenuOpen) {
      setAnchorEl(userButtonRef.current)
    } else {
      setAnchorEl(null)
    }
  }, [isMenuOpen])

  useEffect(() => {
    if (badgeRef === null && bidsCounter === 0) return

    badgeRef.current?.classList.add('tada')
    setTimeout(() => {
      badgeRef.current?.classList.remove('tada')
    }, 1000)
  }, [bidsCounter])

  // RETURN
  return (
    <div>
      <IconButton
        ref={userButtonRef}
        size='large'
        aria-label='account of current user'
        aria-controls='user-menu-appbar'
        aria-haspopup='true'
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={() => setCurrentQuotationTab(quotationTabTypes.cart_tab_open)}
        color={currentTab === quotationTabTypes.cart_tab_open ? 'warning' : 'inherit'}>
        <Badge badgeContent={bidsCounter} color='secondary' ref={badgeRef}>
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
      <IconButton
        ref={userButtonRef}
        size='large'
        aria-label='account of current user'
        aria-controls='user-menu-appbar'
        aria-haspopup='true'
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={toggleMenu}
        color='inherit'>
        <AccountCircle />
      </IconButton>
      <Menu
        id='user-menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={toggleMenu}>
        <MenuItem onClick={toggleMenu}>{user?.name}</MenuItem>
        <MenuItem onClick={toggleMenu}>{user?.email}</MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>

          <ListItemText>{t('logout', { ns: 'user' })}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleVisitView(isActiveVisitMode)}>
          <ListItemIcon>
            {
              isActiveVisitMode ?
              <VisibilityOffIcon fontSize='small' /> :
              <VisibilityIcon fontSize='small' />
            }
          </ListItemIcon>

          <ListItemText>{t('visit_mode', { ns: 'user' })}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}
