import '../../../styles/utils/Image.css'
import { Button, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
const supportedImagaFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']

const UploadImage = (props) => {
  const { imageUrl, fileName, error, touched, label, abbr, imagePreviewSize, modelType = 'default' } = props
  const { t } = useTranslation('commons');
  const [url, setUrl] = useState('')
  const [name, setName] = useState(t('no_image'))
  const theme = useTheme();

  const getSuggestedSize = (type) => {
    return t(`commons:suggested_sizes.${type}`) || t('commons:unspecifiedSize');
  }

  const _handleImageChange = (e) => {
    const { onChange } = props
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      setUrl(reader.result)
      setName(file.name)
    }
    reader.readAsDataURL(file)
    onChange(file)
  }

  const fileInputRef = useRef(null)

  const handleButtonClick = (event) => {
    event.preventDefault()
    fileInputRef.current.click()
  }

  useEffect(() => {
    if (fileName) setName(fileName)
  }, [fileName])

  return (
    <div>
      {label ? (
        <Typography>
          {label}
          {abbr && <abbr style={{ color: theme.palette.error.main }}>*</abbr>}
        </Typography>
      ) : (
        false
      )}
      <div style={{ ...imagePreviewSize }} className='custom-file-img'>
        <img style={{ ...imagePreviewSize, width: '100%' }} src={url || imageUrl} alt="Preview" />
      </div>
      <div className='input-group'>
        <div className='custom-file'>
          <input
            type='file'
            id='inputGroupFile01'
            aria-describedby='inputGroupFileAddon01'
            onChange={(e) => _handleImageChange(e)}
            accept={supportedImagaFormats}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <Button color='primary' type='button' variant='contained' size='small' onClick={handleButtonClick} style={{ marginRight: '10px'}}>
            {t('choose_file')}
          </Button>
          <label htmlFor='inputGroupFile01'>{name}</label>
        </div>
      </div>
      <p style={{ fontSize: '11px', fontStyle: 'italic', marginBottom: '10px' }}>
        {t('suggested_size', { size: getSuggestedSize(modelType, t) })}
      </p>
      {error && touched && <p className='text-danger my-3'>{error}</p>}
    </div>
  )
}

UploadImage.defaultProps = {
  onChange: () => {}
}

export default UploadImage
