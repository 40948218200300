import { calculateSumByAttribute } from "./calculateSumByAttribute"

export const getTotalPrices = (state) => {
  const bids = state.bids
  const totalLeasingPrice = bids.reduce((sum, bid) => sum + bid.total_leasing_price, 0)
  const totalProductionPrice = calculateSumByAttribute(bids, 'production_price')
  const totalInstallationPrice = calculateSumByAttribute(bids, 'installation_price')
  const totalSumPrice = totalLeasingPrice + totalProductionPrice + totalInstallationPrice
  return { totalLeasingPrice, totalProductionPrice, totalInstallationPrice, totalSumPrice }
}
