import propTypes from 'prop-types'

import { useAppSelector } from '../../hooks/store'
import { quotationTabTypes } from '../../utils/enums/quotationTabTypes'
import { AdSpacesIndex } from '../adSpaces/AdSpacesIndex'
import { QuotationMap } from '../map/QuotationMap'
import { ConditionalContent } from '../shared/ConditionalContent'
import { QuotationForm } from './QuotationForm'

export const QuotationMainContent = ({ submitAction, quotation, advertisers, agencies, industries }) => {
  const currentQuotationTab = useAppSelector((state) => state.quotationTab.currentTab)
  const isCatalogTabOpen = quotationTabTypes.catalog_tab_open === currentQuotationTab

  return (
    <>
      <ConditionalContent condition={quotationTabTypes.map_tab_open === currentQuotationTab}>
        <QuotationMap />
      </ConditionalContent>

      <ConditionalContent condition={isCatalogTabOpen}>
        <AdSpacesIndex isTabActive={isCatalogTabOpen} />
      </ConditionalContent>

      <ConditionalContent condition={quotationTabTypes.cart_tab_open === currentQuotationTab}>
        <QuotationForm submitAction={submitAction} quotation={quotation} advertisers={advertisers} agencies={agencies} industries={industries} />
      </ConditionalContent>
    </>
  )
}

QuotationMainContent.propTypes = {
  submitAction: propTypes.func.isRequired,
  quotation: propTypes.shape({
    uuid: propTypes.string,
    title: propTypes.string,
    bids: propTypes.array
  }),
  advertisers: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    })
  ),
  agencies: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    })
  ),
  industries: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    })
  )
}