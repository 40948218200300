import { MoreVertSharp } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import propTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { useAppSelector } from '../../hooks/store'
import useMapFilters from '../../hooks/useMapFilters'
import { quotationTabTypes } from '../../utils/enums/quotationTabTypes'
import { formats, selectOptionFormats } from '../../utils/formats'
import { ConditionalContent } from '../shared/ConditionalContent'
import { DateRangePicker } from './mapFilterInputs/DateRangePicker'
import { FormatInput } from './mapFilterInputs/FormatInput'
import { LocalityFilterInput } from './mapFilterInputs/LocalityFilterInput'
import { ProviderInput } from './mapFilterInputs/ProviderInput'
import { QueryFilterInput } from './mapFilterInputs/QueryFilterInput'
import { RegionFilterInput } from './mapFilterInputs/RegionFilterInput'
import { ShowUnavailableCheckbox } from './mapFilterInputs/ShowUnavailableCheckbox'

import '../../styles/map/MapFilters.css'

const emptyFilters = {
  availability_start_date: null,
  availability_end_date: null,
  format_ids: [],
  provider_ids: [],
  locality_ids: [],
  region_ids: [],
  query_filter: {
    text: '',
    exact_match: false
  },
  show_unavailable: false
}

const MapFilters = ({ providers }) => {
  const { t } = useTranslation(['translation', 'quotation'])
  const currentQuotationTab = useAppSelector((state) => state.quotationTab.currentTab)
  const isNotCartTab = currentQuotationTab !== quotationTabTypes.cart_tab_open
  const isCatalogTab = currentQuotationTab === quotationTabTypes.catalog_tab_open

  const {
    regions,
    localities,
    showMoreFilters,
    toggleMoreFilters,
    handleSubmit,
    clearFilters,
    isFilterEmpty,
    setRegionsIds
  } = useMapFilters(emptyFilters)

  //
  /* RETURN */
  return (
    <ConditionalContent condition={isNotCartTab}>
      <Formik
        initialValues={{
          format_ids: [],
          availability_start_date: null,
          availability_end_date: null,
          provider_ids: [],
          region_ids: [],
          locality_ids: [],
          query_filter: { text: '', exact_match: false },
          show_unavailable: false
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          format_ids: Yup.array(),
          availability_start_date: Yup.date().nullable(),
          availability_end_date: Yup.date().nullable(),
          availability: Yup.array(),
          min_price: Yup.number(),
          max_price: Yup.number(),
          provider_ids: Yup.array(),
          locality_ids: Yup.array(),
          region_ids: Yup.array(),
          query_filter: Yup.object({
            text: Yup.string(),
            exact_match: Yup.boolean()
          }),
          show_unavailable: Yup.boolean()
        })}>
        {({ values, setFieldValue, resetForm }) => {
          return (
            <Form className='quotation-filter-container'>
              <FormatInput values={values.format_ids} formats={selectOptionFormats} setFieldValue={setFieldValue} />
              <DateRangePicker values={values} setFieldValue={setFieldValue} />
              <ProviderInput values={values.provider_ids} providers={providers} setFieldValue={setFieldValue} />
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                className='quotation-filter-button first-button'>
                {t('filter', { ns: 'quotation' })}
              </Button>
              <Button
                onClick={() => clearFilters(resetForm)}
                type='button'
                variant='contained'
                disabled={isFilterEmpty()}
                className='quotation-filter-button clean-filter-button'>
                {t('clear_filters', { ns: 'quotation' })}
              </Button>
              <div className={`translucent-background ${showMoreFilters ? 'show' : ''}`} onClick={toggleMoreFilters} />
              <div className={`parent-more-filters-container ${showMoreFilters ? 'show' : ''}`}>
                <Button
                  type='button'
                  color='secondary'
                  variant='outlined'
                  onClick={toggleMoreFilters}
                  className='quotation-filter-button show-more-button'>
                  <MoreVertSharp />
                  {showMoreFilters ? t('less_filters', { ns: 'quotation' }) : t('more_filters', { ns: 'quotation' })}
                </Button>
                <div className='more-filters-container'>
                  <Typography noWrap variant='h6' className='ad-space-price'>
                    {t('catalog_filters', { ns: 'quotation' })}
                  </Typography>
                  <QueryFilterInput values={values} formats={formats} setFieldValue={setFieldValue} />
                  <ConditionalContent condition={isCatalogTab}>
                    <RegionFilterInput
                      values={values}
                      formats={formats}
                      setFieldValue={setFieldValue}
                      regions={regions}
                      setRegionsIds={setRegionsIds}
                    />
                    <LocalityFilterInput
                      values={values}
                      formats={formats}
                      setFieldValue={setFieldValue}
                      localities={localities}
                    />
                  </ConditionalContent>
                  {/* TODO cuando se corrija la redefinicion de values, enviar start y end dates en vez de values para validar */}
                  <ShowUnavailableCheckbox values={values} setFieldValue={setFieldValue} />
                  <Button
                    type='submit'
                    color='secondary'
                    variant='contained'
                    className='quotation-filter-button first-button'>
                    {t('filter', { ns: 'quotation' })}
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </ConditionalContent>
  )
}

export default React.memo(MapFilters)

MapFilters.propTypes = {
  providers: propTypes.array
}
