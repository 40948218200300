import { ListItem, Paper } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Box } from '@mui/system'

export function AdSpaceListLoading() {
  return [...Array(5)].map((_, index) => (
    <ListItem
      key={index}
      alignItems='flex-start'
      sx={{
        p: 0,
        display: 'flex'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '140px',
          padding: '10px',
          borderRadius: '15px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '10px'
        }}
      >
        <Paper
          component={'div'}
          sx={{
            flex: 2,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            backgroundColor: 'transparent'
          }}
        >
          <Skeleton animation='wave' variant='rectangular' width={'100%'} height={'100%'} />
        </Paper>

        <Paper
          component={'div'}
          elevation={0}
          sx={{
            flex: 3,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            backgroundColor: 'transparent'
          }}
        >
          <Skeleton animation='wave' variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton animation='wave' variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton animation='wave' variant='text' sx={{ fontSize: '1rem' }} />
          <Skeleton animation='wave' variant='text' sx={{ fontSize: '1rem' }} />
        </Paper>
      </Box>
    </ListItem>
  ))
}
