import { useJsApiLoader } from '@react-google-maps/api'
import { useLayoutEffect, useRef, useState } from 'react'

import { useQuotationMapMarkersFetcher } from '../../hooks/useQuotationMapMarkersFetcher'
import { useTimeoutWraper } from '../../hooks/useTimeoutWraper'
import { detailsVariants as variants } from '../../utils/detailsVariants'
import { markersIdsInBounds } from '../../utils/geos/mapUtils'
import { libraries } from '../../utils/mapVariables'
import { AdSpacesList } from '../adSpaces/AdSpacesList'
import { Map } from './Map'

import '../../styles/Quotations.scss'

export function QuotationMap() {
  const [activeMarkerUuid, setActiveMarkerUuid] = useState({ uuid: null, active: false })
  const [viewPortMarkersIds, setViewPortMarkersIds] = useState([])
  const [height, setHeight] = useState(0)

  const mapRef = useRef(null)
  const { loadedMarkers, loading: isLoadingMarkers } = useQuotationMapMarkersFetcher()

  // height is used to avoid scrolling ad-spaces list beyond map
  // must be set when mapTabIsOpen for mapRef to be able
  const handleHeight = () => {
    setHeight(mapRef.current?.clientHeight)
  }

  useLayoutEffect(() => {
    handleHeight()
    window.addEventListener('resize', handleHeight)

    return () => {
      window.removeEventListener('resize', handleHeight)
    }
  }, [mapRef.current?.clientHeight])

  const timeoutWrapper = useTimeoutWraper()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  const handleOnBoundsChanged = (bounds) => {
    timeoutWrapper(() => {
      if (bounds && loadedMarkers) {
        const markerIds = markersIdsInBounds(loadedMarkers, bounds)
        setViewPortMarkersIds(markerIds)
      }
    }, 1000)
  }

  return (
    <>
      <div className={'quotation-map-and-adspace-list '} ref={mapRef}>
        {isLoaded ? (
          <Map
            className={'q-map show'}
            markers={loadedMarkers}
            variant={variants.withAddToCartButton}
            activeMarkerUuid={activeMarkerUuid}
            isLoadingMarkers={isLoadingMarkers}
            onBoundsChanged={handleOnBoundsChanged}
          />
        ) : null}

        <AdSpacesList
          className={'ad-spaces-list'}
          adSpacesIds={viewPortMarkersIds}
          height={height}
          onMouseEnter={(uuid) => setActiveMarkerUuid({ uuid, active: true })}
          onMouseLeave={(uuid) => setActiveMarkerUuid({ uuid, active: false })}
        />
      </div>
    </>
  )
}
