import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { AdvertiserForm } from '../../components/advertisers/AdvertiserForm'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export function AdvertiserEdit() {
  //
  /* CONSTANTS */
  const { id } = useParams()
  const advertiserUpdateUrl = crudConstPath.advertiser.update(id)
  const advertiserEditUrl = crudConstPath.advertiser.edit(id)

  //
  /* HOOKS */
  const { t } = useTranslation(['advertiser'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()

  useEffect(() => {
    sendRequest(advertiserEditUrl, crudConstNames.get)
  }, [])

  const advertiser = data?.advertiser
  const industries = data?.industries || []
  //
  /* METHODS */
  const handleCreate = (values) => {
    const advertiserData = { advertiser: values }
    sendRequest(advertiserUpdateUrl, crudConstNames.put, {}, advertiserData).then((response) => {
      if (response?.success) {
        notify(t('updated', { ns: 'advertiser' }), severities.success)
        navigate('/advertisers')
      }
    })
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return advertiser ? <AdvertiserForm onSubmit={handleCreate} industries={industries} advertiser={advertiser} /> : null
}
