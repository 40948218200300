import { crudConst } from '../../utils/enums/crudConst'
import { Button, ButtonGroup, Typography, Paper } from '@mui/material'
import { Formik, Form } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AdvertiserFormFields } from './AdvertiserFormFields'
import * as Yup from 'yup'

const { create, update } = crudConst

export const AdvertiserForm = ({ onSubmit, advertiser, industries }) => {
  //
  /* CONSTANTS */
  const action = advertiser ? update : create
  //
  /* HOOKS */
  const navigate = useNavigate()
  const { t } = useTranslation(['translation', 'advertiser'])

  //
  /* METHODS */
  const handleSubmit = (values) => {
    onSubmit(values)
  }

  const handleGoBack = () => {
    navigate('/advertisers')
  }

  const initialValues = {
    name: advertiser?.name || '',
    corporate_name: advertiser?.corporate_name || '',
    national_id: advertiser?.national_id || '',
    trade_name: advertiser?.trade_name || '',
    address: advertiser?.address || '',
    email: advertiser?.email || '',
    industry_id: advertiser?.industry_id || ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('name_required', { ns: 'advertiser' })),
    corporate_name: Yup.string().nullable(),
    national_id: Yup.string()
      .required(t('national_id_required', { ns: 'advertiser' }))
      .matches(/\b[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[0-9kK]\b/, t('national_id_match', { ns: 'advertiser' })),
    address: Yup.string().nullable(),
    trade_name: Yup.string().nullable(),
    email: Yup.string()
      .email(t('email_invalid', { ns: 'advertiser' }))
      .nullable(),
    industry_id: Yup.string()
  });

  //
  /* RETURN */
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Paper
            sx={{
              p: 1,
              mt: 2,
              mr: 2,
              flexDirection: 'column',
              gap: '10px',
              display: 'flex'
            }}
            elevation={3}>
            <Typography variant='h5' sx={{ color: 'secondary.dark', height: '25px', margin: 2 }}>
              {action === create ? t('new', { ns: 'advertiser' }) : t('edit', { ns: 'advertiser' })}
            </Typography>

            <Form sx={{ display: 'flex', gap: '10px' }}>
              <AdvertiserFormFields
                errors={errors}
                touched={touched}
                translated={t}
                industries={industries}
              />

              <ButtonGroup
                variant='outlined'
                aria-label='outlined button group'
                sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', gap: '10px' }}>
                <Button color='primary' type='button' variant='contained' onClick={handleGoBack}>
                  {t('back_to_list', { ns: 'advertiser' })}
                </Button>
                <Button color='secondary' type='submit' variant='contained'>
                  {action === update ? t('save', { ns: 'translation' }) : t('create', { ns: 'advertiser' })}
                </Button>
              </ButtonGroup>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  )
}

AdvertiserForm.propTypes = {
  submitAction: propTypes.func,
  advertiser: propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
    corporate_name: propTypes.string,
    national_id: propTypes.string,
    trade_name: propTypes.string,
    address: propTypes.string,
    email: propTypes.string,
    industry_id: propTypes.number
  }),
  industries: propTypes.array.isRequired
}
