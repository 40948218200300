export const bidPricesTypes = {
  leasing: "leasing_price",
  installation: "installation_price",
  production: "production_price",
  total: "total_price",
  total_leasing: "totalLeasingPrice",
  total_installation: "totalInstallationPrice",
  total_production: "totalProductionPrice",
  base_leasing_price: "ad_space_leasing_price",
  base_production_price: "ad_space_production_price",
  base_installation_price: "ad_space_installation_price"
}