import { ProtectedRoute } from '../components/auth/ProtectedRoute'
import { Navbar } from '../components/navbar/Navbar'
import { NotificationContainer } from '../components/notifications/NotificationContainer'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { useCheckPermissions } from '../utils/useCheckPermissions'
import propTypes from 'prop-types'

export function Page({ title, children, actions, subject, placeholder = null }) {
  const hasPermission = useCheckPermissions(actions, subject)
  // HOOKS
  // METHODS
  // RETURN
  return (
    <DefaultLayout docTitle={title}>
      <Navbar title={title} />
      <NotificationContainer />
      <ProtectedRoute>
        {hasPermission ? children : placeholder}
      </ProtectedRoute>
    </DefaultLayout>
  )
}

Page.propTypes = {
  title: propTypes.string,
  children: propTypes.object,
  actions: propTypes.array.isRequired,
  subject: propTypes.string.isRequired,
  placeholder: propTypes.object
}
