import { Paper } from "@mui/material";

// CONSTANTS
const ITEM_HEIGHT = 48

export const FormPaper = (props) => {
  return <Paper
    sx={{
      maxHeight: ITEM_HEIGHT * 10,
      minHeight: ITEM_HEIGHT * 5,
      minWidth: '200px',
      'ul': {
        padding: '0px'
      },
      "& .MuiAutocomplete-listbox": {
        "& .MuiAutocomplete-option[aria-selected='true']": {
          bgcolor: "#269982"
        },
        "& .MuiAutocomplete-option[aria-selected='true']:hover": {
          bgcolor: "#269982",
          opacity: "0.9"
        }
      }
    }}
    {...props} />;
}