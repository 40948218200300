import { FormControl, TextField } from '@mui/material'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

export const AgencyFormFields = ({ errors, touched, translated }) => (
  <>
    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        error={errors.name && touched.name}
        helperText={errors.name && touched.name ? errors.name : ''}
        name='name'
        id='name'
        label={translated('name', { ns: 'agency' })}
        fullWidth
        sx={{ flex: 1 }}
        size='small'
      />
      <Field name='national_id'>
        {({ field }) => (
          <MaskedInput
            {...field}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9k]/]}
            guide={false}
            render={(ref, inputProps) => (
              <TextField
                {...inputProps}
                label={translated('national_id', { ns: 'agency' })}
                error={errors.national_id && touched.national_id}
                helperText={errors.national_id && touched.national_id ? errors.national_id : ''}
                id='national_id'
                size='small'
                fullWidth
                sx={{ flex: 1 }}
                inputRef={ref}
              />
            )}
          />
        )}
      </Field>
    </FormControl>

    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        error={errors.corporate_name && touched.corporate_name}
        helperText={errors.corporate_name && touched.corporate_name ? errors.corporate_name : ''}
        name='corporate_name'
        id='corporate_name'
        label={translated('corporate_name', { ns: 'agency' })}
        fullWidth
        sx={{ flex: 1 }}
        size='small'
      />

      <Field
        as={TextField}
        name='trade_name'
        id='trade_name'
        label={translated('trade_name', { ns: 'agency' })}
        sx={{ flex: 1 }}
        size='small'
      />
    </FormControl>

    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
      <Field
        as={TextField}
        error={errors.email && touched.email}
        helperText={errors.email && touched.email ? errors.email : ''}
        name='email'
        type='email'
        id='email'
        label={translated('email', { ns: 'agency' })}
        fullWidth
        sx={{ flex: 1 }}
        size='small'
      />

      <Field
        as={TextField}
        name='address'
        id='address'
        label={translated('address', { ns: 'agency' })}
        sx={{ flex: 1 }}
        size='small'
      />
    </FormControl>
  </>
)

AgencyFormFields.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  translated: PropTypes.func.isRequired
};