import { useState, useEffect } from 'react'

import { isEmptyObjectDeep } from '../utils/object/isEmptyObject'
import { useMapFilterActions } from './actions/useMapFilterActions'
import { useAppSelector } from './store'
import useBackendApi from './useBackendApi'
import { crudConstNames, crudConstPath } from '../utils/enums/crudConst'

const useMapFilters = (emptyFilters) => {
  const { setAdSpacesFilterState, clearMapFilterState } = useMapFilterActions()
  const mapFilters = useAppSelector((state) => state.mapFilter.adSpacesFilter)
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [regionsIds, setRegionsIds] = useState([])
  const [regions, setRegions] = useState([])
  const [localities, setLocalities] = useState([])
  const backendApi = useBackendApi()

  const fetchRegions = async () => {
    const regionsResponse = await backendApi.sendRequest(crudConstPath.regions, crudConstNames.get)
    return regionsResponse
  }

  const fetchLocalities = async (regionsIds) => {
    const queryString = regionsIds.length > 0 ? `?region_ids=${regionsIds.join(',')}` : ''
    const localitiesResponse = await backendApi.sendRequest(
      `${crudConstPath.localities}${queryString}`,
      crudConstNames.get
    )
    return localitiesResponse
  }

  //
  /* METHODS */
  const handleSubmit = (values) => {
    const storeValues = { ...values }
    storeValues.availability_start_date = values.availability_start_date?.format('YYYY-MM-DD')
    storeValues.availability_end_date = values.availability_end_date?.format('YYYY-MM-DD')
    setAdSpacesFilterState(storeValues)

    setShowMoreFilters(false)
  }

  const toggleMoreFilters = () => {
    setShowMoreFilters((prev) => !prev)
  }

  const clearFilters = (resetForm) => {
    resetForm({ values: emptyFilters })
    setShowMoreFilters(false)
    clearMapFilterState()
  }

  function isFilterEmpty() {
    return isEmptyObjectDeep(mapFilters)
  }

  useEffect(() => {
    const loadRegions = async () => {
      const regionsData = await fetchRegions()
      setRegions(regionsData.data)
    }

    const loadLocalities = async () => {
      const localitiesData = await fetchLocalities(regionsIds)
      setLocalities(localitiesData.data)
    }

    // loadRegions()
    loadLocalities()
  }, [regionsIds])
  useEffect(() => {
    const loadRegions = async () => {
      const regionsData = await fetchRegions()
      setRegions(regionsData.data)
    }

    loadRegions()
  }, [])

  return {
    regions,
    localities,
    showMoreFilters,
    toggleMoreFilters,
    handleSubmit,
    clearFilters,
    isFilterEmpty,
    setRegionsIds
  }
}

export default useMapFilters
