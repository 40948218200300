import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Quotation } from '../../components/quotations/Quotation'
import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'
import { useMapFilterActions } from '../../hooks/actions/useMapFilterActions'
import useBackendApi from '../../hooks/useBackendApi'
import { crudConst, crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { notifySuccess } from '../../utils/notifications/notify'

import '../../styles/Quotations.scss'

const { create } = crudConst
const quotationsNewUrl = crudConstPath.quotations.new
const quotationsCreateUrl = crudConstPath.quotations.create
const getQuotation = (sendRequest) => sendRequest(quotationsNewUrl, crudConstNames.get)
const createQuotation = async (newQuotationParams, sendRequest) =>
  await sendRequest(quotationsCreateUrl, crudConstNames.post, {}, newQuotationParams)

export function QuotationNew() {
  const { t } = useTranslation(['translation', 'quotation'])
  const { data, loading, sendRequest } = useBackendApi()
  const navigate = useNavigate()
  const { clearMapFilterState } = useMapFilterActions()
  const { clearBidsInCart, setPackagePricingInCart } = useBidsCartActions()

  useEffect(() => {
    clearBidsInCart()
    setPackagePricingInCart(false)
    clearMapFilterState()
    getQuotation(sendRequest)
  }, [])

  const handleCreate = async (values, packagePricing, bidsCart, isSaleRequested = false) => {
    const newQuotationParams = {
      ...values,
      package_pricing: packagePricing,
      bids_attributes: bidsCart.bids,
      total_installation_price: bidsCart.totalInstallationPrice,
      total_leasing_price: bidsCart.totalLeasingPrice,
      total_production_price: bidsCart.totalProductionPrice,
      total_price: bidsCart.totalSumPrice
    }

    // TODO:  hacer que createQuotation use otro SendRequest ya que al llamarlo se debe volver a llamar el
    // getQuotation, ya que usan la misma variable "data" para almacenar los resultados
    // refactorizar en todos los formularios con este problema
    const response = await createQuotation(newQuotationParams, sendRequest)
    if (response) {
      notifySuccess(t('created', { ns: 'quotation' }))
      navigate(`/quotations/${response.quotation.id}/edit`)
    } else {
      getQuotation(sendRequest)
    }
  }

  //
  /* RETURN */
  return !loading && data?.providers && data?.advertisers && data?.agencies ? (
    <Quotation submitAction={handleCreate} providers={data.providers} mode={create} advertisers={data.advertisers} agencies={data.agencies} industries={data.industries} />
  ) : null
}
