import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PreviewIcon from '@mui/icons-material/Preview'
import { Paper } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EasyTable } from '../../components/EasyTable'
import CustomPagination from '../../components/Utils/DataTable/CustomPagination'
import { ConfirmationModal } from '../../components/shared/ConfirmationModal'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { useDestroyRecord } from '../../hooks/useDestroyRecord'
import { useReplicateRecord } from '../../hooks/useReplicateRecord'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import { formattedSaleListTotalPrices } from '../../utils/formattedSaleListTotalPrices'
import notify from '../../utils/notifications/notify'
import translatedHeaders from '../../utils/translatedHeaders'

const url = crudConstPath.quotations.index
const attrs = ['serial', 'title', 'advertiser_name', 'user_name', 'created_at', 'updated_at', 'total_price']

export function QuotationIndex() {
  const { t } = useTranslation(['translation', 'quotation'])
  const [isOpened, setIsOpened] = useState(false)
  const [fetchData, setFetchData] = useState(true)
  const [activeQuotationId, setActiveQuotationId] = useState(null)
  const { destroyRecord } = useDestroyRecord()
  const { replicateRecord } = useReplicateRecord()
  const { data, loading, sendRequest } = useBackendApi()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  const headers = translatedHeaders(attrs, 'quotation')
  const quotations = data?.quotations || []
  const totalPages = data?.total_pages || 0

  const actions = [
    {
      label: t('view', { ns: 'translation' }),
      onClick: (id) => navigate(`${id}`),
      icon: <PreviewIcon />,
      attrId: 'uuid'
    },
    {
      label: t('edit', { ns: 'translation' }),
      onClick: (id) => navigate(`${id}/edit`),
      icon: <EditIcon />,
      attrId: 'id'
    },
    {
      label: t('destroy', { ns: 'quotation' }),
      onClick: (id) => handleOnClickDestroy(id),
      icon: <DeleteIcon />,
      attrId: 'id'
    },
    {
      label: t('more-options', { ns: 'translation' }),
      icon: <MoreVertIcon />,
      options: [
        {
          label: t('replicate', { ns: 'quotation' }),
          onClick: (id) => handleOnClickReplicate(id),
          attrId: 'id'
        }
      ]
    }
  ]

  useEffect(() => {
    if (fetchData) {
      setFetchData(false)
      sendRequest(`${url}?page=${page}&limit=${rowsPerPage}`, crudConstNames.get)
    }
  }, [fetchData, page, rowsPerPage])

  const handleOnClickReplicate = async (id) => {
    try {
      const response = await replicateRecord(crudConstPath.quotations.replicate(id), t('replicate_success', { ns: 'quotation' }))
      navigate(`/quotations/${response.message.id}/edit`)
      notify(t('replicate_success', { ns: 'quotation' }), severities.success)
    } catch (error) {
      console.warn(error)
    }
  }

  const handleCloseModal = () => setIsOpened(false)
  const handleOnClickDestroy = (id) => {
    setIsOpened(true)
    setActiveQuotationId(id)
  }
  const handleDestroy = (id) => {
    destroyRecord(id, url, t('destroy_success', { ns: 'quotation' }))
    setFetchData(true)
    setActiveQuotationId(null)
    setIsOpened(false)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
    setFetchData(true)
  }

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
    setPage(1)
    setFetchData(true)
  }

  const formattedPricesQuotation = formattedSaleListTotalPrices(quotations)

  if (loading) return <LoadingBox/>
  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        mt: 2,
        mr: 2,
        backgroundColor: '#ddd'
      }}>
      <EasyTable headers={headers} data={formattedPricesQuotation} actions={actions} />
      <CustomPagination
        rowCount={totalPages} // Número total de elementos
        rowsPerPage={rowsPerPage} // Número de filas por página
        currentPage={page} // Página actual (indexada desde cero)
        onChangePage={handleChangePage} // Manejar cambio de página
        onChangeRowsPerPage={handleChangeRowsPerPage} // Manejar cambio de filas por página
        paginationRowsPerPageOptions={[30, 60, 90, 120]} // Opciones de filas por página
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página',
          rangeSeparatorText: 'de'
        }}
      />
      <ConfirmationModal
        isOpened={isOpened}
        closeModalHandler={handleCloseModal}
        confirmHandler={handleDestroy}
        titleContent={t('destroy_title', { ns: 'quotation' })}
        bodyContent={t('destroy_body', { ns: 'quotation' })}
        confirmContent={t('delete', { ns: 'translation' })}
        confirmButtonType={'error'}
        confirmParams={activeQuotationId}
      />
    </Paper>
  )
}
