import { setClipboardWithNotification } from '../../utils/setClipboardWithNotification'
import { ContentCopy } from '@mui/icons-material'
import { Button } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export function ClipboardButton({ children, copyContent }) {
  const { t } = useTranslation(['translation'])

  const handleOnClick = () => {
    setClipboardWithNotification(copyContent, t('copied', { ns: 'translation' }))
  }
  return (
    <Button onClick={() => handleOnClick()} variant='outlined' startIcon={<ContentCopy />} sx={{ mr: '8px' }}>
      {children}
    </Button>
  )
}

ClipboardButton.propTypes = {
  children: propTypes.object,
  copyContent: propTypes.string
}
