import { Autocomplete, TextField, FormControl } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const CompanySelector = ({ companies, setSelectedCompany }) => {
  const { t } = useTranslation(['dashboard'])

  const handleChange = (_, newValue) => {
    const selectedCompany = companies.find(company => company?.uuid === newValue?.id);
    setSelectedCompany(selectedCompany);
  }

  const options = companies?.map(company => ({
    label: company?.name, // Usa el nombre de la compañía como etiqueta
    id: company?.uuid
  }));

  return (
    <FormControl variant='outlined' size='small' fullWidth>
      <Autocomplete
        disablePortal
        id='company-select'
        options={options}
        sx={{ width: 300 }}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label={t('company_selector', { ns: 'dashboard' })} variant='outlined' />}
      />
    </FormControl>
  )
}

CompanySelector.propTypes = {
  companies: PropTypes.array.isRequired,
  setSelectedCompany: PropTypes.func.isRequired,
  values: PropTypes.array
}
