import { useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { crudConstNames, crudConstPath } from '../utils/enums/crudConst'
import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import useBackendApi from './useBackendApi'

export const useSelectForm = (resource, crudResource, initialOptions, modalStateHandler) => {
  const resourceId = `${resource}_id`
  const { values, setFieldValue } = useFormikContext()
  const [selectOptions, setSelectOptions] = useState(initialOptions)
  const optionRef = useRef(values[resourceId])
  const { sendRequest } = useBackendApi()
  const { t } = useTranslation(['translation', resource])

  useEffect(() => {
    optionRef.current = values[resourceId]
  }, [values[resourceId]])

  const handleSelectChange = (event) => {
    const { value } = event.target
    if (value === crudConstNames.create) {
      modalStateHandler(true)
    } else {
      optionRef.current = value
      setFieldValue(resourceId, value)
    }
  }

  const handleCreateResource = (resourceData) => {
    sendRequest(crudConstPath[crudResource].create, crudConstNames.post, {}, { [resource]: resourceData })
      .then((response) => {
        if (response.success) {
          const newOption = { id: response.message.id, name: resourceData.name }
          setSelectOptions([...selectOptions, newOption])
          optionRef.current = newOption.id
          setFieldValue(resourceId, newOption.id)

          notify(t('created', { ns: resource }), severities.success)
          modalStateHandler(false)
        }
      })
  }

  return {
    selectOptions,
    optionRef,
    handleSelectChange,
    handleCreateResource,
  }
}
