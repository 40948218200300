import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Divider, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { generateQuotationPublicUrl } from '../../utils/quotations/generatePublicUrl'
import { PermissionWrapper } from '../permissions/PermissionWrapper'
import { LabelWithValue } from '../shared/LabelWithValue'
import { DropdownQuotationButton } from './DropdownQuotationButton'
import ExportQuotationOptions from './ExportQuotationOptions'
import { ClipboardButton } from '../shared/ClipboardButton'

export function QuotationSummary({ quotation, publicPage = false, showButtons = true }) {
  const navigate = useNavigate()
  const { t } = useTranslation(['translation', 'quotation'])
  if (!quotation) {
    return null
  }
  const fullUrl = generateQuotationPublicUrl(quotation.uuid)
  return (
    <>
      <Box>
        {quotation.logo && publicPage ? (
          <img
            height='90px'
            srcSet={quotation.logo}
            src={quotation.logo}
            alt={quotation.title}
            loading='lazy'
          />
        ) : null}
        <Typography variant='h6'>
          {' '}
          {quotation.advertiser?.name} - {quotation?.title}{' '}
          {showButtons ? (
            <Box sx={{ float: 'right' }}>
              <PermissionWrapper subject='Quotation' actions={['manage']}>
                {/* @Note: Hidden create campaign temporarily */}
                {/* <Button
                  onClick={() => navigate(`/campaigns/${quotation.id}/new`)}
                  variant='outlined'
                  startIcon={<AddIcon />}
                  sx={{ mr: '8px' }}>
                  {t('create_campaign', { ns: 'quotation' })}
                </Button> */}
                <ClipboardButton copyContent={fullUrl}>{t('copy_link')}</ClipboardButton>
              </PermissionWrapper>
              {publicPage ? null : (
                <PermissionWrapper subject='Quotation' actions={['manage']}>
                  <Button
                    onClick={() => navigate(`/quotations/${quotation.id}/edit`)}
                    variant='outlined'
                    startIcon={<EditIcon />}
                    sx={{ mr: '8px' }}>
                    {t('edit')}
                  </Button>
                </PermissionWrapper>
              )}
              <ExportQuotationOptions
                quotationId={quotation.uuid}
                filename={[quotation.advertiser?.name, quotation.title].join(' - ')}
                packagePricing={quotation.package_pricing}
              />
              <PermissionWrapper subject='Quotation' actions={['manage']}>
                <DropdownQuotationButton data={quotation}/>
              </PermissionWrapper>
            </Box>
          ) : null}
        </Typography>
      </Box>

      <Divider />
      <Typography variant='overline' component='div' sx={{ display: 'flex', gap: 2, mb: 1 }}>
        <Box sx={{ textAlign: 'justify' }}>
          <div>
            {t('serial', { ns: 'quotation' })}: {quotation.serial}
          </div>
        </Box>
        <Box sx={{ textAlign: 'justify' }}>
          <div>
            {t('creationDateLabel', { ns: 'quotation' })}: {quotation?.created_at}
          </div>
        </Box>
      </Typography>

      <Typography variant='body1' component='div' sx={{ display: 'flex', gap: 1, mb: 1 }}>
        <Box sx={{ textAlign: 'justify', fontWeight: 'bold' }}>
          <div>{t('executiveLabel', { ns: 'quotation' })}:</div>
        </Box>
        <Box sx={{ textAlign: 'justify' }}>
          <div>{quotation?.user?.name}</div>
        </Box>
      </Typography>

      {/* {quotation?.is_campaign && (
        <Typography variant='body1' sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Box sx={{ textAlign: 'justify', fontWeight: 'bold' }}>{t('statusLabel', { ns: 'quotation' })}:</Box>
          <Box sx={{ textAlign: 'justify' }}>{quotation?.status_name}</Box>
        </Typography>
      )} */}

      {quotation?.comments && (
        <Box>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
            {t('comments', { ns: 'quotation' })}
          </Typography>
          <Typography variant='body1' sx={{ whiteSpace: 'pre-line', maxHeight: '100px', overflowY: 'scroll' }}>
            {quotation?.comments}
          </Typography>
        </Box>
      )}
    </>
  )
}

QuotationSummary.propTypes = {
  quotation: propTypes.object.isRequired,
  publicPage: propTypes.bool,
  showButtons: propTypes.bool
}
