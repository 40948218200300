import { crudConst } from '../../utils/enums/crudConst'
import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Field, Form, Formik } from 'formik'
import moment from 'moment-timezone'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const { create, update } = crudConst

export const UserForm = ({ submitAction, user, companies = [], roles = [] }) => {
  //
  /* CONSTANTS */
  const action = user ? update : create

  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'user'])

  //
  /* METHODS */
  const handleSubmit = (values) => {
    submitAction({ user: values })
  }
  //
  /* RETURN */
  const checkOnlyOneCompany = companies.length === 1 ? companies[0].id : ''
  const initialValues = {
    name: user?.name || '',
    company_id: user?.company_id.toString() || checkOnlyOneCompany,
    email: user?.email || '',
    phone: user?.phone || '',
    role: user?.role?.toString() || '',
    timezone: user?.timezone || moment.tz.guess()
  }

  const yupValidationsHash = {
    name: Yup.string().required(t('name_required', { ns: 'user' })),
    company_id: Yup.number().required(t('company_required', { ns: 'user' })),
    email: Yup.string()
      .email(t('email_invalid', { ns: 'user' }))
      .required(t('email_required', { ns: 'user' })),
    role: Yup.string().required(t('role_required', { ns: 'user' })),
    timezone: Yup.string().required(t('timezone_required', { ns: 'user' }))
  }
  if (action === create) {
    yupValidationsHash.password = Yup.string()
      .required(t('password_required', { ns: 'user' }))
      .min(8, t('password_min', { ns: 'user' }))
      .matches(/^(?=.*[a-z])/, t('password_lowercase', { ns: 'user' }))
      .matches(/^(?=.*[A-Z])/, t('password_uppercase', { ns: 'user' }))
      .matches(/^(?=.*[0-9])/, t('password_number', { ns: 'user' }))
    yupValidationsHash.password_confirmation = Yup.string()
      .required(t('password_required', { ns: 'user' }))
      .oneOf([Yup.ref('password'), null], t('password_match', { ns: 'user' }))

    initialValues.password = ''
    initialValues.password_confirmation = ''
  }
  const yupValidations = Yup.object(yupValidationsHash)
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={yupValidations}>
        {({ errors, touched, setFieldValue }) => (
          <Paper
            sx={{
              p: 1,
              mt: 2,
              mr: 2,
              flexDirection: 'column',
              gap: '10px',
              display: 'flex'
            }}
            elevation={3}>
            <Typography variant='h5' sx={{ color: 'secondary.dark', height: '25px', margin: 2 }}>
              {action === create ? t('new', { ns: 'user' }) : t('edit', { ns: 'company' })}
            </Typography>

            <Form sx={{ display: 'flex', gap: '10px' }}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  name='name'
                  id='name'
                  label={t('name', { ns: 'user' })}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  name='email'
                  id='email'
                  label={t('email', { ns: 'user' })}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field
                  as={TextField}
                  name='phone'
                  id='phone'
                  label={t('phone', { ns: 'user' })}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>
              {action === create ? (
                <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                  <Field
                    as={TextField}
                    name='password'
                    id='password'
                    type='password'
                    label={t('password', { ns: 'user' })}
                    error={errors.password && touched.password}
                    helperText={errors.password && touched.password ? errors.password : ''}
                    sx={{ flex: 1 }}
                    size='small'
                  />
                  <Field
                    as={TextField}
                    name='password_confirmation'
                    type='password'
                    id='password_confirmation'
                    label={t('password_confirmation', { ns: 'user' })}
                    error={errors.password_confirmation && touched.password_confirmation}
                    helperText={
                      errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : ''
                    }
                    sx={{ flex: 1 }}
                    size='small'
                  />
                </FormControl>
              ) : null}

              <FormControl sx={{ mb: '10px' }} fullWidth error={errors.company_id && touched.company_id}>
                <InputLabel htmlFor='company_id'>{t('company', { ns: 'user' })}</InputLabel>

                <Field
                  as={Select}
                  name='company_id'
                  id='company_id'
                  label={t('company', { ns: 'user' })}
                  sx={{ flex: 1 }}>
                  {companies.map((option) => {
                    const { id, name } = option

                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  })}
                </Field>
                {errors.company_id && touched.company_id ? <FormHelperText>{errors.company_id}</FormHelperText> : null}
              </FormControl>
              <FormControl sx={{ mb: '10px' }} fullWidth error={errors.role && touched.role}>
                <InputLabel htmlFor='role'>{t('role', { ns: 'user' })}</InputLabel>
                <Field as={Select} name='role' id='role' label={t('role', { ns: 'user' })} sx={{ flex: 1 }}>
                  {roles.map((option) => {
                    const name = option[0]
                    const id = option[1]

                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  })}
                </Field>
                {errors.role && touched.role ? <FormHelperText>{errors.role}</FormHelperText> : null}
              </FormControl>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={Autocomplete}
                  name='timezone'
                  id='timezone'
                  label={t('timezone', { ns: 'commons' })}
                  sx={{ flex: 1 }}
                  size='small'
                  fullWidth
                  options={moment.tz.names()}
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => {
                    setFieldValue('timezone', value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('timezone', { ns: 'commons' })} variant='outlined' />
                  )}
                />
              </FormControl>

              <ButtonGroup
                variant='outlined'
                aria-label='outlined button group'
                sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', gap: '10px' }}>
                <Button color='secondary' type='submit' variant='contained'>
                  {action === update ? t('save', { ns: 'translation' }) : t('create', { ns: 'user' })}
                </Button>
              </ButtonGroup>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  )
}

UserForm.propTypes = {
  submitAction: propTypes.func,
  user: propTypes.object,
  companies: propTypes.array.isRequired,
  roles: propTypes.array.isRequired
}
