import useDownloadPdf from '../../hooks/useDownloadPdf'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Button, Menu, MenuItem } from '@mui/material'
import propTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { crudConstPath } from '../../utils/enums/crudConst'

const url = crudConstPath.quotations.index

const ExportQuotationToPdfDropdown = ({ quotationId, filename }) => {
  const [dropdownState, setDropdown] = useState(null)
  const { t } = useTranslation(['transaltion', 'quotation'])
  const pdf_url = `${url}/${quotationId}.pdf`
  const { getPdf, withTotalsRef } = useDownloadPdf(pdf_url, filename)

  const handleDownload = (withTotals) => {
    handleCloseDropdown()
    withTotalsRef.current = withTotals
    return getPdf()
  }

  const handleOpenDropdown = (event) => {
    setDropdown(event.currentTarget);
  }

  const handleCloseDropdown = () => {
    setDropdown(null);
  }

  return (
    <>
      <Button
        variant='outlined'
        startIcon={<FileDownloadIcon />}
        onClick={handleOpenDropdown}>
          {t('export_to_pdf', { ns: 'quotation' })}
      </Button>

      <Menu
        anchorEl={dropdownState}
        open={Boolean(dropdownState)}
        onClose={handleCloseDropdown}
      >
        <MenuItem
          onClick={() => handleDownload(true)}>
          {t('export_with_totals', { ns: 'quotation' })}
        </MenuItem>
        <MenuItem
          onClick={() => handleDownload(false)}>
          {t('export_without_totals', { ns: 'quotation' })}
        </MenuItem>
      </Menu>
    </>
  )
}

ExportQuotationToPdfDropdown.propTypes = {
  quotationId: propTypes.string.isRequired,
  filename: propTypes.string.isRequired
}

export default ExportQuotationToPdfDropdown