import axios from 'axios'
import { useEffect, useState } from 'react'

import { getAuthorizationCode } from '../../utils/authorization'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstPath } from '../../utils/enums/crudConst'

const AD_SPACES_URL = crudConstPath.adSpaces.detailed_index
/**
 * Obtiene un listado de AdSpaces.
 *
 * @param {hash} position - latitud y longitud para usar de filtro {lat:, lng:}
 * @param {hash} params - parametros utilizados como filtro.
 * @returns {[AdSpaces]} Listado de soportes segun parametros enviados
 */
export const useAdSpacesFetcher = (position, params = {}) => {
  const [adSpaces, setAdSpaces] = useState([])
  const fetchAdSpaces = async () => {
    try {
      const response = await axios.get(AD_SPACES_URL, {
        params: { bounds: { south: position.lat, west: position.lng, north: position.lat, east: position.lng}, ...params },
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuthorizationCode()}`
        }
      })

      const { data } = response.data
      setAdSpaces(data)
    } catch (error) {
      notify(error.message, severities.error)
    }
  }

  useEffect(() => {
    if (!position) return
    fetchAdSpaces()
  }, [position])

  return { adSpaces }
}
