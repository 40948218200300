import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import useBackendApi from '../useBackendApi'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

export const usePreCampaignRequester = () => {
  const { data, sendRequest } = useBackendApi()
  const navigate = useNavigate()
  const { t } = useTranslation(['campaign'])

  const sendPreCampaignRequest = async (campaignId) => {
    try {
      const url = crudConstPath.quotations.request_pre_campaign(campaignId)
      const response = await sendRequest(url, crudConstNames.post)
      navigate(`/campaigns/${response.message.id}/pre_campaign`, { replace: true })
      notify(t('created', { ns: 'campaign' }), severities.success)

    } catch (error) {
      console.warn(error)
    }
  }

  return { sendPreCampaignRequest }
}
