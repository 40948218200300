export const quotationTabTypes = {
  map_tab_open: 0,
  catalog_tab_open: 1,
  cart_tab_open: 2
}


export const quotationTabNames = {
  map_tab_open: "map_tab_open",
  catalog_tab_open: "catalog_tab_open",
  cart_tab_open: "cart_tab_open"
}
