import { esES as coreEsES } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import { esES } from '@mui/x-date-pickers/locales'
export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#333F48',
      textContrast: '#fff'
    },
    secondary: {
      main: '#269982',
      dark: '#1A6B5B'
    },
    background: {
      default: '#eeeeee',
      light: '#F5F5F5',
      secondary: '#269982'
    },
    error: {
      main: '#F9423A'
    },
    success: {
      main: '#00BD02'
    },
    warning: {
      main: '#ED6C02'
    },
    info: {
      main: '#0288D1'
    },
    disabled: {
      main: '#858585'
    }
  },
  typography: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeightBold: 700,
    fontWeightMedium: 500
  },
  spacing: 8,
  shape: {
    borderRadius: 4
  },
  props: {
    MuiDrawer: {
      width: 240,
      flexShrink: 0,
      marginTop: 64,
      overflowX: 'hidden',
      whiteSpace: 'nowrap'
    },
    MuiMenuItem: {
      dense: true
    },
    MuiTable: {
      size: 'small'
    }
  },
  overrides: {
    '@global': {
      MuiCssBaseline: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#2b2b2b'
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #2b2b2b'
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595'
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595'
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595'
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b'
          }
        }
      }
    }
  }
}

const theme = createTheme(themeOptions, coreEsES, esES)

const planningTheme = createTheme(
  theme,
  {
    props: {
      MuiDrawer: {
        boxSizing: 'border-box',
        width: theme.props.MuiDrawer.width,
        marginTop: 64,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: 'hidden',
        '& .MuiDrawer-paper': {
          width: theme.props.MuiDrawer.width,
          marginTop: 64,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
          }),
          overflowX: 'hidden'
        }
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {},
            '& .MuiInputAdornment-root': {
              marginRight: 0
            },
            '& .MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
              paddingRight: 0,
              textAlign: 'right'
            }
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            left: 0,
            right: 0,
            display: 'flex',
            zIndex: 9999,
            position: 'absolute',
            // transform: `translateY(${ownerState.offset}px)`,
            top: 0,
            justifyContent: 'center'
          })
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '7px 7px'
          },
          head: {
            fontWeight: 700,
            backgroundColor: theme.palette.background.secondary,
            color: '#fff'
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {}
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: theme.palette.background.default
            }
          }
        }
      }
    }
  },
  coreEsES,
  esES
)

export default planningTheme
