/* eslint-disable new-cap */
import { useEffect, useRef } from 'react'

import { useMapFilterActions } from '../hooks/actions/useMapFilterActions'
import { getPaddedMapBounds } from '../utils/getPaddedMapBounds'
import { useTimeoutWraper } from './useTimeoutWraper'
import { useAppSelector } from './store'

const fitMapToBounds = (showAllMarkers, map, markers) => {
  if (showAllMarkers) {
    const bounds = markers.reduce((acc, marker) => {
      return acc.extend(marker)
    }, new window.google.maps.LatLngBounds())

    map?.fitBounds(bounds)
  }
}

/**
 * useMapInitializer is use for initial configuration of googlemap
 * like onLoadLogic, onTilesLoad and onLoadMarker
 * @param {Boolean} showAllMarkers
 * @param {Array} markers
 * @returns
 */
export function useMapInitializer(showAllMarkers, markers, onBoundsChanged, handleSetZoom) {
  const mapRef = useRef(null)
  const { setBoundsFilterState } = useMapFilterActions() // setBound to redux
  const mapFilter = useAppSelector((state) => state.mapFilter)

  const timeoutWrapper = useTimeoutWraper()

  useEffect(() => {
    onBoundsChanged && mapRef.current && onBoundsChanged(mapRef.current.getBounds())
  }, [markers])

  const handleOnLoad = (map) => {
    mapRef.current = map
    fitMapToBounds(showAllMarkers, mapRef.current, markers)
  }

  const handleOnTilesLoad = () => {
    if (onBoundsChanged === undefined) return false
    const bounds = getPaddedMapBounds(mapRef?.current).toJSON()
    const currentZoom = mapRef.current.getZoom()
    if (currentZoom <= 14 || boundsAreOutOf(bounds)) {
      timeoutWrapper(() => {
        setBoundsFilterState(bounds)
        handleSetZoom(mapRef.current.getZoom())
      }, 1000)
    } else {
      return false
    }
  }

  const boundsAreOutOf = (bounds) => {
    const oldBounds =  mapFilter.oldBounds
    return (
      bounds.south < oldBounds.south ||
      bounds.north > oldBounds.north ||
      bounds.west < oldBounds.west ||
      bounds.east > oldBounds.east
    )
  }

  return { handleOnLoad, handleOnTilesLoad, mapRef }
}
