import { Autocomplete, Button, ListSubheader, TextField } from "@mui/material";
import { useField } from "formik";
import { FormPaper } from "./FormPaper";

export const GroupedAutoComplete = ({
  label,
  field_name,
  formValues,
  setFieldValue,
  handleOnClick,
  ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  return (
    <Autocomplete
      {...props}
      disablePortal
      name={field_name}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      disableCloseOnSelect={true}
      className='autocomplete-input filter-input'
      onChange={(_event, newValue) => {
        setFieldValue(field_name,
          newValue.length > 0 ?
            newValue.map(field => field.uuid) :
            []
        )
      }}
      ChipProps={{
        clickable: false,
        classes: 'input-chip',
        variant: 'outlined',
        onDelete: null
      }}
      multiple
      size='small'
      groupBy={(option) => option.header}
      getOptionLabel={(option) => option.name}
      PaperComponent={FormPaper}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          className='filters-menu-item'
          fullWidth
        />
      )}
      renderGroup={(params) => (
        <ul key={params.key} style={{padding: 0}}>
          <ListSubheader component='div' className='subheader'>
            <Button onClick={() => handleOnClick(params.group)} fullWidth >
              {params.group}
            </Button>
          </ListSubheader>
          {params.children}
        </ul>
      )}
    />
  )
}