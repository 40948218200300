import { crudConstNames } from '../utils/enums/crudConst'
import useBackendApi from './useBackendApi'

export const useReplicateRecord = () => {
  const { sendRequest } = useBackendApi()
  const replicateRecord = async (url) => {
    const response = await sendRequest(url, crudConstNames.post)
    return response

  }
  return { replicateRecord }
}