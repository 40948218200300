import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, MenuItem, Select, TextField, Tooltip, Typography, Popover } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { MobileDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import propTypes from 'prop-types'
import { memo, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from "@mui/system"

import { useBidBySlice } from '../../hooks/useBidBySlice'
import { useBidsActionBySlice } from '../../hooks/useBidsActionBySlice'
import useDurationWithDatesCalculator from '../../hooks/useDurationWithDatesCalculator'
import { detailsVariants } from '../../utils/detailsVariants'
import { durationUnits, durationUnitsEnum } from '../../utils/durationUnits'
import { CurrencyFieldInput } from '../CurrencyFieldInput'
import { AdSpaceDetails } from '../adSpaces/AdSpaceDetails'
import { ObjectList } from '../shared/ObjectList'

import '../../styles/bids/BidRow.css'
import { VisitModeWrapper } from '../VisitModeWrapper'

const BidRow = ({
  index,
  storeSliceId,
  errors,
  bidUnavailables,
  providerUuid,
  packagePricing,
  bidsBounce,
  setActiveBounce
}) => {
  // HOOKS
  const [selectedBidAdSpaceId, setSelectedBidAdSpaceId] = useState(null)
  const [isOpened, setIsOpened] = useState(false)
  const { t } = useTranslation(['translation', 'adSpace'])
  const { removeBidFromCart, updateBidInCart } = useBidsActionBySlice(storeSliceId)
  const [activeBid, setActiveBid] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  // assign bid from redux based on type of slice and bid information nested on errors
  const bid = useBidBySlice(storeSliceId, index, providerUuid)
  const missingDatesError = null ?? errors?.missingDates?.find((errorBid) => errorBid.id === bid.id)
  const collisionDatesError = null ?? errors?.collidingBids?.find((errorBid) => errorBid.id === bid.id)
  const unavailable = bidUnavailables?.includes(bid.id)
  const isOpenPopoverPrices = Boolean(anchorEl)
  const isLowerPriceRef = useRef(bid.leasing_price < bid.ad_space_minimum_price)

  // CONSTANTS
  const { quantity, duration, duration_unit, start_date, end_date } = bid

  const listPrices = {
    provider: bid.ad_space_provider_name,
    provider_fee: t('currency', { val: bid.ad_space_provider_fee, ns: 'adSpace' }),
    minimum_price: t('currency', { val: bid.ad_space_minimum_price, ns: 'adSpace' })
  }

  // METHODS
  const handleInfoClick = (id) => {
    setSelectedBidAdSpaceId(id)
    setIsOpened(true)
  }

  const renderAdSpacecontent = () => {
    return (
      <>
        {t('sku', { ns: 'adSpace' })}: {bid.ad_space_sku} |&nbsp;
        <VisitModeWrapper>
          {t('code.short', { ns: 'adSpace' })}: {bid.ad_space_code == '' ? t('code.empty', { ns: 'adSpace' }) : bid.ad_space_code } |&nbsp;
        </VisitModeWrapper>
        {bid.ad_space_format_name}
      </>
    )
  }

  const handlePopoverClick = (event) => {
    event.stopPropagation()
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleClickOnPopover = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMouseEnter = (uuid) => {
    setActiveBounce(uuid, true)
  }

  const handleMouseLeave = (uuid) => {
    setActiveBounce(uuid, false)
  }


  const handleCloseModal = () => setIsOpened(false)

  const handleClick = () => removeBidFromCart(bid, index)

  const handleQuantityChange = (e) =>
    updateBidInCart({ provider_uuid: bid.provider_uuid, quantity: parseInt(e.target.value) || 1 }, index)

  const handleLeasingPriceChange = (values) => {
    isLowerPriceRef.current = values.value < bid.ad_space_minimum_price
    if (!packagePricing) {
      updateBidInCart({ provider_uuid: bid.provider_uuid, leasing_price: values.value || 0 }, index)
    }
  }

  const handleDurationChange = (value) =>
    updateBidInCart({ provider_uuid: bid.provider_uuid, duration: value }, index)

  const handleProductionPriceChange = (values) => {
    if (!packagePricing) {
      updateBidInCart({ provider_uuid: bid.provider_uuid, production_price: values.value || 0 }, index)
    }
  }

  const handleInstallationPriceChange = (values) => {
    if (!packagePricing) {
      updateBidInCart({ provider_uuid: bid.provider_uuid, installation_price: values.value || 0 }, index)
    }
  }

  const handleDurationUnitChange = (newDurationUnit) =>
    updateBidInCart({ provider_uuid: bid.provider_uuid, duration_unit: newDurationUnit }, index)

  const handleStartDateChange = (date) =>
    updateBidInCart({ provider_uuid: bid.provider_uuid, start_date: date.toISOString() }, index)

  const handleEndDateChange = (date) =>
    updateBidInCart({ provider_uuid: bid.provider_uuid, end_date: date.toISOString() }, index)

  const { atDurationChange, atDurationUnitChange, atStartDateChange, atEndDateChange } = useDurationWithDatesCalculator(
    handleDurationChange,
    handleDurationUnitChange,
    handleStartDateChange,
    handleEndDateChange
  )

  useEffect(() => {
    const startDate = start_date ? moment(start_date) : null
    const endDate = end_date ? moment(end_date) : null
    if (endDate && startDate) {
      atEndDateChange({ endDate, startDate })
    }
  }, [])

  return bid._destroy ? null : (
    <TableRow
      key={index}
      onMouseEnter={() => {
        handleMouseEnter(bid.ad_space_uuid)
        setActiveBid(true)
      }}
      onMouseLeave={() => {
        handleMouseLeave(bid.ad_space_uuid)
        setActiveBid(false)
      }}
      style={{ background: activeBid ? '#26998229' : '' }}>
      <TableCell sx={{ padding: 0 }}>
        <div className={'info-container'}>
          {collisionDatesError ? (
            <Tooltip sx={{ flex: 1 }} title={t('messages.collision_dates', { ns: 'bid' })}>
              <IconButton>
                <ErrorIcon color={'error'} />
              </IconButton>
            </Tooltip>
          ) : null}
          {unavailable ? (
            <Tooltip sx={{ flex: 1 }} title={t('messages.unavailable', { ns: 'bid' })}>
              <IconButton>
                <CalendarMonthIcon color={'error'} />
              </IconButton>
            </Tooltip>
          ) : null}
          <IconButton
            sx={{ padding: 0, flex: 1 }}
            onClick={() => {
              handleInfoClick(bid.ad_space_uuid)
            }}>
            <InfoOutlinedIcon fontSize='small' />
          </IconButton>
        </div>
      </TableCell>
      <TableCell sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '350px',
        display: 'block' }}>
        <Tooltip sx={{ flex: 1 }} title={bid.ad_space_name}>
          <Typography padding={0} sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
            {bid.ad_space_name}
          </Typography>
        </Tooltip>
        <Tooltip sx={{ flex: 1 }} title={renderAdSpacecontent()}>
          <Typography variant='caption' sx={{ padding: 0, textTransform: 'uppercase', fontSize: '10px' }}>
            {renderAdSpacecontent()}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell className='stretch-table-cell'>
        <CurrencyFieldInput
          onChange={handleLeasingPriceChange}
          value={bid.leasing_price}
          data-testid='leasing-price-input'
          disabled={packagePricing}
          sx={{
            input: {
              textAlign: 'right',
              pl: 0,
              pr: 0,
              pb: 1,
              minwidth: '80px',
              color: isLowerPriceRef.current ? 'red' : ''
            }
          }}
        />
      </TableCell>
      <VisitModeWrapper>
        <TableCell sx={{ padding: 0 }}>
          <Tooltip onClick={handleClickOnPopover}>
            <IconButton sx={{ padding: 0, flex: 1 }}>
              <InfoOutlinedIcon
                key={`${bid.adSpace_uuid}Icon`}
                className='code-info-icon'
                aria-owns={isOpenPopoverPrices ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
              />
            </IconButton>
          </Tooltip>
          <Popover
            id='mouse-over-popover'
            onClick={handlePopoverClick}
            open={isOpenPopoverPrices}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}>
            <Box className='ad-space-prices'>
              <ObjectList data={listPrices} nameSpace='adSpace' />
            </Box>
          </Popover>
        </TableCell>
      </VisitModeWrapper>
      <TableCell className='stretch-table-cell'>
        <TextField
          data-testid='quantity-input'
          sx={{ width: '50px', input: { textAlign: 'center', padding: 1 } }}
          name='quantity'
          type='number'
          variant='standard'
          size='small'
          onChange={handleQuantityChange}
          InputProps={{ inputProps: { min: 1 } }}
          value={quantity}
        />
      </TableCell>
      <TableCell className='stretch-table-cell'>
        <div className='container-duration-with-dates'>
          <TextField
            className='duration-input'
            name='duration'
            label={t('duration_label', { ns: 'bid' })}
            type='number'
            variant='outlined'
            size='small'
            onChange={(e) => {
              atDurationChange({ duration: e.target.value, startDate: start_date, durationUnit: duration_unit })
            }}
            value={duration}
            InputProps={{ inputProps: { min: 1 } }}
          />

          <Select
            className='duration-unit-input'
            size='small'
            name='duration_unit'
            onChange={(e) => {
              atDurationUnitChange({ durationUnit: e.target.value, startDate: start_date, duration })
            }}
            value={duration_unit}>
            <MenuItem value={durationUnitsEnum.days}> {durationUnits[durationUnitsEnum.days]}</MenuItem>
            <MenuItem value={durationUnitsEnum.months}> {durationUnits[durationUnitsEnum.months]}</MenuItem>
          </Select>
        </div>
      </TableCell>

      <TableCell className='stretch-table-cell'>
        <div className='container-duration-with-dates'>
          <MobileDatePicker
            className='start-date-input'
            slotProps={{ textField: { size: 'small', error: missingDatesError?.start_date === null && !start_date } }}
            format='DD/MM/YYYY'
            size='small'
            value={start_date ? moment(start_date) : null}
            onChange={(date) => atStartDateChange({ startDate: date, duration, durationUnit: duration_unit })}
            closeOnSelect={true}
            inputFormat='dd/MM/yyyy'
            maxDate={null}
            minDate={null}
            label={t('starts_at', { ns: 'translation' })}
          />

          <MobileDatePicker
            className='end-date-input'
            slotProps={{ textField: { size: 'small', error: missingDatesError?.end_date === null && !end_date } }}
            format='DD/MM/YYYY'
            size='small'
            value={end_date ? moment(end_date) : null}
            onChange={(date) => atEndDateChange({ endDate: date, startDate: start_date })}
            closeOnSelect={true}
            inputFormat='dd/MM/yyyy'
            maxDate={null}
            minDate={start_date ? moment(start_date) : null}
            label={t('ends_at', { ns: 'translation' })}
          />
        </div>
      </TableCell>
      <TableCell className='stretch-table-cell' align='center'>
        <CurrencyFieldInput displayType='text' value={bid.total_leasing_price} style={{ whiteSpace: 'nowrap' }} />
      </TableCell>
      <TableCell className='stretch-table-cell'>
        <CurrencyFieldInput
          onChange={handleProductionPriceChange}
          value={bid.production_price}
          disabled={packagePricing}
          sx={{ input: { textAlign: 'right', padding: 1, minWidth: '80px' } }}
        />
      </TableCell>
      <TableCell className='stretch-table-cell'>
        <CurrencyFieldInput
          onChange={handleInstallationPriceChange}
          value={bid.installation_price}
          disabled={packagePricing}
          sx={{ input: { textAlign: 'right', padding: 1, minWidth: '80px' } }}
        />
      </TableCell>
      <TableCell>
        <IconButton aria-label='delete' onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
      {selectedBidAdSpaceId && isOpened && (
        <AdSpaceDetails
          variant={detailsVariants.default}
          isOpened={isOpened}
          closeModal={handleCloseModal}
          id={selectedBidAdSpaceId}
        />
      )}
    </TableRow>
  )
}

BidRow.propTypes = {
  index: propTypes.number.isRequired,
  storeSliceId: propTypes.number.isRequired,
  errors: propTypes.object,
  setActiveBounce: propTypes.func,
  bidsBounce: propTypes.object,
  bidUnavailables: propTypes.array.isRequired,
  packagePricing: propTypes.bool,
  providerUuid: propTypes.string,
}

export default memo(BidRow)
