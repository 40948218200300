import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentTab: 0
}

export const quotationTabSlice = createSlice({
  name: 'quotationTab',
  initialState,
  reducers: {
    setQuotationTab: (state, action) => {
      state.currentTab = action.payload
    }
  }
})

export const { setQuotationTab } = quotationTabSlice.actions
export default quotationTabSlice.reducer
