import { TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
export function CurrencyFieldInput({ onChange, ...props }) {
  const { t } = useTranslation(['translation'])

  return (
    <NumericFormat
      {...props}
      customInput={TextField}
      variant='standard'
      valueIsNumericString={true}
      onValueChange={onChange}
      thousandSeparator={t('thousand_separator')}
      decimalSeparator={t('decimal_separator')}
      decimalScale={t('decimal_scale')}
      allowNegative={false}
      prefix={t('currency_symbol')}
    />
  )
}

CurrencyFieldInput.propTypes = {
  onChange: PropTypes.func,
  props: PropTypes.object
}
