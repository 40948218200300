import { getAuthorizationCode } from '../utils/authorization'
import { crudConstPath } from '../utils/enums/crudConst'
import { severities } from '../utils/enums/notifications/notificationsSeverities'
import notify from '../utils/notifications/notify'
import axios from 'axios'
import { useEffect, useState } from 'react'

const url = crudConstPath.campaigns.index

export function useGetCampaign(id) {
  const [loading, setLoading] = useState(false)
  const [campaign, setCampaign] = useState({})

  const getCampaign = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${url}/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuthorizationCode()}`
        }
      })
      setCampaign(response.data.campaign)
    } catch (error) {
      notify(error.response.data.message, severities.error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCampaign()
  }, [id])

  return { campaign, bids: campaign?.bids || [], loading }
}
