import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Field } from 'formik'
import propTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// CONSTANTS
const MenuProps = {
  PaperProps: {
    style: {
      minWidth: '200px'
    }
  }
}

export const RegionFilterInput = ({ regions, setRegionsIds, values }) => {
  // HOOKS
  const { t } = useTranslation(['translation', 'adSpace'])

  // METHODS

  useEffect(() => {
    const regionIds = regions.filter((region) => values.region_ids.includes(region.uuid)).map((region) => region.id)
    setRegionsIds(regionIds)
  }, [values.region_ids])

  // RETURN
  return regions ? (
    <FormControl className='form-control-filters' variant='outlined' size='small' fullWidth>
      <InputLabel htmlFor='region-select'>{t('region', { ns: 'adSpace' })}</InputLabel>
      <Field
        as={Select}
        name='region_ids'
        id='region-select'
        label={t('region', { ns: 'adSpace' })}
        multiple
        MenuProps={MenuProps}
        className='filters-input-select'>
        {regions.map((region, index) => {
          const { uuid, name } = region
          return (
            <MenuItem key={index} value={uuid} className='filters-menu-item'>
              {name}
            </MenuItem>
          )
        })}
      </Field>
    </FormControl>
  ) : null
}

RegionFilterInput.propTypes = {
  regions: propTypes.array.isRequired,
  setRegionsIds: propTypes.func.isRequired,
  values: propTypes.object.isRequired
}
