import { getDurationInMonths } from "./getDurationInMonths"

export const calculateLeasingTotal = (bid, fromBasePrice = false) => {
  const { leasing_price, ad_space_leasing_price, quantity, duration, duration_unit } = bid
  const durationInMonths = getDurationInMonths(duration_unit, duration)
  if (fromBasePrice) {
    return ad_space_leasing_price * quantity * durationInMonths
  } else {
    return leasing_price * quantity * durationInMonths
  }
}
