import { CompanySelector } from '../../components/dashboard/CompanySelector'
import DashboardCompanyFilters from '../../components/dashboard/DashboardCompanyFilters'
import StatisticCard from '../../components/dashboard/StatisticCard'
import useBackendApi from '../../hooks/useBackendApi'
import '../../styles/Dashboard.scss'
import { Grid, Typography, Button, Box } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

const url = crudConstPath.dashboard

export function Dashboard() {
  const { data, sendRequest } = useBackendApi()
  const [selectedCompany, setSelectedCompany] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const { t } = useTranslation('dashboard')

  const formattedDate = selectedDate.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })

  useEffect(() => {
    const params = {
      selectedCompany: selectedCompany?.uuid,
      selectedDate: selectedDate.toISOString()
    }
    sendRequest(url, crudConstNames.get, {}, null, params)
  }, [selectedCompany, selectedDate])

  return (
    <Box className='dashboard'>
      <Typography variant='h4' component='h2' gutterBottom>
        {t('dashboard')}
      </Typography>
      <div className='dashboard-summary'>
        <Typography variant='h6' gutterBottom>
          {`${t('summary_all_companies')} (${formattedDate})`}
        </Typography>
        <div className='dashboard-buttons-group'>
          <Button variant='contained' color='primary' onClick={() => setSelectedDate(new Date())}>
            {t('today')}
          </Button>
          <DatePicker
            label={t('availability_start_date', { ns: 'dashboard' })}
            value={moment(selectedDate)}
            slotProps={{ textField: { size: 'small' } }}
            format={t('date_format', { ns: 'translation' })}
            onChange={(newDate) => setSelectedDate(new Date(newDate))}
            inputFormat={t('date_input_format', { ns: 'translation' })}
          />
        </div>
      </div>

      <Grid container spacing={2}>
        {data && (
          <>
            <Grid item xs={12} md={4}>
              <StatisticCard data={data.all_companies.total_active_users} title={t('total_active_users')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatisticCard data={data.all_companies.total_quotations} title={t('total_quotations')} />
            </Grid>
            {/* SALE_MODULE: hidden temporarily */}
            {/* <Grid item xs={12} md={4}>
              <StatisticCard data={data.all_companies.total_campaigns} title={t('total_purchase_requests')} />
            </Grid> */}
          </>
        )}
      </Grid>

      <div className='dashboard-summary-by-company'>
        <Typography variant='h6' gutterBottom>
          {selectedCompany ? `${t('company_summary')} ${selectedCompany.name} (${formattedDate})` : t('select_company')}
        </Typography>
        <div className='dashboard-buttons-group'>
          <DashboardCompanyFilters setSelectedCompany={setSelectedCompany} />
        </div>
      </div>

      <Grid container spacing={2}>
        {data?.selected_company && selectedCompany ? (
          <>
            <Grid item xs={12} md={4}>
              <StatisticCard data={data.selected_company.company_active_users} title={t('active_users')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatisticCard data={data.selected_company.company_total_quotations} title={t('created_quotations')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatisticCard data={data.selected_company.company_total_campaigns} title={t('sent_purchase_requests')} />
            </Grid>
          </>
        ) : (
          <div className='dashboard-no-company-text-box'>
            <Typography variant='subtitle1'>{t('select_company')}</Typography>
          </div>
        )}
      </Grid>
    </Box>
  )
}
