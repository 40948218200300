import '../../styles/navbar/Navbar.css'
import { Logo } from '../Logo'
import { SideMenuButton } from './SideMenuButton'
import UserMenu from './UserMenu'
import { AppBar, Box, CssBaseline, Toolbar, Typography } from '@mui/material'
import propTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

export function Navbar({title}) {
  // HOOKS

  const location = useLocation()

  // METHODS
  const isNavDisabled = location.pathname.includes('/quotation/')
  const isLoginPage = location.pathname === '/'

  // RETURN

  /* Si es la página de inicio de sesión, no mostrar la barra de navegación */
  if (isLoginPage || isNavDisabled) {
    return null
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position='fixed' color='primary'>
        <Toolbar>
          <SideMenuButton />
          <Logo className='navbar-brand-logo' />
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h5' component='h1'>
              {title}
            </Typography>
          </Box>
          <UserMenu />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Navbar.propTypes = {
  title: propTypes.string
}
