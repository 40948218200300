import propTypes from 'prop-types'

import '../../styles/shared/ConditionalContent.css'

// Main purpose is to hide content based on a condition
export function ConditionalContent({ condition, children }) {
  return (
    <div className={condition ? 'block' : 'hidden'}>
      {children}
    </div>
  )
}

ConditionalContent.propTypes = {
  condition: propTypes.boolean,
  children: propTypes.object
}