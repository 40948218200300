import { getPermissions } from './getPermissions'

export const useCheckPermissions = (actions, subject) => {
  const permissions = getPermissions()
  // TODO: Include conditions as parameter to check for specific blocks
  // TODO: Include negations for when specific role cannot do certain action
  const hasPermission = permissions.some(permission =>
    permission.action.filter(permitted_action =>
      actions.includes(permitted_action)
    ).length > 0 &&
    (permission.subject === subject || permission.subject === 'all')
  )
  return hasPermission
}
