import propTypes from 'prop-types'

import { crudConst } from '../../utils/enums/crudConst'
import { quotationTabTypes } from '../../utils/enums/quotationTabTypes'
import { QuotationMainContent } from './QuotationMainContent'
import { QuotationMainMenu } from './QuotationMainMenu'

import '../../styles/Quotations.scss'

const { update } = crudConst
export function Quotation({ submitAction, quotation, mode, providers, advertisers, agencies, industries }) {
  const initialTabOpen =
    mode === update && quotationTabTypes.cart_tab_open
      ? quotationTabTypes.cart_tab_open
      : quotationTabTypes.map_tab_open
  return (
    <div className='q-container-column'>
      <QuotationMainMenu initialTabOpen={initialTabOpen} quotation={quotation} providers={providers} />
      <QuotationMainContent submitAction={submitAction} quotation={quotation} advertisers={advertisers} agencies={agencies} industries={industries} />
    </div>
  )
}

Quotation.propTypes = {
  submitAction: propTypes.func.isRequired,
  quotation: propTypes.shape({
    uuid: propTypes.string,
    title: propTypes.string,
    bids: propTypes.array
  }),
  mode: propTypes.number.isRequired,
  providers: propTypes.arrayOf(
    propTypes.shape({
      uuid: propTypes.string,
      name: propTypes.string
    })
  ),
  advertisers: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    })
  ),
  agencies: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    })
  ),
  industries: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string
    })
  )
}
