import EditIcon from '@mui/icons-material/Edit'
import { Button, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EasyTable } from '../../components/EasyTable'
import CustomPagination from '../../components/Utils/DataTable/CustomPagination'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import translatedHeaders from '../../utils/translatedHeaders'

const url = crudConstPath.agencies.index

const attrs = [
  'name',
  'national_id',
  'corporate_name',
  'trade_name',
  'address',
  'email',
  'created_at',
  'updated_at'
]

export function AgencyIndex() {
  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'agency'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  const agencies = data?.agencies || []
  const totalPages = data?.total_pages || 0 // Ajusta según el total que obtienes del backend
  const headers = translatedHeaders(attrs, 'agency')

  const actions = [
    {
      label: t('edit', { ns: 'agency' }),
      onClick: (id) => navigate(`${id}/edit`),
      icon: <EditIcon />,
      attrId: 'id'
    }
  ]

  useEffect(() => {
    sendRequest(`${url}?page=${page}&limit=${rowsPerPage}`, crudConstNames.get)
  }, [page, rowsPerPage])

  const handleClick = () => {
    navigate('/agencies/new')
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
    setPage(1)
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return agencies ? (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        mt: 2,
        mr: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
      <Button
        sx={{ maxWidth: '200px', marginLeft: 'auto' }}
        type='submit'
        color='secondary'
        variant='contained'
        onClick={handleClick}>
        {t('new', { ns: 'agency' })}
      </Button>
      <EasyTable headers={headers} data={agencies} actions={actions} />
      <CustomPagination
        rowCount={totalPages}
        rowsPerPage={rowsPerPage}
        currentPage={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        paginationRowsPerPageOptions={[30, 60, 90, 120]}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página',
          rangeSeparatorText: 'de'
        }}
      />
    </Paper>
  ) : null
}
