import { bidPricesTypes } from "../enums/bidPrices"
import { calculateBaseTotalPrices } from "./calculateBaseTotalPrices"

export const getBaseTotalPriceByAttribute = (attribute, bids, totalPrice) => {
  const { baseTotalLeasingPrice, baseTotalProductionPrice, baseTotalInstallationPrice } = calculateBaseTotalPrices(bids)
  let baseTotalPrice = 1
  let attributeName = ''
  switch (attribute) {
    case bidPricesTypes.total_leasing:
      baseTotalPrice = baseTotalLeasingPrice
      attributeName = bidPricesTypes.leasing
      break
    case bidPricesTypes.total_production:
      baseTotalPrice = baseTotalProductionPrice
      attributeName = bidPricesTypes.production
      break
    case bidPricesTypes.total_installation:
      baseTotalPrice = baseTotalInstallationPrice
      attributeName = bidPricesTypes.installation
      break
  }

  const isBaseTotalZero = baseTotalPrice === 0
  if (isBaseTotalZero) {
    const bids_length = bids.filter((bid) => bid.quantity > 0).length
    baseTotalPrice = bids_length > 0 ? totalPrice / bids_length : 0
  }
  return { attributeName, baseTotalPrice, isBaseTotalZero }
}