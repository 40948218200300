import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'
import { useAppSelector } from '../../hooks/store'
import { CurrencyFieldInput } from '../CurrencyFieldInput'
import { bidPricesTypes } from '../../utils/enums/bidPrices'
export function BidsTotalsTable({ action, onSubmitSale = () => { } }) {
  const { t } = useTranslation(['translation', 'bid', 'sale'])
  const bidsCart = useAppSelector(state => state.bidsCart)
  const { updatePackagePriceInCart } = useBidsCartActions()
  const { totalLeasingPrice, totalProductionPrice, totalInstallationPrice, totalSumPrice } = bidsCart

  const handlePriceChange = (key, value) => {
    if (bidsCart.packagePricing && parseInt(value) !== bidsCart[key]) {
      updatePackagePriceInCart(bidsCart, { [key]: parseInt(value) })
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead className='summary'>
          <TableRow>
            <TableCell colSpan={1}> {t('total_leasing_price', { ns: 'bid' })} </TableCell>
            <TableCell colSpan={2}> {t('total_production_price', { ns: 'bid' })} </TableCell>
            <TableCell colSpan={2}> {t('total_installation_price', { ns: 'bid' })} </TableCell>
            <TableCell colSpan={2}> {t('total_sum', { ns: 'bid' })} </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='summary'>
          <TableRow>
            <TableCell>
            <CurrencyFieldInput
              onChange={(values) => handlePriceChange(bidPricesTypes.total_leasing, values.value )}
              value={totalLeasingPrice}
              data-testid='total-leasing-price-input'
              disabled={!bidsCart?.packagePricing}
              sx={{
                input: {
                  textAlign: 'right',
                  pl: 0,
                  pr: 0,
                  pb: 1,
                  minwidth: '80px'
                }
              }}
            />
            </TableCell>
            <TableCell align='center'> + </TableCell>
            <TableCell>
              <CurrencyFieldInput
                onChange={(values) => handlePriceChange(bidPricesTypes.total_production, values.value )}
                value={totalProductionPrice}
                data-testid='total-production-price-input'
                disabled={!bidsCart?.packagePricing}
                sx={{
                  input: {
                    textAlign: 'right',
                    pl: 0,
                    pr: 0,
                    pb: 1,
                    minwidth: '80px'
                  }
                }}
              />
            </TableCell>
            <TableCell align='center'> + </TableCell>
            <TableCell>
              <CurrencyFieldInput
                onChange={(values) => handlePriceChange(bidPricesTypes.total_installation, values.value )}
                value={totalInstallationPrice}
                data-testid='total-installation-price-input'
                disabled={!bidsCart?.packagePricing}
                sx={{
                  input: {
                    textAlign: 'right',
                    pl: 0,
                    pr: 0,
                    pb: 1,
                    minwidth: '80px'
                  }
                }}
              />
            </TableCell>
            <TableCell align='center'> = </TableCell>
            <TableCell>
              {t('currency', { val: totalSumPrice, ns: 'adSpace' })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

BidsTotalsTable.propTypes = {
  action: propTypes.number,
  totalLeasingPrice: propTypes.number,
  totalProductionPrice: propTypes.number,
  totalInstallationPrice: propTypes.number,
  totalSumPrice: propTypes.number,
  onSubmitSale: propTypes.func
}
