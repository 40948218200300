import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'

import { getAuthorizationCode } from '../../utils/authorization'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { useAppSelector } from '../store'
import { crudConstPath } from '../../utils/enums/crudConst'
const AD_SPACES_URL = crudConstPath.adSpaces.full_index
const PAGE_SIZE = 10

export const useAdSpacesList = (adSpacesIds) => {
  const memoizedAdSpacesIds = useMemo(() => adSpacesIds, [adSpacesIds])
  const [adSpaces, setAdSpaces] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const availabilityFilter = useAppSelector((state) => state.mapFilter)

  const fetchAdSpaces = async () => {
    if (getAdSpacesIdsPage.length === 0) {
      setHasMore(false)
      return
    }
    try {
      const response = await axios.get(AD_SPACES_URL, {
        params: { ids: getAdSpacesIdsPage, ...availabilityFilter },
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuthorizationCode()}`
        }
      })

      const { data } = response.data
      if (page === 1) {
        setAdSpaces(data)
      } else {
        setAdSpaces((prevAdSpaces) => [...prevAdSpaces, ...data])
      }
      setHasMore(page * PAGE_SIZE < memoizedAdSpacesIds.length)
    } catch (error) {
      notify(error.message, severities.error)
    }
  }

  useEffect(() => {
    setAdSpaces([])
    setPage(1)
    setHasMore(true)
  }, [memoizedAdSpacesIds])

  useEffect(() => {

    if (memoizedAdSpacesIds.length === 0) return
    fetchAdSpaces()
  }, [page, memoizedAdSpacesIds])

  const addPage = () => {
    setPage((prevPage) => prevPage + 1)
  }

  const getAdSpacesIdsPage = useMemo(() => {
    const startIndex = (page - 1) * PAGE_SIZE
    const endIndex = startIndex + PAGE_SIZE
    return memoizedAdSpacesIds.slice(startIndex, endIndex)
  }, [memoizedAdSpacesIds, page])

  return { adSpaces, addPage, hasMore }
}
