import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Quotation } from '../../components/quotations/Quotation'
import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'
import { useMapFilterActions } from '../../hooks/actions/useMapFilterActions'
import useBackendApi from '../../hooks/useBackendApi'
import { crudConst, crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { notifySuccess } from '../../utils/notifications/notify'

import '../../styles/Quotations.scss'
import { campaignTabTypes } from '../../utils/enums/campaignTabTypes'
import { CampaignMainMenu } from '../../components/campaigns/CampaignMainMenu'

const { update } = crudConst
const url = crudConstPath.campaigns.index
const getCampaign = (sendRequest, url) => sendRequest(url, crudConstNames.get)
const updateCampaign = async (editCampaignParams, url, sendRequest) => {
  return await sendRequest(url, crudConstNames.patch, {}, editCampaignParams)
}

export function CampaignNew() {
  const { t } = useTranslation(['translation', 'campaign'])
  const { data, loading, sendRequest } = useBackendApi()
  const { id } = useParams()
  const campaignUpdateUrl = crudConstPath.campaigns.update(id)
  const campaignEditUrl = crudConstPath.campaigns.pre_campaign(id)
  const { clearMapFilterState } = useMapFilterActions()
  const { setBidsInCart, setPackagePricingInCart, setTotalsFromQuotationInCart } = useBidsCartActions()
  const campaign = data?.campaign
  const advertisers = data?.advertisers
  const agencies = data?.agencies
  const providers = data?.providers
  const industries = data?.industries
  const initialTabOpen = campaignTabTypes.client_tab_open

  useEffect(() => {
    getCampaign(sendRequest, campaignEditUrl)
    clearMapFilterState()
  }, [])

  useEffect(() => {
    if (!(campaign === null || campaign === undefined)) {
      setBidsInCart(campaign.bids)
      setPackagePricingInCart(campaign.package_pricing)
      setTotalsFromQuotationInCart(campaign.total_leasing_price, campaign.total_installation_price, campaign.total_production_price, campaign.total_price)
    }
  }, [campaign])

  const handleUpdate = async (values, packagePricing, bidsCart) => {
    const editCampaignParams = {
      ...values,
      package_pricing: packagePricing,
      bids_attributes: bidsCart
    }

    const response = await updateCampaign(editCampaignParams, campaignUpdateUrl, sendRequest)
    if (response) {
      getCampaign(sendRequest, campaignEditUrl)
      notifySuccess(t('updated', { ns: 'campaign' }))
    } else {
      getCampaign(sendRequest, campaignEditUrl)
    }
  }
  //
  /* RETURN */

  return !loading && campaign && advertisers && agencies ? (

    <div className='q-container-column'>
      <CampaignMainMenu initialTabOpen={initialTabOpen} />
      <Quotation
        submitAction={handleUpdate}
        quotation={campaign}
        providers={providers}
        mode={update}
        advertisers={advertisers}
        agencies={agencies}
        industries={industries}
      />
    </div>
  ) : null
}