import { Divider, List, ListItem, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { visitModeAttributes } from '../../utils/enums/visitModeAttributes'

import { ContactPopover } from './ContactPopover'
import { useAppSelector } from '../../hooks/store'

// An array turned into a table with the option of adding a contact popover
export function ObjectList({ data, nameSpace }) {
  const { t } = useTranslation([nameSpace])
  const isActiveVisitMode = useAppSelector((state) => state.visitMode.isActive)
  const restrictedAttributes = Object.values(visitModeAttributes)

  const isString = (value) => typeof value === 'string' || value instanceof String
  const isEmptyValue = (value) => value === '' || value === null || value === undefined

  return (
    <List component={Paper} style={{ paddingTop: 0, paddingBottom: 0 }}>
      {
        Object.keys(data).map((key) => {
          if (isEmptyValue(data[key]) || (isActiveVisitMode && restrictedAttributes.includes(key))) {
            return null
          }
          return (
            <Box className='list-row' key={key}>
              <ListItem className='list-item'>
                <Typography className='list-item-label' sx={{ fontWeight: 700 }}>
                  {t(key, { ns: nameSpace })}
                </Typography>

                {isString(data[key]) ? (
                  <Typography className='list-item-content'>{data[key]}</Typography>
                  ) : (
                  <ContactPopover object={data[key]} />
                )}
              </ListItem>
              <Divider component='li' />
            </Box>
          )
        })
      }
    </List>
  )
}

ObjectList.propTypes = {
  data: propTypes.object,
  nameSpace: propTypes.string
}