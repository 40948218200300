import useBackendApi from '../../hooks/useBackendApi'
import '../../styles/Dashboard.scss'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { CompanySelector } from './CompanySelector'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

const url = crudConstPath.companies.index

const DashboardCompanyFilters = ({ setSelectedCompany }) => {
  //
  /* HOOKS */
  const { data, sendRequest } = useBackendApi()

  // METHODS
  const getCompanies = () => {
    sendRequest(url, crudConstNames.get)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  //
  /* RETURN */
  return (
    <Formik>
      {({ errors, touched, values, setFieldValue }) => (
        <Form
          className='dashboard-filter-form'>
          <CompanySelector setSelectedCompany={setSelectedCompany}  companies={data?.companies} />
        </Form>
      )}
    </Formik>
  )
}

export default React.memo(DashboardCompanyFilters)

DashboardCompanyFilters.propTypes = {
  setSelectedCompany: PropTypes.func.isRequired
}
