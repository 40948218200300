import Button from '@mui/material/Button'
import propTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../hooks/store'

import '../../styles/campaigns/CampaignTabs.css'
import { campaignTabNames, campaignTabTypes } from '../../utils/enums/campaignTabTypes'
import { useCampaignTabActions } from '../../hooks/actions/useCampaignTabActions'

export function CampaignTabs({ tab }) {
  // HOOKS
  const { t } = useTranslation(['campaign'])
  const { setCurrentCampaignTab } = useCampaignTabActions()
  const currentTab = useAppSelector((state) => state.campaignTab.currentTab)

  useEffect(() => {
    // carga el tab inicial
    if (tab) {
      setCurrentCampaignTab(tab)
    }
  }, [])

  // METHODS
  const handleTabClick = (event) => {
    setCurrentCampaignTab(campaignTabTypes[event.currentTarget.id])
  }

  // RETURN
  return (
    <div className='campaign-tabs'>
      <Button
        id={campaignTabNames.client_tab_open}
        className='campaign-tabs-button'
        variant={currentTab === campaignTabTypes.client_tab_open ? 'contained' : 'outlined'}
        onClick={handleTabClick}>
        {t('client', { ns: 'campaign' })}
      </Button>
    </div>
  )
}

CampaignTabs.propTypes = {
  tab: propTypes.number
}
