import { NotificationContainer } from '../components/notifications/NotificationContainer'
import { PublicLayout } from '../layouts/PublicLayout'
import propTypes from 'prop-types'

export function PublicPage({ title, children }) {
  // HOOKS
  // METHODS
  // RETURN
  return (
    <PublicLayout docTitle={title}>
      <NotificationContainer />
      {children}
    </PublicLayout>
  )
}

PublicPage.propTypes = {
  title: propTypes.string,
  children: propTypes.object
}
