import propTypes from 'prop-types'

import { QuotationContextualActions } from './QuotationContextualActions'
import { QuotationTabs } from './QuotationTabs'

import '../../styles/quotations/QuotationMainMenu.css'

export function QuotationMainMenu({ initialTabOpen, quotation, providers }) {
  return (
    <div className='quotation-tabs-and-filters'>
      <QuotationTabs tab={initialTabOpen} />
      <QuotationContextualActions quotation={quotation} providers={providers} />
    </div>
  )
}
QuotationMainMenu.propTypes = {
  initialTabOpen: propTypes.number,
  quotation: propTypes.object,
  providers: propTypes.array
}
